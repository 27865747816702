import React from "react";
import { Link } from "react-router-dom";
import { HiHome } from "react-icons/hi2";
import { BsChevronRight } from "react-icons/bs";
function Banner(props) {
  return (
    <>
      <section className="section-banner">
        <img src={props.BannerImage} alt="banner" />
        <div className="banner-content-wrapper">
          <div className="container">
            <div className="banner-nav-wrapper">
              <h2>{props.heading}</h2>
              <ul>
                <li>
                  <Link to="/">
                    <HiHome />
                  </Link>
                </li>
                <li>
                  <BsChevronRight />
                </li>
                <li>
                  <Link to={props.url}>{props.heading}</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Banner;
