import React, { useEffect, useState } from "react";
import AdminLayout from "../../../components/AdminLayout";
import { FaTrash } from "react-icons/fa";
import { BsPencilFill } from "react-icons/bs";
import { DataStore } from "@aws-amplify/datastore";
import { JobPosting } from "../../../models";
import { createJobPosting } from "../../../api/JobPostingAPI";
import ConfirmationBox from "../../../components/ConfirmationBox";

function AddCareers() {
  const [Show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [SelectedDataForRemove, setSelectedDataForRemove] = useState({});
  const [careerData, setCareerData] = useState();
  const [Editdataflag, setEditdataflag] = useState(false);
  const [editCareerObj, setEditCareerObj] = useState({});
  const [formValues, setFormValues] = useState({
    Position: "",
    JobType: "",
    Description: "",
    HubspotFormId: "",
  });

  const getJobPostingData = () => {
    const subscription = DataStore.observeQuery(JobPosting).subscribe(
      async (snapshot) => {
        const jobPostings = snapshot.items;
        console.log("job Postings", jobPostings);
        setCareerData(jobPostings);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (Editdataflag === false) {
      if (
        !formValues.Position ||
        !formValues.JobType ||
        !formValues.Description ||
        !formValues.HubspotFormId
      ) {
        alert("Please fill in all required fields.");
        return;
      }
      try {
        console.log("formValues", formValues);
        let CreateCareersObj = { ...formValues };
        console.log("Create Careers Obj", CreateCareersObj);

        CreateCareersObj.Position = formValues.Position;
        CreateCareersObj.JobType = formValues.JobType;
        CreateCareersObj.Description = formValues.Description;
        CreateCareersObj.HubspotFormId = formValues.HubspotFormId;

        console.log("Careers Data  on submit", CreateCareersObj);

        const response = await createJobPosting(CreateCareersObj);
        console.log("Careers data saved successfully:", response);
        setMessage("New Positions Text added");
        setShow(true);
        setFormValues({
          Position: "",
          JobType: "",
          Description: "",
          HubspotFormId: "",
        });
        getJobPostingData();
      } catch (error) {
        console.error("Error saving Careers data:", error);
      }
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleRemove = async (data) => {
    console.log("data", data);
    try {
      const response = await DataStore.delete(JobPosting, data.id);
      getJobPostingData();
    } catch (error) {}
  };

  const handleDelete = (data) => {
    console.log("handleDelete", data);
    setSelectedDataForRemove(data);
    setMessage("Do you really want to delete these records?");
    setShow(true);
  };

  const handleEdit = async (data) => {
    console.log("Edit", data);
    setEditCareerObj(data);
    setEditdataflag(true);
    setFormValues({
      Position: data.Position,
      JobType: data.JobType,
      Description: data.Description,
      HubspotFormId: data.HubspotFormId,
    });
  };

  const handleEditsubmit = async () => {
    console.log("formValues", formValues);

    const careersObj = await DataStore.query(JobPosting, editCareerObj.id);
    console.log("careers Obj", careersObj);
    if (careersObj) {
      // eslint-disable-next-line no-use-before-define
      const CareerUpdateObj = JobPosting.copyOf(careersObj, (updated) => {
        updated.Position = formValues.Position;
        updated.JobType = formValues.JobType;
        updated.Description = formValues.Description;
        updated.HubspotFormId = formValues.HubspotFormId;
      });
      console.log("careers Obj", careersObj);
      const ResultCareerItem = await DataStore.save(CareerUpdateObj);
      setMessage("Records updated successfully!");
      setEditdataflag(false);
      setShow(true);
      if (ResultCareerItem) {
        setFormValues({
          Position: "",
          JobType: "",
          Description: "",
          HubspotFormId: "",
        });

        getJobPostingData();
      }
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setFormValues({
      Position: "",
      JobType: "",
      Description: "",
      HubspotFormId: "",
    });
    setEditdataflag(false);
  };

  // confirm box started
  const handleConfirm = async () => {
    setMessage("");
    console.log("confirm", SelectedDataForRemove);
    setShow(false);
    handleRemove(SelectedDataForRemove);
  };

  const handleDecline = async () => {
    setMessage("");
    setShow(false);
  };

  const handleOk = async () => {
    setMessage("");
    setShow(false);
  };
  // confirm box ended

  useEffect(() => {
    getJobPostingData();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-content-container">
        <div className="admin-left-side">
          <h1 className="common-heading">Add New Position </h1>
          <div className="white-box">
            <form action="submit" onSubmit={handleSubmitForm}>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Job Type :
                </label>
                <input
                  class="form-control"
                  placeholder="e.g. Full Time, Part Time "
                  type="text"
                  id="example"
                  name="JobType"
                  value={formValues.JobType}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Position Name :
                </label>
                <input
                  class="form-control"
                  placeholder="Enter Position Name"
                  type="text"
                  id="example"
                  name="Position"
                  value={formValues.Position}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Job Description :
                </label>
                <textarea
                  class="form-control"
                  id=""
                  rows="3"
                  name="Description"
                  value={formValues.Description}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  HubSpot Form Id :
                </label>
                <input
                  class="form-control"
                  placeholder=""
                  type="text"
                  id="example"
                  name="HubspotFormId"
                  value={formValues.HubspotFormId}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group admin-btn-container">
                {Editdataflag == false ? (
                  <button
                    className="bg-success"
                    onClick={() => {
                      handleSubmitForm();
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    className="bg-success"
                    onClick={() => {
                      handleEditsubmit();
                    }}
                  >
                    Update
                  </button>
                )}
                <button
                  className="bg-danger"
                  onClick={(e) => {
                    handleCancel(e);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="admin-right-side">
          <h1 className="common-heading">All Positions</h1>
          <div className="white-box">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th width="5%" className="text-center">
                    SN
                  </th>
                  <th width="15%">Job Type</th>
                  <th width="25%">Position</th>
                  <th width="45%">Description</th>
                  <th width="10%">Action</th>
                </tr>
              </thead>
              <tbody>
                {careerData &&
                  careerData.length > 0 &&
                  careerData.map((data, index) => (
                    <tr>
                      <td className="text-center">{index + 1}</td>
                      <td>{data.JobType}</td>
                      <td>{data.Position}</td>
                      <td>{data.Description}</td>
                      <td>
                        <ul className="table-action-btn">
                          <li>
                            <BsPencilFill
                              className="text-success"
                              onClick={() => {
                                handleEdit(data);
                              }}
                            />
                          </li>

                          <li>
                            <FaTrash
                              className="text-danger"
                              onClick={() => {
                                handleDelete(data);
                              }}
                            />
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {Show ? (
        <ConfirmationBox
          show={Show}
          message={message}
          handleConfirm={
            message == "New Positions added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleConfirm
          }
          handleDecline={
            message == "New Positions added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleDecline
          }
          handleOK={
            message == "New Positions added" ||
            message == "Records updated successfully!"
              ? handleOk
              : undefined
          }
        />
      ) : null}
    </AdminLayout>
  );
}

export default AddCareers;
