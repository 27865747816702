import React, { useEffect, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import AdminLayout from "../../../components/AdminLayout";
import defaultUser from "../../../assets/images/default/default-user.png";
import { FaTrash } from "react-icons/fa";
import { DataStore } from "@aws-amplify/datastore";
import { uploadData } from "@aws-amplify/storage";
import { awsmobile } from "../../../aws-exports";

import { AboutUs } from "../../../models";
import ConfirmationBox from "../../../components/ConfirmationBox";

function AddHomeBanner() {
  const [Show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [SelectedDataForRemove, setSelectedDataForRemove] = useState({});
  const [Photofile, setPhotofile] = useState();
  const [Commonfileurl, setCommonfileurl] = useState();
  const [indexValue, setIndexValue] = useState("");
  const awsBucket = awsmobile.aws_user_files_s3_bucket;
  // const [Editdataflag, setEditdataflag] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [formValues, setFormValues] = useState({
    SwiperImage: "",
  });
  const inputFile = useRef(null);
  const [photoData, setPhotoData] = useState();

  // Add Images started here
  const validateFileType = async (e, Type) => {
    console.log("eventvalue", e);
    var file = e.target.files[0];
    const fileurl = URL.createObjectURL(file);
    setCommonfileurl(fileurl);
    console.log("fileurl", file, fileurl);
    var fileType = file.type;
    if (
      fileType === "image/jpg" ||
      fileType === "image/jpeg" ||
      fileType === "image/png"
    ) {
      if (Type === "PhotoURL") {
        setPhotofile(file);
        setSelectedValue(true);
      }
    } else {
      alert("Only jpg/jpeg and png files are allowed!");
    }
  };

  const uploadLogoFileToStorage = async (folderName, file) => {
    console.log("filetype", file.type, file.size);
    const contentType = file.type;
    let fileName = file.name.replaceAll(" ", "_");
    console.log("hellocontent", file.name);
    console.log("contentType", contentType);
    try {
      const uploadTask = await uploadData({
        key: `${folderName}/${fileName}`,
        data: file,
        options: {
          contentType: contentType,
        },
      }).result;

      console.log("Succeeded: ", uploadTask);
      let Response = `https://${awsBucket}.s3.ap-south-1.amazonaws.com/public/${folderName}/${fileName}`;

      return Response;
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (!selectedValue) {
      alert("Please fill in all required fields.");
      return;
    }
    // try {
    console.log("formValues", formValues);
    let UserPhoto = "";
    if (Photofile && Commonfileurl) {
      UserPhoto = await uploadLogoFileToStorage(
        "images",
        Photofile,
        Commonfileurl
      );
    }

    console.log("Images Data  on submit", UserPhoto);
    console.log("selectedValue", selectedValue);

    const Imagesobj = await DataStore.query(AboutUs);
    console.log("careers Obj", Imagesobj);
    if (Imagesobj) {
      let arrvalue = [];
      if (Imagesobj[0].SwiperImage && Imagesobj[0].SwiperImage !== null) {
        arrvalue = [...Imagesobj[0].SwiperImage];
      }

      arrvalue.push(UserPhoto);
      console.log("arrvalue", UserPhoto, arrvalue);

      const CareerUpdateObj = AboutUs.copyOf(Imagesobj[0], (updated) => {
        updated.SwiperImage = arrvalue;
        return updated;
      });
      console.log("Updated Obj", CareerUpdateObj);
      const ResultImages = await DataStore.save(CareerUpdateObj);
    }
    inputFile.current.value = "";
    setMessage("New Image added in Gallery");
    setShow(true);
    setFormValues({
      SwiperImage: "",
      // id: "",
    });
    // } catch (error) {
    //   console.error("Error saving Images data:", error);
    // }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setFormValues({
      SwiperImage: "",
      // id: "",
    });
    inputFile.current.value = "";
    setPhotofile(null);
    setCommonfileurl(null);
    // setEditdataflag(false);
    setSelectedValue("");
  };
  // Add Images ended here

  // confirm box started
  const handleConfirm = async () => {
    setMessage("");
    handleRemove();
    console.log("confirm", SelectedDataForRemove);
    setShow(false);
    // handleRemove(SelectedDataForRemove, selectedIndex);
  };

  const handleDecline = async () => {
    setMessage("");
    setShow(false);
  };

  const handleOk = async () => {
    setMessage("");
    setShow(false);
  };
  // confirm box ended

  useEffect(() => {
    const ImagesSubscription = DataStore.observeQuery(AboutUs).subscribe(
      async (snapshots) => {
        const Images = snapshots.items;
        setPhotoData(Images[0].SwiperImage);
        console.log("Images", Images[0].SwiperImage);
      }
    );
  }, []);

  const handleDeleteBanner = (key) => {
    setShow(true);
    setMessage("Do you want delete Banner?");
    setIndexValue(key);
  };

  const handleRemove = async () => {
    try {
      // Fetch the AboutUs item from the data store
      const aboutUsItem = await DataStore.query(AboutUs);

      // Ensure that the aboutUsItem exists and has SwiperImage array
      if (aboutUsItem && aboutUsItem[0].SwiperImage) {
        // Create a copy of the SwiperImage array
        let updatedSwiperImage = [...aboutUsItem[0].SwiperImage];

        // Remove the image at the specified index
        updatedSwiperImage.splice(indexValue, 1);

        // Update the AboutUs item with the new SwiperImage array
        const updatedAboutUsItem = await DataStore.save(
          AboutUs.copyOf(aboutUsItem[0], (updated) => {
            updated.SwiperImage = updatedSwiperImage;
          })
        );

        // Update the state with the updated SwiperImage array
        setPhotoData(updatedSwiperImage);
      }
    } catch (error) {
      console.error("Error removing image:", error);
      // Optionally display an error message
      setMessage("Error removing image");
      setShow(true);
    }
  };

  return (
    <AdminLayout>
      <div className="admin-content-container">
        <div className="admin-left-side">
          <h1 className="common-heading">
            Add Banner <small className="text-danger">(1280 X 670)</small>
          </h1>
          <div className="white-box">
            <form onSubmit={handleSubmitForm}>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Upload Image :{" "}
                </label>
                <input
                  class="form-control"
                  accept="image/*"
                  type="file"
                  id="formFile"
                  name="PhotoURL"
                  ref={inputFile}
                  onChange={(e) => {
                    validateFileType(e, "PhotoURL");
                  }}
                />
              </div>
              <div class="form-group admin-btn-container">
                <button
                  className="bg-success"
                  onClick={(e) => {
                    handleSubmitForm(e);
                  }}
                >
                  Add
                </button>

                <button
                  className="bg-danger"
                  onClick={(e) => {
                    handleCancel(e);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="admin-right-side">
          <h1 className="common-heading">All Photos</h1>
          <div className="white-box">
            <div className="tech-team-container home-banner-container">
              {photoData &&
                photoData.length > 0 &&
                photoData.map((photo, key) => (
                  <div className="tech-team-item" key={key}>
                    <div
                      className="team-item-wrapper"
                      data-aos="fade-up"
                      data-aos-once="true"
                      data-aos-easing="ease-in-out"
                      style={{ background: "#6766662b" }}
                    >
                      <div className="team-img-wrapper">
                        <img
                          src={photo}
                          onError={(e) => (e.target.src = defaultUser)}
                          alt="member"
                          style={{ objectFit: "contain" }}
                        />
                      </div>

                      <div className="admin-match-card">
                        <ul className="table-action-btn">
                          <li>
                            <FaTrash
                              className="text-danger"
                              onClick={() => {
                                handleDeleteBanner(key);
                                // handleRemove(key);
                              }}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      {Show ? (
        <ConfirmationBox
          show={Show}
          message={message}
          handleConfirm={
            message == "New Image added in Gallery" ||
            message == "Records updated successfully!"
              ? undefined
              : handleConfirm
          }
          handleDecline={
            message == "New Image added in Gallery" ||
            message == "Records updated successfully!"
              ? undefined
              : handleDecline
          }
          handleOK={
            message == "New Image added in Gallery" ||
            message == "Records updated successfully!"
              ? handleOk
              : undefined
          }
        />
      ) : null}
    </AdminLayout>
  );
}

export default AddHomeBanner;
