import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AdminLayout from "../../../components/AdminLayout";
import { FaTrash } from "react-icons/fa";
import { BsPencilFill } from "react-icons/bs";
import DefaultUser from "../../../assets/images/avtar/snigmay-avtar-bg.png";
import { DataStore } from "@aws-amplify/datastore";
import { COTM } from "../../../models";
import { uploadData } from "@aws-amplify/storage";
import { createCOTM } from "../../../api/CotmAPI";
import ConfirmationBox from "../../../components/ConfirmationBox";
import { awsmobile } from "../../../aws-exports";

function AddCOTM() {
  const [Show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [SelectedDataForRemove, setSelectedDataForRemove] = useState({});
  const [selectedIndex, setSelectedIndex] = useState();
  const [startDate, setStartDate] = useState();
  const [cotmData, setCotmData] = useState();
  const [ProfilePhotofile, setProfilePhotofile] = useState();
  const [Commonfileurl, setCommonfileurl] = useState();
  const [Editdataflag, setEditdataflag] = useState(false);
  const [EditObj, setEditObj] = useState({});
  const awsBucket = awsmobile.aws_user_files_s3_bucket;
  const [formValues, setFormValues] = useState({
    Month: "",
    Name: "",
    PhotoURL: "",
  });
  const inputFile = useRef(null);

  const validateFileType = async (e, Type) => {
    console.log("eventvalue", e);
    var file = e.target.files[0];
    const fileurl = URL.createObjectURL(file);
    setCommonfileurl(fileurl);
    console.log("fileurl", file, fileurl);
    var fileType = file.type;
    if (
      fileType === "image/jpg" ||
      fileType === "image/jpeg" ||
      fileType === "image/png"
    ) {
      console.log("hello");
      setProfilePhotofile(file);
    } else {
      alert("Only jpg/jpeg and png files are allowed!");
    }
  };

  const uploadPhotoFileToStorage = async (folderName, file) => {
    console.log("filetype", file.type, file.size);
    const contentType = file.type;
    let fileName = file.name.replaceAll(" ", "_");
    console.log("hellocontent", file.name);
    console.log("contentType", contentType);
    try {
      const uploadTask = await uploadData({
        key: `${folderName}/${fileName}`,
        data: file,
        options: {
          contentType: contentType,
        },
      }).result;

      console.log("Succeeded: ", uploadTask);
      let Response = `https://${awsBucket}.s3.ap-south-1.amazonaws.com/public/${folderName}/${fileName}`;

      return Response;
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const getCOTMData = () => {
    const subscription = DataStore.observeQuery(COTM).subscribe(
      async (snapshot) => {
        const cotms = snapshot.items;
        console.log("cotms", cotms);
        setCotmData(cotms);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const handleOnChange = (e) => {
    console.log("handle on change", e);
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleChangeDate = (date) => {
    console.log("handle on change", date);
    setFormValues((prevValues) => ({
      ...prevValues,
      Month: date,
    }));
    setStartDate(date);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setFormValues({
      Name: "",
      Month: "",
      PhotoURL: "",
    });
    inputFile.current.value = "";
    setProfilePhotofile(null);
    setCommonfileurl(null);
    setEditdataflag(false);
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (Editdataflag === false) {
      if (!formValues.Name || !formValues.Month) {
        alert("Please fill in all required fields.");
        return;
      }

      try {
        const existingRecord = cotmData.find((item) => {
          console.log("item", item);
        });
        console.log("Existing record", existingRecord);
        if (existingRecord) {
          // If a record with the same date exists, add data to that array
          let ProfilePhoto = "";
          if (ProfilePhotofile && Commonfileurl) {
            ProfilePhoto = await uploadPhotoFileToStorage(
              "cotms",
              ProfilePhotofile,
              Commonfileurl
            );
          }
          const newList = [
            // ...existingRecord.BirthdayList,
            ...existingRecord.Coaches,
            {
              Name: formValues.Name,
              Description: "Forward",
              PhotoURL: ProfilePhoto,
            },
          ];
          const updatedRecord = COTM.copyOf(existingRecord, (updated) => {
            updated.Coaches = newList;
            return updated;
          });

          console.log(updatedRecord);
          await DataStore.save(updatedRecord);

          setCotmData(newList);
        } else {
          // If the date doesn't exist, create a new entry
          let ProfilePhoto = "";
          if (ProfilePhotofile && Commonfileurl) {
            ProfilePhoto = await uploadPhotoFileToStorage(
              "cotms",
              ProfilePhotofile,
              Commonfileurl
            );
          }

          const dateObject = new Date(formValues.Month);
          const formattedDate = dateObject.toLocaleDateString("en-US", {
            month: "long",
            year: "numeric",
          });
          console.log("formattedDate", formattedDate);
          const newEntry = {
            // Date: moment(formValues.Date).format("YYYY-MM-DD"),
            Month: formattedDate,
            Coaches: [
              {
                Name: formValues.Name,
                Description: "Forward",
                PhotoURL: ProfilePhoto,
              },
            ],
          };
          console.log("newEntry", newEntry);
          const response = await createCOTM(newEntry);
          setCotmData([...cotmData, response]);
        }
        inputFile.current.value = "";
        setMessage("New Coach of The Month added");
        setShow(true);
        setFormValues({
          Name: "",
          PhotoURL: "",
          Month: "",
        });
      } catch (error) {
        console.error("Error saving cotm data:", error);
      }
    }
  };

  const handleEdit = async (data, val) => {
    console.log("Edit", data);
    setEditObj(data);
    setEditdataflag(true);
    // const coachData = data.Coaches[idx];
    console.log("coaches data", data, val);

    setFormValues({
      Name: val.Name,
      Month: data.Month,
      PhotoURL: val.PhotoURL,
    });
  };

  const handleEditsubmit = async () => {
    try {
      let ProfilePhoto = "";
      if (ProfilePhotofile && Commonfileurl) {
        ProfilePhoto = await uploadPhotoFileToStorage(
          "ProfilePhotofile",
          ProfilePhotofile,
          Commonfileurl
        );
      }

      const cotmObj = await DataStore.query(COTM, EditObj.id);
      console.log("COTMObj", cotmObj);

      if (cotmObj) {
        const updatedCoaches = cotmObj.Coaches.map((coach) => {
          if (coach.Name === EditObj.Coaches[0].Name) {
            // Update the specific coach details
            return {
              ...coach,
              Name: formValues.Name,
              Description: formValues.Description,
              PhotoURL: ProfilePhoto !== "" ? ProfilePhoto : coach.PhotoURL,
            };
          }
          return coach;
        });

        const updatedCOTMObj = COTM.copyOf(cotmObj, (updated) => {
          const dateObject = new Date(formValues.Month);
          const formattedDate = dateObject.toLocaleDateString("en-US", {
            month: "long",
            year: "numeric",
          });
          updated.Coaches = updatedCoaches;
          updated.Month = formattedDate; // Update the Month field
          return updated;
        });

        console.log("Updated COTM Obj", updatedCOTMObj);

        const result = await DataStore.save(updatedCOTMObj);

        if (result) {
          inputFile.current.value = "";
          setMessage("Records updated successfully!");
          setShow(true);
          setFormValues({
            Month: "",
            Name: "",
            PhotoURL: "",
          });
          setEditdataflag(false); // Reset the edit flag
        }
      }
    } catch (error) {
      console.error("Error updating COTM data:", error);
    }
  };

  const handleRemove = async (data, idx) => {
    console.log("data", data, idx);
    // let UpdatedObject = {...data};
    let updateddata = data.Coaches.filter((ele, index) => idx !== index);
    console.log("updateddata", updateddata);
    const updatedRecord = COTM.copyOf(data, (updated) => {
      updated.Coaches = updateddata;
      return updated;
    });
    await DataStore.save(updatedRecord);
  };

  const handleDelete = (data, SelectedIndex) => {
    console.log("handleDelete", data);
    setSelectedIndex(SelectedIndex);
    setSelectedDataForRemove(data);
    setMessage("Do you really want to delete these records?");
    setShow(true);
  };

  // confirm box started
  const handleConfirm = async () => {
    setMessage("");
    console.log("confirm", SelectedDataForRemove);
    setShow(false);
    handleRemove(SelectedDataForRemove, selectedIndex);
  };

  const handleDecline = async () => {
    setMessage("");
    setShow(false);
  };

  const handleOk = async () => {
    setMessage("");
    setShow(false);
  };
  // confirm box ended

  useEffect(() => {
    getCOTMData();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-content-container">
        <div className="admin-left-side">
          <h1 className="common-heading">Add Coach of the month</h1>
          <div className="white-box">
            <form action="submit" onSubmit={handleSubmitForm}>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Name :
                </label>
                <input
                  class="form-control"
                  placeholder="Enter Name"
                  type="text"
                  id="example"
                  name="Name"
                  value={formValues.Name}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Select Month & Year :
                </label>
                <span class="custom-form-control">
                  <DatePicker
                    dateFormat="MMMM yyyy"
                    showMonthYearPicker
                    selected={startDate}
                    onChange={handleChangeDate}
                    name="Month"
                    value={formValues.Month}
                  />
                </span>
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Upload Photo :
                  <small className="text-danger">
                    (Size should be 220 X 360 )
                  </small>
                </label>
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  name="PhotoURL"
                  ref={inputFile}
                  onChange={(e) => {
                    validateFileType(e, "PhotoURL");
                  }}
                />
              </div>
              <div class="form-group admin-btn-container">
                {Editdataflag == false ? (
                  <button
                    className="bg-success"
                    onClick={() => {
                      handleSubmitForm();
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    className="bg-success"
                    onClick={() => {
                      handleEditsubmit();
                    }}
                  >
                    Update
                  </button>
                )}
                <button
                  className="bg-danger"
                  onClick={(e) => {
                    handleCancel(e);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="admin-right-side">
          <h1 className="common-heading">Coaches of the month</h1>
          <div className="white-box">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th width="10%" className="text-center">
                    SN
                  </th>
                  <th width="30%" className="text-center">
                    Photo
                  </th>
                  <th width="50%">Name</th>
                  <th width="10%">Action</th>
                </tr>
              </thead>
              <tbody>
                {cotmData &&
                  cotmData.length > 0 &&
                  cotmData.map((data, index) => {
                    return (
                      data.Coaches &&
                      data.Coaches.length > 0 &&
                      data.Coaches.map((val, indx) => (
                        <tr key={indx}>
                          <td className="text-center">{index + 1}</td>
                          <td>
                            <div className="d-flex justify-content-center">
                              <img
                                src={val.PhotoURL}
                                style={{
                                  width: "122px",
                                  maxHeight: "200px",
                                  objectFit: "contain",
                                }}
                                onError={(e) => (e.target.src = DefaultUser)}
                                alt="user"
                              />
                            </div>
                          </td>
                          <td>
                            <h3 className="dataH3">{val.Name}</h3>
                            <h4 className="dataH4">{data.Month}</h4>
                          </td>
                          <td>
                            <ul className="table-action-btn">
                              <li>
                                <BsPencilFill
                                  className="text-success"
                                  onClick={() => {
                                    handleEdit(data, val);
                                  }}
                                />
                              </li>

                              <li>
                                <FaTrash
                                  className="text-danger"
                                  onClick={() => {
                                    handleDelete(data, indx);
                                  }}
                                />
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ))
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {Show ? (
        <ConfirmationBox
          show={Show}
          message={message}
          handleConfirm={
            message == "New Coach of The Month added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleConfirm
          }
          handleDecline={
            message == "New Coach of The Month added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleDecline
          }
          handleOK={
            message == "New Coach of The Month added" ||
            message == "Records updated successfully!"
              ? handleOk
              : undefined
          }
        />
      ) : null}
    </AdminLayout>
  );
}

export default AddCOTM;
