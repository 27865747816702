import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// web page
import Home from "../Pages/Web/Home";
import AboutMain from "../Pages/Web/AboutMain";
import AcademyMain from "../Pages/Web/AcademyMain";
import ClubMain from "../Pages/Web/ClubMain";
import Contact from "../Pages/Web/Contact";
import Gallery from "../Pages/Web/Gallery";
import ImageGallery from "../Pages/Web/Gallery/ImageGallery";
import Amenities from "../Pages/Web/Amenities";
import WallOfFame from "../Pages/Web/WallOfFame";
import Careers from "../Pages/Web/Careers";
import Press from "../Pages/Web/Press";
import TeamBehindTeam from "../Pages/Web/TeamBehindTeam";
import CSR from "../Pages/Web/CSR/CSR";
import Merchandise from "../Pages/Web/FanZone/Merchandise";
import Trial from "../Pages/Web/Trial";
import TeamsPage from "../Pages/Web/Teams/TeamsPage";
import Login from "../Pages/Web/Login/Login";
// Admin Page
import AddTestimonials from "../Pages/Admin/Home/AddTestimonials";
import AddMatches from "../Pages/Admin/Home/AddMatches";
import AddTeams from "../Pages/Admin/Teams/AddTeams";
import AddPress from "../Pages/Admin/Press/AddPress";
import AddImage from "../Pages/Admin/Gallery/AddImage";
import AddVideo from "../Pages/Admin/Gallery/AddVideo";
import AddTeamBehindTeam from "../Pages/Admin/TeamBehindTeam/AddTeamBehindTeam";
import AddAchievement from "../Pages/Admin/About/AddAchievement";
import AddBirthday from "../Pages/Admin/Birthday/AddBirthday";
import AddPOTM from "../Pages/Admin/POTM/AddPOTM";
import AddCOTM from "../Pages/Admin/COTM/AddCOTM";
import TopSlider from "../Pages/Admin/TopSlider/TopSlider";
import AddTrials from "../Pages/Admin/Trials/AddTrials";
import AddCareers from "../Pages/Admin/Careers/AddCareers";
import AddAboutUs from "../Pages/Admin/About/AddAboutUs";
import AddHomeBanner from "../Pages/Admin/Home/AddHomeBanner";

function RoutesList(props) {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Web Pages Started Here */}
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<AboutMain />}></Route>
          <Route path="/academy" element={<AcademyMain />}></Route>
          <Route path="/club" element={<ClubMain />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/gallery" element={<Gallery />}></Route>
          <Route path="/gallery/photos" element={<ImageGallery />}></Route>
          <Route path="/our-amenities" element={<Amenities />}></Route>
          <Route path="/wall-of-fame" element={<WallOfFame />}></Route>
          <Route path="/careers" element={<Careers />}></Route>
          <Route path="/press" element={<Press />}></Route>
          <Route path="/about/team" element={<TeamBehindTeam />}></Route>
          <Route path="/csr" element={<CSR />}></Route>
          <Route path="/fan-zone/merchandise" element={<Merchandise />}></Route>
          <Route path="/trials" element={<Trial />}></Route>
          <Route path="/teams" element={<TeamsPage />}></Route>
          <Route path="/login" element={<Login />}></Route>

          {/* Web Pages Ended Here */}
          {/* Admin Pages Started Here */}
          <Route
            path="/admin/testimonials"
            element={<AddTestimonials />}
          ></Route>
          <Route path="/admin/matches" element={<AddMatches />}></Route>
          <Route path="/admin/teams" element={<AddTeams />}></Route>
          <Route path="/admin/press" element={<AddPress />} />
          <Route path="/admin/gallery/image" element={<AddImage />} />
          <Route path="/admin/gallery/video" element={<AddVideo />} />
          <Route
            path="/admin/team-behind-team"
            element={<AddTeamBehindTeam />}
          />
          <Route path="/admin/achievements" element={<AddAchievement />} />
          <Route path="/admin/birthdays" element={<AddBirthday />} />
          <Route path="/admin/potm" element={<AddPOTM />} />
          <Route path="/admin/cotm" element={<AddCOTM />} />
          <Route path="/admin/top-slider" element={<TopSlider />} />
          <Route path="/admin/trials" element={<AddTrials />} />
          <Route path="/admin/careers" element={<AddCareers />} />
          <Route path="/admin/about" element={<AddAboutUs />} />
          <Route path="/admin/home/banner" element={<AddHomeBanner />} />
          {/* Admin Pages Ended Here */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default RoutesList;
