// import React, { useState } from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";

// function Layout(props) {
//   const [sidebarClass, setSidebarClass] = useState(false);

//   const handleValue = () => {
//     setSidebarClass(!sidebarClass);
//   };

//   return (
//     <>
//       <Navbar handleValue={handleValue} />
//       <div
//         className={
//           sidebarClass === true
//             ? "collapse-menu layout-container"
//             : "layout-container"
//         }
//       >
//         <div className="main-container">{props.children}</div>
//       </div>
//       <Footer />
//     </>
//   );
// }

// export default Layout;

import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

function Layout(props) {
  return (
    <>
      <Navbar />
      <div className="layout-container">
        <div className="main-container">{props.children}</div>
      </div>
      <Footer />
    </>
  );
}

export default Layout;
