import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import Banner from "../../../components/Banner";
import BannerImage from "../../../assets/images/banner/banner-1.jpg";
import Member1 from "../../../assets/images/team/member-1.jpg";
import Member2 from "../../../assets/images/team/member-2.jpg";
import { DataStore } from "@aws-amplify/datastore";
import { Teams } from "../../../models";
import defaultPlayer from "../../../assets/images/avtar/snigmay-avtar-bg.png";
import { Helmet } from "react-helmet";

function TeamsPage() {
  // const TeamData = [
  //   {
  //     teamName: "Senior Team",
  //     players: [
  //       {
  //         PhotoURL: Member1,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member2,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member1,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member1,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member1,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member2,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member1,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member1,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //     ],
  //   },
  //   {
  //     teamName: "Under 13",
  //     players: [
  //       {
  //         PhotoURL: Member1,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member2,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member1,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member1,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member1,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member2,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member1,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member1,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //     ],
  //   },
  //   {
  //     teamName: "Under 7",
  //     players: [
  //       {
  //         PhotoURL: Member1,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member2,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member1,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member1,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member1,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member2,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member1,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //       {
  //         PhotoURL: Member1,
  //         Name: "Ajeet Jadhav",
  //         Position: "Mid Fielder",
  //       },
  //     ],
  //   },
  // ];

  const [TeamData, setTeamData] = useState();
  const sortOrder = [
    "Senior Men",
    "Reserve Team/Elite U19",
    "Senior Women",
    "Girls U17",
    "Elite U17",
    "Elite U15",
    "Elite U13",
    "Elite U11",
    "Elite U9",
    "Elite U7",
    "PS U17",
    "PS U15",
    "PS U13",
    "PS U11",
    "PS U9",
    "PS U7",
    "BL U17",
    "BL U15",
    "BL U13",
    "BL U11",
    "BL U9",
    "BL U7",
    "SA U17",
    "SA U15",
    "SA U13",
    "SA U11",
    "SA U9",
    "SA U7",
  ];
  useEffect(() => {
    const teamArray = [];
    const subscription = DataStore.observeQuery(Teams).subscribe(
      async (snapshot) => {
        const teams = snapshot.items;
        console.log("teams data", teams);

        for (let i = 0; i < teams.length; i++) {
          let teamPlayersPromise = await teams[i].Players.toArray();
          let teamPlayers = await Promise.all(teamPlayersPromise);
          console.log("teamPlayers  data", teamPlayers);
          // let teamPlayersFlat = teamPlayers.flat();
          teamArray.push({
            teamName: teams[i].Name,
            players: teamPlayers,
          });
        }
        console.log("Players  data", teamArray);
        const sortedTeams = teamArray.sort((a, b) => {
          const indexA = sortOrder.indexOf(a.teamName);
          const indexB = sortOrder.indexOf(b.teamName);
          // return indexA - indexB;

          if (indexA === -1 && indexB === -1) {
            return 0; // Preserve original order for teams not in sortOrder
          } else if (indexA === -1) {
            return 1; // Teams not in sortOrder go after listed teams
          } else if (indexB === -1) {
            return -1; // Teams in sortOrder come before those not in sortOrder
          } else {
            return indexA - indexB; // Standard sorting based on sortOrder
          }
        });
        console.log("sorted team data", sortedTeams);
        setTeamData(sortedTeams);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Team Members of Snigmay Pune FC</title>
        <meta
          name="description"
          content="Explore the talented team members of Snigmay Pune FC. From skilled players to dedicated coaches, discover the team that makes Snigmay Pune FC exceptional."
        />
        <meta name="keywords" content="Team Members, Our Great Players " />
      </Helmet>
      <Banner BannerImage={BannerImage} heading="Teams" url="/teams" />
      {TeamData &&
        TeamData.length > 0 &&
        TeamData.map((data, teamIndex) =>
          data.players && data.players.length > 0 ? (
            <section className="teams-player-container sec-pad">
              <div className="container">
                <div className="headling-wrapper white-headline">
                  <h2
                    className="common-heading"
                    data-aos="zoom-in"
                    data-aos-once="true"
                    data-aos-easing="ease-in-out"
                  >
                    {data.players && data.players.length > 0
                      ? data.teamName
                      : null}
                  </h2>
                </div>
                <div className="tech-team-container">
                  {data.players &&
                    data.players.length > 0 &&
                    data.players.map((player, key) => {
                      return (
                        <div className="tech-team-item" key={key}>
                          <div
                            className="team-item-wrapper"
                            data-aos="fade-up"
                            data-aos-once="true"
                            data-aos-easing="ease-in-out"
                          >
                            <div className="team-img-wrapper">
                              <img
                                src={player.PhotoURL}
                                onError={(e) => (e.target.src = defaultPlayer)}
                                alt="member"
                              />
                            </div>
                            <div className="team-content-wrapper">
                              <h2>{player.Name}</h2>
                              <h3>{player.Position}</h3>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </section>
          ) : null
        )}
    </Layout>
  );
}

export default TeamsPage;
