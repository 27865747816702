// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { AboutUs, JobPosting, Trials, ScrollingText, TeamBehindTeam, PressRelease, Testimonial, Matches, Videos, Event, Achievements, Player, Teams, Birthday, COTM, POTM, PlayerOfTheMonth } = initSchema(schema);

export {
  AboutUs,
  JobPosting,
  Trials,
  ScrollingText,
  TeamBehindTeam,
  PressRelease,
  Testimonial,
  Matches,
  Videos,
  Event,
  Achievements,
  Player,
  Teams,
  Birthday,
  COTM,
  POTM,
  PlayerOfTheMonth
};