import React from "react";
import Layout from "../../components/Layout";
import Banner from "../../components/Banner";
import BannerImage from "../../assets/images/banner/banner-1.jpg";
import PressCard from "./Press/PressCard";
import { Helmet } from "react-helmet";

function Press() {
  return (
    <>
      <Layout>
        <Helmet>
          <title>Updates of upcoming Events</title>
          <meta
            name="description"
            content="Discover the latest updates and news about Snigmay Pune Football Club"
          />
          <meta name="keywords" content="Updates, News" />
        </Helmet>
        <Banner BannerImage={BannerImage} heading="Press" url="/press" />
        <PressCard />
      </Layout>
    </>
  );
}

export default Press;
