import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "../../../components/AdminLayout";
import { FaTrash } from "react-icons/fa";
import { BsPencilFill } from "react-icons/bs";
import DefaultUser from "../../../assets/images/admin/user/user.png";
import { DataStore } from "@aws-amplify/datastore";
import { Birthday } from "../../../models";
import { uploadData } from "@aws-amplify/storage";
import { createBirthday } from "../../../api/BirthdayAPI";
import moment from "moment";
import ConfirmationBox from "../../../components/ConfirmationBox";
import { awsmobile } from "../../../aws-exports";

function AddBirthday() {
  const [Show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [SelectedDataForRemove, setSelectedDataForRemove] = useState({});
  const [birthdaysData, setBirthdaysData] = useState();
  const [ProfilePhotofile, setProfilePhotofile] = useState();
  const [Commonfileurl, setCommonfileurl] = useState();
  const [Editdataflag, setEditdataflag] = useState(false);
  const [EditObj, setEditObj] = useState({});
  const [selectedIndex, setSelectedIndex] = useState();
  const awsBucket = awsmobile.aws_user_files_s3_bucket;
  const [formValues, setFormValues] = useState({
    Date: "",
    Name: "",
    PhotoURL: "",
  });
  const inputFile = useRef(null);

  const validateFileType = async (e, Type) => {
    console.log("eventvalue", e);
    var file = e.target.files[0];
    const fileurl = URL.createObjectURL(file);
    setCommonfileurl(fileurl);
    console.log("fileurl", file, fileurl);
    var fileType = file.type;
    if (
      fileType === "image/jpg" ||
      fileType === "image/jpeg" ||
      fileType === "image/png"
    ) {
      console.log("hello");
      setProfilePhotofile(file);
    } else {
      alert("Only jpg/jpeg and png files are allowed!");
    }
  };

  const uploadPhotoFileToStorage = async (folderName, file) => {
    console.log("filetype", file.type, file.size);
    const contentType = file.type;
    let fileName = file.name.replaceAll(" ", "_");
    console.log("hellocontent", file.name);
    console.log("contentType", contentType);
    try {
      const uploadTask = await uploadData({
        key: `${folderName}/${fileName}`,
        data: file,
        options: {
          contentType: contentType,
        },
      }).result;

      console.log("Succeeded: ", uploadTask);
      let Response = `https://${awsBucket}.s3.ap-south-1.amazonaws.com/public/${folderName}/${fileName}`;

      return Response;
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const getBirthdayData = () => {
    const subscription = DataStore.observeQuery(Birthday).subscribe(
      async (snapshot) => {
        const birthdays = snapshot.items;
        console.log("birthdays", birthdays);
        setBirthdaysData(birthdays);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (Editdataflag === false) {
      if (!formValues.Name || !formValues.Date) {
        alert("Please fill in all required fields.");
        return;
      }

      try {
        const existingRecord = birthdaysData.find((item) =>
          moment(item.Date).isSame(moment(formValues.Date), "day")
        );
        console.log("Existing record", existingRecord);
        if (existingRecord) {
          // If a record with the same date exists, add data to that array
          let ProfilePhoto = "";
          if (ProfilePhotofile && Commonfileurl) {
            ProfilePhoto = await uploadPhotoFileToStorage(
              "Achievements",
              ProfilePhotofile,
              Commonfileurl
            );
          }
          const newList = [
            ...existingRecord.BirthdayList,
            {
              Name: formValues.Name,
              Description: "Forward",
              PhotoURL: ProfilePhoto,
            },
          ];
          const updatedRecord = Birthday.copyOf(existingRecord, (updated) => {
            updated.BirthdayList = newList;
            return updated;
          });

          console.log(updatedRecord);
          await DataStore.save(updatedRecord);

          setBirthdaysData(newList);
        } else {
          // If the date doesn't exist, create a new entry
          let ProfilePhoto = "";
          if (ProfilePhotofile && Commonfileurl) {
            ProfilePhoto = await uploadPhotoFileToStorage(
              "Achievements",
              ProfilePhotofile,
              Commonfileurl
            );
          }
          const newEntry = {
            Date: moment(formValues.Date).format("YYYY-MM-DD"),
            BirthdayList: [
              {
                Name: formValues.Name,
                Description: "Forward",
                PhotoURL: ProfilePhoto,
              },
            ],
          };

          const response = await createBirthday(newEntry);
          setBirthdaysData([...birthdaysData, response]);
        }
        inputFile.current.value = "";
        setMessage("New Birthdays added");
        setShow(true);
        setFormValues({
          Name: "",
          PhotoURL: "",
          Date: 0,
        });
      } catch (error) {
        console.error("Error saving Birthday data:", error);
      }
    }
  };

  const handleRemove = async (data, idx) => {
    console.log("data", data, idx);
    // let UpdatedObject = {...data};
    let updateddata = data.BirthdayList.filter((ele, index) => idx !== index);
    console.log("updateddata", updateddata);
    const updatedRecord = Birthday.copyOf(data, (updated) => {
      updated.BirthdayList = updateddata;
      return updated;
    });
    await DataStore.save(updatedRecord);
    getBirthdayData();
  };

  const handleDelete = (data, SelectedIndex) => {
    console.log("handleDelete", data);
    setSelectedIndex(SelectedIndex);
    setSelectedDataForRemove(data);
    setMessage("Do you really want to delete these records?");
    setShow(true);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setFormValues({
      Date: "",
      Name: "",
      PhotoURL: "",
    });
    inputFile.current.value = "";
    setProfilePhotofile(null);
    setCommonfileurl(null);
    setEditdataflag(false);
  };

  const handleEdit = async (data, val) => {
    console.log("Edit", data);
    setEditObj(data);
    setEditdataflag(true);
    // const coachData = data.Coaches[idx];
    console.log("Birthdays data", data, val);

    setFormValues({
      Name: val.Name,
      Date: data.Date,
      PhotoURL: val.PhotoURL,
    });
  };

  const handleEditsubmit = async () => {
    try {
      let ProfilePhoto = "";
      if (ProfilePhotofile && Commonfileurl) {
        ProfilePhoto = await uploadPhotoFileToStorage(
          "ProfilePhotofile",
          ProfilePhotofile,
          Commonfileurl
        );
      }

      const birthdayObj = await DataStore.query(Birthday, EditObj.id);
      console.log("birthday Obj", birthdayObj);

      if (birthdayObj) {
        const updatedBirthdayList = birthdayObj.BirthdayList.map((birthday) => {
          if (birthday.Name === EditObj.BirthdayList[0].Name) {
            // Update the specific coach details
            return {
              ...birthday,
              Name: formValues.Name,
              Description: formValues.Description,
              PhotoURL: ProfilePhoto !== "" ? ProfilePhoto : birthday.PhotoURL,
            };
          }
          return birthday;
        });

        const updatedBirthdayObj = Birthday.copyOf(birthdayObj, (updated) => {
          updated.BirthdayList = updatedBirthdayList;
          updated.Date = moment(formValues.Date).format("YYYY-MM-DD");
          return updated;
        });

        console.log("Updated COTM Obj", updatedBirthdayObj);

        const result = await DataStore.save(updatedBirthdayObj);

        if (result) {
          inputFile.current.value = "";
          setMessage("Records updated successfully!");
          setShow(true);
          setFormValues({
            Date: "",
            Name: "",
            PhotoURL: "",
          });
          setEditdataflag(false); // Reset the edit flag
        }
      }
    } catch (error) {
      console.error("Error updating Birthday data:", error);
    }
  };

  // confirm box started
  const handleConfirm = async () => {
    setMessage("");
    console.log("confirm", SelectedDataForRemove);
    setShow(false);
    handleRemove(SelectedDataForRemove, selectedIndex);
  };

  const handleDecline = async () => {
    setMessage("");
    setShow(false);
  };

  const handleOk = async () => {
    setMessage("");
    setShow(false);
  };
  // confirm box ended

  useEffect(() => {
    getBirthdayData();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-content-container">
        <div className="admin-left-side">
          <h1 className="common-heading">Add Birthday</h1>
          <div className="white-box">
            <form action="submit" onSubmit={handleSubmitForm}>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Name :
                </label>
                <input
                  class="form-control"
                  placeholder="Enter Name"
                  type="text"
                  id="example"
                  name="Name"
                  value={formValues.Name}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Date of Birth :
                </label>
                <input
                  class="form-control"
                  placeholder=""
                  type="date"
                  id="example"
                  name="Date"
                  value={formValues.Date}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Upload Photo :
                  <small className="text-danger">
                    (Size should be 220 X 360 )
                  </small>
                </label>
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  name="PhotoURL"
                  ref={inputFile}
                  onChange={(e) => {
                    validateFileType(e, "PhotoURL");
                  }}
                />
              </div>
              <div class="form-group admin-btn-container">
                {Editdataflag === false ? (
                  <button
                    className="bg-success"
                    onClick={() => {
                      handleSubmitForm();
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    className="bg-success"
                    onClick={() => {
                      handleEditsubmit();
                    }}
                  >
                    Update
                  </button>
                )}
                <button
                  className="bg-danger"
                  onClick={(e) => {
                    handleCancel(e);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="admin-right-side">
          <h1 className="common-heading">All Birthdays</h1>
          <div className="white-box">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th width="10%" className="text-center">
                    SN
                  </th>
                  <th width="30%" className="text-center">
                    Profile
                  </th>
                  <th width="50%">Name</th>
                  <th width="10%">Action</th>
                </tr>
              </thead>
              <tbody>
                {birthdaysData &&
                  birthdaysData.length > 0 &&
                  birthdaysData.map((data, index) => {
                    return (
                      data.BirthdayList &&
                      data.BirthdayList.length > 0 &&
                      data.BirthdayList.map((val, indx) => (
                        <tr key={indx}>
                          <td className="text-center">{indx + 1}</td>
                          <td>
                            <div className="d-flex justify-content-center">
                              <img
                                src={val.PhotoURL}
                                style={{
                                  width: "122px",
                                  maxHeight: "200px",
                                  objectFit: "contain",
                                }}
                                onError={(e) => (e.target.src = DefaultUser)}
                                alt="user"
                              />
                            </div>
                          </td>
                          <td>
                            <h3 className="dataH3">{val.Name}</h3>
                            <h4 className="dataH4">{data.Date}</h4>
                          </td>
                          <td>
                            <ul className="table-action-btn">
                              <li>
                                <BsPencilFill
                                  className="text-success"
                                  onClick={() => {
                                    handleEdit(data, val);
                                  }}
                                />
                              </li>
                              <li>
                                <FaTrash
                                  className="text-danger"
                                  onClick={() => {
                                    handleDelete(data, indx);
                                  }}
                                />
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ))
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {Show ? (
        <ConfirmationBox
          show={Show}
          message={message}
          handleConfirm={
            message == "New Birthdays added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleConfirm
          }
          handleDecline={
            message == "New Birthdays added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleDecline
          }
          handleOK={
            message == "New Birthdays added" ||
            message == "Records updated successfully!"
              ? handleOk
              : undefined
          }
        />
      ) : null}
    </AdminLayout>
  );
}

export default AddBirthday;
