import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import CareersForm from "./CareersForm";
import { DataStore } from "@aws-amplify/datastore";
import { JobPosting } from "../../../models";
import { Helmet } from "react-helmet";

function CareersCard() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [jobPostingData, setJobPostingData] = useState();

  useEffect(() => {
    const subscription = DataStore.observeQuery(JobPosting).subscribe(
      async (snapshot) => {
        const jobPostings = snapshot.items;
        console.log("job Postings", jobPostings);
        setJobPostingData(jobPostings);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>
          We believe in career growth \ Snigmay Pune FC believe in career growth
        </title>
        <meta
          name="description"
          content="Join Snigmay Pune FC for unmatched career growth opportunities. Develop your skills and advance professionally with us.\ Discover Career Growth Opportunities with Snigmay Pune Football Club."
        />
        <meta
          name="keywords"
          content="Career growth, Career opportunities, career development "
        />
      </Helmet>
      <section className="careers-section sec-pad">
        <div className="container">
          <div className="headling-wrapper white-headline">
            <h2 className="common-heading">GET INVOLVE NOW</h2>
            {/* <h4 className="common-subheading">Let's join with us !!</h4> */}
            <h4 className="common-subheading">
              Your chance to join our growing team !!
            </h4>
          </div>
          <div className="row">
            {jobPostingData &&
              jobPostingData.length > 0 &&
              jobPostingData.map((val, key) => {
                return (
                  <div className="col-md-4">
                    <div class="vacancy-content" onClick={handleShow}>
                      <span>{val.JobType}</span>
                      <h3>{val.Position}</h3>
                      <p>{val.Description}</p>
                      <button class="home-btn-animation">APPLY NOW</button>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      {/* modal started here */}
      <Modal
        className="amenities-modal-container careers-modal-container"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h3>WORK WITH US.</h3>
          <h2>LET'S WORK TOGETHER.</h2>
          <p>
            Please fill the form below or mail us at{" "}
            <a class="text-medium-gray" href="mailto:snigmayfc@gmail.com">
              snigmayfc@gmail.com
            </a>
          </p>
          <CareersForm />
        </Modal.Body>
      </Modal>
      {/* modal ended here */}
    </>
  );
}

export default CareersCard;
