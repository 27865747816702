import React, { useEffect, useState, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import AdminLayout from "../../../components/AdminLayout";
import defaultUser from "../../../assets/images/default/default-user.png";
import { FaTrash } from "react-icons/fa";
import { BsPencilFill } from "react-icons/bs";
import { DataStore } from "@aws-amplify/datastore";
import { uploadData } from "@aws-amplify/storage";
import { Teams } from "../../../models";
// import { createTeams } from "../../../api/TeamsAPI";
import { Player } from "../../../models";
import { createPlayer } from "../../../api/PlayerAPI";
import ConfirmationBox from "../../../components/ConfirmationBox";
import { awsmobile } from "../../../aws-exports";
import defaultPlayer from "../../../assets/images/avtar/snigmay-avtar-bg.png";
// const defaultPlayer =
//   "https://snigmay-fc-prod.s3.ap-south-1.amazonaws.com/Avtar/snigmay-avtar-bg.png";

function AddTeams() {
  const [Show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [SelectedDataForRemove, setSelectedDataForRemove] = useState({});
  //
  const [UserPhotofile, setUserPhotofile] = useState();
  const [Commonfileurl, setCommonfileurl] = useState();
  const [Editdataflag, setEditdataflag] = useState(false);
  const [Action, setAction] = useState("");
  const [EditObj, setEditObj] = useState({});
  const [indexValue, setIndexValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const awsBucket = awsmobile.aws_user_files_s3_bucket;
  const [formValues, setFormValues] = useState({
    Name: "",
    Position: "",
    teamsID: "",
    PhotoURL: "",
    Id: "",
  });
  const inputFile = useRef(null);

  const [TeamData, setTeamData] = useState();
  // for adding new team start
  const [TeamName, setTeamName] = useState("");
  const [allTeamsList, setAllTeamsList] = useState([]);

  const handleOnChangeTeam = (e) => {
    setTeamName(e.target.value);
  };

  const handleSubmitFormTeam = async (e) => {
    console.log("Inside Add");
    e.preventDefault();
    if (!TeamName.trim()) {
      console.log("Team name cannot be blank");
      return;
    }
    let createobj = {
      Name: TeamName,
    };
    await DataStore.save(new Teams(createobj));
    setTeamName("");
  };
  // for adding new team end

  // for adding player start
  const validateFileType = async (e, Type) => {
    console.log("eventvalue", e);
    var file = e.target.files[0];
    const fileurl = URL.createObjectURL(file);
    setCommonfileurl(fileurl);
    console.log("fileurl", file, fileurl);
    var fileType = file.type;
    if (
      fileType === "image/jpg" ||
      fileType === "image/jpeg" ||
      fileType === "image/png"
    ) {
      if (Type === "PhotoURL") {
        setUserPhotofile(file);
      }
    } else {
      alert("Only jpg/jpeg and png files are allowed!");
    }
  };

  const uploadLogoFileToStorage = async (folderName, file) => {
    console.log("filetype", file.type, file.size);
    const contentType = file.type;
    let fileName = file.name.replaceAll(" ", "_");
    console.log("hellocontent", file.name);
    console.log("contentType", contentType);
    try {
      const uploadTask = await uploadData({
        key: `${folderName}/${fileName}`,
        data: file,
        options: {
          contentType: contentType,
        },
      }).result;

      console.log("Succeeded: ", uploadTask);
      let Response = `https://${awsBucket}.s3.ap-south-1.amazonaws.com/public/${folderName}/${fileName}`;

      return Response;
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSelectChange = (event) => {
    console.log("event", event);
    const selectedValue = event.target.value;
    console.log("selectedValue", selectedValue);
    setSelectedValue(selectedValue);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setFormValues({
      Name: "",
      Position: "",
      teamsID: "",
      PhotoURL: "",
      Id: "",
    });
    inputFile.current.value = "";
    setUserPhotofile(null);
    setCommonfileurl(null);
    setEditdataflag(false);
    // setSelectedTeam("");
    setSelectedValue("");
  };

  const handleSubmitForm = async (e) => {
    setAction("");
    if (Editdataflag === false) {
      e.preventDefault();
      if (!formValues.Name && !formValues.Position) {
        alert("Please fill in all required fields.");
        return;
      }
      try {
        console.log("formValues", formValues);
        let CreateObj = { ...formValues };
        // CreateObj.Team = selectedTeam;
        CreateObj.teamsID = selectedValue;
        console.log("CreateObj", CreateObj);
        let UserPhoto = "";
        if (UserPhotofile && Commonfileurl) {
          UserPhoto = await uploadLogoFileToStorage(
            "Teams",
            UserPhotofile,
            Commonfileurl
          );
        }

        CreateObj.PhotoURL = UserPhoto;

        console.log("Teams Data  on submit", CreateObj, UserPhoto);
        // const teamDetails = await DataStore.query(Teams, CreateObj.teamsID);
        // console.log("TeamDetaisl", teamDetails);
        let data = {
          Name: CreateObj.Name,
          PhotoURL: CreateObj.PhotoURL ? CreateObj.PhotoURL : defaultPlayer,
          Position: CreateObj.Position,
          teamsID: selectedValue,
        };
        const response = await DataStore.save(new Player(data));
        console.log("Teams data saved successfully:", response);
        setMessage("New Member added");
        setShow(true);
        inputFile.current.value = "";
        setFormValues({
          Name: "",
          Position: "",
          teamsID: "",
          PhotoURL: "",
          Id: "",
        });
        // getTeamsPlayerdata();
        setAction("Player Added");
        // getTBTData();
      } catch (error) {
        console.error("Error saving Teams data:", error);
      }
    }
  };

  const handleRemove = async (data) => {
    setAction("");
    console.log("data", data);
    try {
      const response = await DataStore.delete(Player, data.id);
      // getTBTData();
    } catch (error) {
      console.log("Error occurred", error);
    }
    setAction("Player Deleted");
    // getTeamsPlayerdata();
  };

  const handleEdit = async (data) => {
    console.log("Edit", data);
    setEditObj(data);
    setEditdataflag(true);
    // setSelectedTeam(data.Team);
    setSelectedValue(data.teamsID);
    console.log("team name on edit", data.Team);
    console.log("team Photo on edit", data.PhotoURL);
    setFormValues({
      Name: data.Name,
      Position: data.Position,
      teamsID: data.teamsID,
      PhotoURL: data.PhotoURL,
      Id: data.id,
    });
  };

  const handleDelete = async () => {
    let data = await DataStore.query(Teams);
    let finalteamdata = data.find((ele, idx) => idx === indexValue);
    console.log("finalteamdata", indexValue, finalteamdata);
    await DataStore.delete(finalteamdata);
    await handleGetTeamData();
  };
  const handleDeleteTeamData = async (index) => {
    setIndexValue(index);
    setMessage("Do you want to delete Team??");
    setShow(true);
  };

  const handleEditsubmit = async (e) => {
    setAction("");
    e.preventDefault();
    console.log("formValues", formValues);
    let UserPhoto = "";
    if (UserPhotofile && Commonfileurl) {
      UserPhoto = await uploadLogoFileToStorage(
        "UserPhotofile",
        UserPhotofile,
        Commonfileurl
      );
    }

    const PlayerObj = await DataStore.query(Player, formValues.Id);
    console.log("Player Obj", PlayerObj);
    if (PlayerObj) {
      // eslint-disable-next-line no-use-before-define
      const PlayerUpdateObj = Player.copyOf(PlayerObj, (updated) => {
        updated.Name = formValues.Name;
        updated.Position = formValues.Position;
        updated.teamsID = formValues.teamsID;
        updated.id = formValues.Id;
        updated.PhotoURL = UserPhoto !== "" ? UserPhoto : updated.PhotoURL;
        return updated;
      });
      console.log("Player Obj", PlayerUpdateObj);
      const ResultPlayerItem = await DataStore.save(PlayerUpdateObj);

      setMessage("Records updated successfully!");
      setShow(true);
      if (ResultPlayerItem) {
        setFormValues({
          Name: "",
          Position: "",
          Id: "",
          teamsID: "",
          PhotoURL: "",
        });
      }
    }
    setAction("Player Updated");
  };

  // for adding player end

  // confirm box started
  const handleConfirm = async () => {
    setMessage("");
    console.log("confirm", SelectedDataForRemove);
    setShow(false);
    handleDelete();
    handleRemove(SelectedDataForRemove);
  };

  const handleDecline = async () => {
    setMessage("");
    setShow(false);
  };

  const handleOk = async () => {
    setMessage("");
    setShow(false);
  };
  // confirm box ended
  const handleGetTeamData = () => {
    const subscription = DataStore.observeQuery(Teams).subscribe(
      async (snapshot) => {
        const teamArray = [];
        const teams = snapshot.items;

        console.log("teams data", teams);
        const sortedTeamData = [...teams].sort((a, b) =>
          a.Name.localeCompare(b.Name)
        );
        setAllTeamsList(sortedTeamData);
        for (let i = 0; i < teams.length; i++) {
          let teamPlayersPromise = await teams[i].Players.toArray();
          let teamPlayers = await Promise.all(teamPlayersPromise);
          console.log("teamPlayers  data", teamPlayers);
          teamArray.push({
            teamName: teams[i].Name,
            players: teamPlayers,
          });
        }
        console.log("UniquePlayer  data", teamArray);
        setTeamData(teamArray);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    handleGetTeamData();
  }, [Action]);

  return (
    <AdminLayout>
      <div className="admin-content-container">
        <div className="admin-left-side">
          <h1 className="common-heading">Add Teams</h1>
          <div className="white-box small-white-box">
            <form action="" onSubmit={handleSubmitFormTeam}>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Team Name :
                </label>
                <input
                  class="form-control"
                  type="text"
                  id="example"
                  value={TeamName}
                  onChange={handleOnChangeTeam}
                />
              </div>
              <div class="form-group admin-btn-container">
                <button className="bg-success">Add</button>
                <button className="bg-danger">Cancel</button>
              </div>
            </form>
          </div>
          <h1 className="common-heading">Add Players</h1>
          <div className="white-box">
            <form onSubmit={handleSubmitForm}>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Player Name :
                </label>
                <input
                  class="form-control"
                  type="text"
                  id="example"
                  name="Name"
                  value={formValues.Name}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Playing Position :
                </label>
                <input
                  class="form-control"
                  type="text"
                  id=""
                  name="Position"
                  value={formValues.Position}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Select Team :
                </label>
                <select
                  class="form-select"
                  aria-label=""
                  value={selectedValue}
                  onChange={(e) => handleSelectChange(e)}
                >
                  <option selected>Select Team</option>
                  {allTeamsList &&
                    allTeamsList.length > 0 &&
                    allTeamsList.map((data, index) => (
                      <option value={data.id}>{data.Name}</option>
                    ))}
                </select>
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Upload Image :{" "}
                  <small className="text-danger">
                    (Size should be 200 X 270 )
                  </small>
                </label>
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  name="PhotoURL"
                  ref={inputFile}
                  // value={formValues.PhotoURL}
                  onChange={(e) => {
                    validateFileType(e, "PhotoURL");
                  }}
                />
              </div>
              <div class="form-group admin-btn-container">
                {Editdataflag === false ? (
                  <button
                    className="bg-success"
                    onClick={(e) => {
                      handleSubmitForm(e);
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    className="bg-success"
                    onClick={(e) => {
                      handleEditsubmit(e);
                    }}
                  >
                    Update
                  </button>
                )}
                <button
                  className="bg-danger"
                  onClick={(e) => {
                    handleCancel(e);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="admin-right-side">
          <h1 className="common-heading">All Teams</h1>
          <div className="white-box">
            <Accordion defaultActiveKey="0" className="add-team-accordian">
              {TeamData &&
                TeamData.length > 0 &&
                TeamData.map((data, teamIndex) => (
                  <Accordion.Item eventKey={teamIndex}>
                    <Accordion.Header>
                      {data.teamName}
                      <button>
                        <FaTrash
                          onClick={() => handleDeleteTeamData(teamIndex)}
                        />
                      </button>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="tech-team-container">
                        {data.players &&
                          data.players.length > 0 &&
                          data.players.map((player, key) => {
                            return (
                              <div className="tech-team-item" key={key}>
                                <div
                                  className="team-item-wrapper"
                                  data-aos="fade-up"
                                  data-aos-once="true"
                                  data-aos-easing="ease-in-out"
                                >
                                  <div className="team-img-wrapper">
                                    <img
                                      src={player.PhotoURL}
                                      onError={(e) =>
                                        (e.target.src = defaultPlayer)
                                      }
                                      alt="member"
                                    />
                                  </div>
                                  <div className="team-content-wrapper">
                                    <h2 className="text-center text-white">
                                      {player.Name}
                                    </h2>
                                    <h3 className="text-center text-white">
                                      {player.Position}
                                    </h3>
                                  </div>
                                  <div className="admin-match-card">
                                    <ul className="table-action-btn">
                                      <li>
                                        <BsPencilFill
                                          className="text-success"
                                          onClick={() => {
                                            handleEdit({
                                              ...player,
                                              // Team: data.teamName,
                                            });
                                          }}
                                        />
                                      </li>

                                      <li>
                                        <FaTrash
                                          className="text-danger"
                                          onClick={() => {
                                            handleRemove(player);
                                          }}
                                        />
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
        </div>
      </div>

      {Show ? (
        <ConfirmationBox
          show={Show}
          message={message}
          handleConfirm={
            message == "New Member added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleConfirm
          }
          handleDecline={
            message == "New Member added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleDecline
          }
          handleOK={
            message == "New Member added" ||
            message == "Records updated successfully!"
              ? handleOk
              : undefined
          }
        />
      ) : null}
    </AdminLayout>
  );
}

export default AddTeams;
