import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import logo from "../assets/images/logo/logo.png";
import { Button } from "@aws-amplify/ui-react";
import { useAuthenticator } from "@aws-amplify/ui-react";

function Sidebar() {
  const location = useLocation();
  const { signOut } = useAuthenticator();
  // const logOut = () => {
  //   console.log("Signing out");
  //   signOut();

  // }
  return (
    <>
      <div className="sidebar-inner-conatainer">
        <div className="admin-logo-container">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="sidebar-list-container">
          <ul>
            <li>
              <Link
                to="/admin/home/banner"
                className={
                  location.pathname === "/admin/home/banner" ? "active" : ""
                }
              >
                Home Banner
              </Link>
            </li>
            <li>
              <Link
                to="/admin/about"
                className={location.pathname === "/admin/about" ? "active" : ""}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/admin/testimonials"
                className={
                  location.pathname === "/admin/testimonials" ? "active" : ""
                }
              >
                Testimonials
              </Link>
            </li>
            <li>
              <Link
                to="/admin/matches"
                className={
                  location.pathname === "/admin/matches" ? "active" : ""
                }
              >
                Matches
              </Link>
            </li>
            <li>
              <Link
                to="/admin/teams"
                className={location.pathname === "/admin/teams" ? "active" : ""}
              >
                Teams
              </Link>
            </li>
            <li>
              <Link
                to="/admin/press"
                className={location.pathname === "/admin/press" ? "active" : ""}
              >
                Press
              </Link>
            </li>
            <li>
              <Link
                to="/admin/gallery/image"
                className={
                  location.pathname === "/admin/gallery/image" ? "active" : ""
                }
              >
                Images
              </Link>
            </li>
            <li>
              <Link
                to="/admin/gallery/video"
                className={
                  location.pathname === "/admin/gallery/video" ? "active" : ""
                }
              >
                Videos
              </Link>
            </li>
            <li>
              <Link
                to="/admin/team-behind-team"
                className={
                  location.pathname === "/admin/team-behind-team"
                    ? "active"
                    : ""
                }
              >
                Teams Behind Team
              </Link>
            </li>
            <li>
              <Link
                to="/admin/achievements"
                className={
                  location.pathname === "/admin/achievements" ? "active" : ""
                }
              >
                Achievements
              </Link>
            </li>
            <li>
              <Link
                to="/admin/birthdays"
                className={
                  location.pathname === "/admin/birthdays" ? "active" : ""
                }
              >
                Birthdays
              </Link>
            </li>
            <li>
              <Link
                to="/admin/potm"
                className={location.pathname === "/admin/potm" ? "active" : ""}
              >
                Player of The Month
              </Link>
            </li>
            <li>
              <Link
                to="/admin/cotm"
                className={location.pathname === "/admin/cotm" ? "active" : ""}
              >
                Coach of The Month
              </Link>
            </li>
            <li>
              <Link
                to="/admin/top-slider"
                className={
                  location.pathname === "/admin/top-slider" ? "active" : ""
                }
              >
                Scrolling Text
              </Link>
            </li>
            <li>
              <Link
                to="/admin/trials"
                className={
                  location.pathname === "/admin/trials" ? "active" : ""
                }
              >
                Trials
              </Link>
            </li>
            <li>
              <Link
                to="/admin/careers"
                className={
                  location.pathname === "/admin/careers" ? "active" : ""
                }
              >
                Careers
              </Link>
            </li>
            <li>
              <Link to="/" style={{ padding: "0px" }}>
                <Button onClick={signOut}>Log Out</Button>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
