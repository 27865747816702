import React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FaPlay } from "react-icons/fa";

function HeaderVideo() {
  const FootballVideo =
    "https://snigmaypunefc-storage-63a42a0a100429-dev.s3.ap-south-1.amazonaws.com/public/video/Snigmay+web+video.mp4";

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <section className="amenities-parallex-1 home-video-parallex">
        <div className="amenities-parallex-1-overlay">
          <button onClick={handleShow}>
            <FaPlay />
          </button>
        </div>
      </section>
      <Modal
        className="amenities-modal-container"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <video width="100%" controls>
            <source src={FootballVideo} type="video/mp4" />
          </video>
        </Modal.Body>
      </Modal>
      {/* modal ended here */}
    </>
  );
}

export default HeaderVideo;
