import React, { useState, useEffect } from "react";
// import { DataStore } from "aws-amplify";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "../../../assets/css/swiper.css";
import { Pagination, Navigation } from "swiper";
import { Matches } from "../../../models";
import snigmayLogo from "../../../assets/images/logo/logo.png";
import TeamLogo2 from "../../../assets/images/home/FOOTBALL-CLUB-2.png";

import defaultClubLogo from "../../../assets/images/default/default-club-logo.png";
import { DataStore } from "@aws-amplify/datastore";
function RecentMatches() {
  const [matchesData, setMatchesData] = useState();
  // const matchesData = [
  //   {
  //     tournamentName: "UEFA CHAMPIONS LEAGUE",
  //     team1Logo: TeamLogo1,
  //     team2Logo: TeamLogo2,
  //     team1Name: "Team A",
  //     team2Name: "Team B",
  //     team1Score: 5,
  //     team2Score: 2,
  //     date: "TUE 06 SEP 2022",
  //     time: "12:30 PM",
  //     location: "BALEWADI STADIUM",
  //   },
  //   {
  //     tournamentName: "UEFA CHAMPIONS LEAGUE",
  //     team1Logo: TeamLogo1,
  //     team2Logo: TeamLogo2,
  //     team1Name: "Team C",
  //     team2Name: "Team D",
  //     team1Score: 5,
  //     team2Score: 2,
  //     date: "TUE 06 SEP 2022",
  //     time: "18:30 PM",
  //     location: "BALEWADI STADIUM",
  //   },
  //   {
  //     tournamentName: "UEFA CHAMPIONS LEAGUE",
  //     team1Logo: TeamLogo1,
  //     team2Logo: TeamLogo2,
  //     team1Name: "Team E",
  //     team2Name: "Team F",
  //     team1Score: 5,
  //     team2Score: 2,
  //     date: "TUE 06 SEP 2022",
  //     time: "20:30 PM",
  //     location: "BALEWADI STADIUM",
  //   },
  //   {
  //     tournamentName: "DIGITRACK super LEAGUE",
  //     team1Logo: TeamLogo2,
  //     team2Logo: TeamLogo1,
  //     team1Name: "Team A",
  //     team2Name: "Team B",
  //     team1Score: 3,
  //     team2Score: 2,
  //     date: "TUE 06 SEP 2022",
  //     time: "16:30 PM",
  //     location: "HOTFUT Ground",
  //   },
  //   {
  //     tournamentName: "UEFA CHAMPIONS LEAGUE",
  //     team1Logo: TeamLogo1,
  //     team2Logo: TeamLogo2,
  //     team1Name: "Team A",
  //     team2Name: "Team B",
  //     team1Score: 0,
  //     team2Score: 4,
  //     date: "TUE 06 SEP 2022",
  //     time: "19:30 PM",
  //     location: "BALEWADI STADIUM",
  //   },
  // ];
  // const recentMatchesData = matchesData.slice(-3);
  const formatDate = (inputDate) => {
    // Attempt to parse the input date string
    const parsedDate = new Date(inputDate);

    if (!inputDate) {
      return "Invalid Date";
    }

    // Check if the parsed date is valid
    if (isNaN(parsedDate.getTime())) {
      return "Invalid date"; // Return an error message if the date is invalid
    }

    // Define options for formatting
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    // Format the date using the options
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      parsedDate
    );

    return formattedDate;
  };

  useEffect(() => {
    const subscription = DataStore.observeQuery(Matches).subscribe(
      async (snapshot) => {
        const matches = snapshot.items;
        console.log("Matches", matches);
        setMatchesData(matches);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
    // const getMatchesData = async ()=> {
    //   await getMatches();
    // }
    // getMatchesData();
  }, []);

  return (
    <>
      {matchesData && matchesData.length > 0 && (
        <section className="home-recent-match-section sec-pad">
          <div className="container">
            <div className="headling-wrapper" style={{ marginBottom: 0 }}>
              <h2
                className="common-heading"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                Matches
              </h2>
              <h4
                className="common-subheading"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                Our Recent Matches.
              </h4>
            </div>
          </div>
          <div className="matches-slider-wrapper">
            <div className="container">
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                loop={true}
                autoplay={true}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  300: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                className="mySwiper"
              >
                {matchesData &&
                  matchesData.length > 0 &&
                  matchesData.map((data) => (
                    <SwiperSlide>
                      <div
                        className="recent-maches-item"
                        data-aos="fade-up"
                        data-aos-once="true"
                      >
                        <h3>{data.TournamentName}</h3>
                        <div className="score-team-containerr">
                          <div className="team-logo-wrapper">
                            <img
                              src={snigmayLogo}
                              onError={(e) => (e.target.src = defaultClubLogo)}
                              alt="team logo"
                            />
                            <h4>{data.HomeTeamName}</h4>
                          </div>
                          <div className="score-wrapper">
                            {data.HomeTeamScore} &nbsp;-&nbsp;
                            {data.OpponentTeamScore}
                          </div>
                          <div className="team-logo-wrapper">
                            <img
                              src={data.OpponentTeamLogo}
                              onError={(e) => (e.target.src = defaultClubLogo)}
                              alt="team logo"
                            />
                            <h4>{data.OpponentTeamName}</h4>
                          </div>
                        </div>
                        <h4>
                          {formatDate(data.Date)},&nbsp;&nbsp; {data.Time},{" "}
                          <br /> {data.Location}
                        </h4>
                        <Link to="/about">
                          <div className="recent-match-btn">View Details</div>
                        </Link>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
export default RecentMatches;
