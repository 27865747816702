import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function ConfirmationBox({
  show,
  message,
  handleConfirm,
  handleClose,
  handleOK,
  handleDecline,
}) {
  return (
    <Modal
      className="confirm-box-modal"
      show={show}
      onHide={handleClose}
      autoFocus={false}
      centered
    >
      <Modal.Body className="text-center">
        <div className="confirm_icon_box">
          <i class="bi bi-x-circle"></i>
        </div>
        {!handleOK ? <h2>Are you sure?</h2> : null}
        <p>{message}</p>
        <div className="confirm-decline">
          {handleOK && (
            <Button className="btn-success" onClick={handleOK}>
              OK
            </Button>
          )}

          {handleDecline && (
            <Button
              className="confirm_box_cancel btn-secondary"
              onClick={handleDecline}
            >
              Cancel
            </Button>
          )}

          {handleConfirm && (
            <Button
              className="confirm_box_del btn-danger"
              onClick={handleConfirm}
            >
              Delete
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmationBox;
