import React from "react";
import Layout from "../../components/Layout";
import HeaderSwiper from "./Home/HeaderSwiper";
import About from "./Home/About";
import Club from "./Home/Club";
import VisionMission from "./Home/VisionMission";
import Academy from "./Home/Academy";
import RecentMatches from "./Home/RecentMatches";
import Testimonials from "./Home/Testimonials";
import Partners from "./Home/Partners";
import Counter from "./Home/Counter";
import WhySnigmay from "./Home/WhySnigmay";
import BirthdayCorner from "./Academy/BirthdayCorner";
import HeaderVideo from "./Home/HeaderVideo";
import COTMPage from "./Academy/COTMPage";
import POTMPage from "./Academy/POTMPage";
import TechnicalPartner from "./Home/TechnicalPartner";
import { Helmet } from "react-helmet";
function Home() {
  return (
    <>
      <Layout>
        <Helmet>
          <title>Snigmay – Pune’s Best Football Club</title>
          <meta
            name="description"
            content="Snigmay – Pune’s Best Football Club, Premier facilities, brilliant coaches, expert guidance, and comprehensive training for all levels. Join us for top-tier football development!"
          />
          <meta
            name="keywords"
            content="Pune’s Best Football Club, Top Football Club in Pune, "
          />
        </Helmet>
        <HeaderSwiper />
        <About />
        <VisionMission />
        <Club />
        <WhySnigmay />
        <HeaderVideo />
        <Academy />
        <POTMPage />
        <COTMPage />
        <RecentMatches />
        <Testimonials />
        <BirthdayCorner />
        <Counter />
        <Partners />
        <TechnicalPartner />
      </Layout>
    </>
  );
}
export default Home;
