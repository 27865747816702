import React from "react";
function Values() {
  return (
    <>
      <section className="about-values-section sec-pad">
        <div className="container">
          <div className="about-values-left-wrapper">
            <h2
              className="common-heading"
              data-aos="zoom-in"
              data-aos-once="true"
            >
              Our Values
            </h2>
            <h4
              className="common-subheading"
              data-aos="zoom-in"
              data-aos-once="true"
            >
              Building Excellence Through Integrity and Teamwork.
            </h4>
            {/* <p data-aos="fade-up" data-aos-once="true">
              Our values are FOSTER: Fair play, One team, Sportsmanship, Trust,
              Excellence in Execution, and Respect. These principles guide our
              approach to football, ensuring we play with integrity, work
              collaboratively, and strive for excellence while maintaining a
              positive and respectful environment.
            </p> */}
            <ul>
              <li
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-easing="ease-in-out"
                data-aos-delay="300"
              >
                <b>Fair Play:</b> Compete honestly and uphold the rules of the
                game.
              </li>
              <li
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-easing="ease-in-out"
                data-aos-delay="600"
              >
                <b> One Team:</b> Collaborate and support each other towards a
                common goal.
              </li>
              <li
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-easing="ease-in-out"
                data-aos-delay="900"
              >
                <b>Sportsmanship:</b> Show grace and respect in both victory and
                defeat.
              </li>
              <li
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-easing="ease-in-out"
                data-aos-delay="1200"
              >
                <b>Trust:</b> Rely on teammates and believe in their abilities
                and intentions.
              </li>
              <li
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-easing="ease-in-out"
                data-aos-delay="1200"
              >
                <b>Excellence in Execution:</b> Perform tasks with precision and
                strive for high standards.
              </li>
              <li
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-easing="ease-in-out"
                data-aos-delay="1200"
              >
                <b>Respect:</b> Acknowledge and value each individual’s
                contributions and differences.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}
export default Values;
