import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import Banner from "../../components/Banner";
import BannerImage from "../../assets/images/banner/banner-1.jpg";
import About from "./Home/About";
import Values from "./About/AboutUs/Values";
import { DataStore } from "@aws-amplify/datastore";
import { AboutUs } from "../../models";
import { Helmet } from "react-helmet";

function AboutMain() {
  const hideButton = true;
  const [aboutData, setAboutData] = useState();

  useEffect(() => {
    const subscription = DataStore.observeQuery(AboutUs).subscribe(
      async (snapshot) => {
        const about = snapshot.items;
        console.log("about", about);
        setAboutData(about[0]);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>History of Snigmay Pune Football Club </title>
        <meta
          name="description"
          content="The History of Snigmay Pune Football Club highlights its rise from a local start in 2015 to a regional football powerhouse."
        />
        <meta name="keywords" content="History of Snigmay Pune Football Club" />
      </Helmet>
      <Banner BannerImage={BannerImage} heading="About Us" url="/about" />
      <About hideButton={hideButton} />
      {/* Vision Started Here */}
      {aboutData && (
        <section className="about-vision-section">
          <div className="container">
            <div className="headling-wrapper white-headline">
              <h2
                className="common-heading"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                Our Vision
              </h2>
              <h4
                className="common-subheading"
                data-aos="zoom-in"
                data-aos-once="true"
                style={{ textAlign: "center" }}
              >
                {aboutData.Vision}
              </h4>
            </div>
            {/* <div
              className="text-center"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <p>{aboutData.Vision}</p>
            </div> */}
          </div>
        </section>
      )}
      {/* Vision Ended Here */}
      {/* Mission Started Here */}
      {aboutData && (
        <section className="about-vision-section sec-pad">
          <div className="container">
            <div className="headling-wrapper white-headline">
              <h2
                className="common-heading"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                Our Mission
              </h2>
              <h4
                className="common-subheading"
                data-aos="zoom-in"
                data-aos-once="true"
                style={{ textAlign: "center" }}
              >
                {aboutData.Mission}
              </h4>
            </div>
            {/* <div
              className="text-center"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <p>{aboutData.Mission}</p>
            </div> */}
          </div>
        </section>
      )}
      {/* Mission Ended Here */}
      <Values />
    </Layout>
  );
}
export default AboutMain;
