import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "../../../components/AdminLayout";
import DefaultBanner from "../../../assets/images/default/default-banner.png";
import { FaTrash } from "react-icons/fa";
import { BsPencilFill } from "react-icons/bs";
import { DataStore } from "@aws-amplify/datastore";
import { uploadData } from "@aws-amplify/storage";
import { PressRelease } from "../../../models";
import { createPress } from "../../../api/PressReleaseAPI";
import moment from "moment";
import ConfirmationBox from "../../../components/ConfirmationBox";
import { awsmobile } from "../../../aws-exports";

function AddPress() {
  const [Show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [SelectedDataForRemove, setSelectedDataForRemove] = useState({});
  const [pressData, setPressData] = useState();
  const [BannerPhotofile, setBannerPhotofile] = useState();
  const [Commonfileurl, setCommonfileurl] = useState();
  const [Editdataflag, setEditdataflag] = useState(false);
  const [EditObj, setEditObj] = useState({});
  const awsBucket = awsmobile.aws_user_files_s3_bucket;
  const [formValues, setFormValues] = useState({
    Title: "",
    Date: "",
    Description: "",
    Banner: "",
  });
  const inputFile = useRef(null);

  const validateFileType = async (e, Type) => {
    console.log("eventvalue", e);
    var file = e.target.files[0];
    const fileurl = URL.createObjectURL(file);
    setCommonfileurl(fileurl);
    console.log("fileurl", file, fileurl);
    var fileType = file.type;
    if (
      fileType === "image/jpg" ||
      fileType === "image/jpeg" ||
      fileType === "image/png"
    ) {
      setBannerPhotofile(file);
    } else {
      alert("Only jpg/jpeg and png files are allowed!");
    }
  };

  const uploadLogoFileToStorage = async (folderName, file) => {
    console.log("filetype", file.type, file.size);
    const contentType = file.type;
    let fileName = file.name.replaceAll(" ", "_");
    console.log("hellocontent", file.name);
    console.log("contentType", contentType);
    try {
      const uploadTask = await uploadData({
        key: `${folderName}/${fileName}`,
        data: file,
        options: {
          contentType: contentType,
        },
      }).result;

      console.log("Succeeded: ", uploadTask);
      let Response = `https://${awsBucket}.s3.ap-south-1.amazonaws.com/public/${folderName}/${fileName}`;

      return Response;
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const handleGetDate = (date) => {
    let formattedDate;
    if (!date) {
      // If date is not selected, use the current date
      formattedDate = moment().format("YYYY-MM-DD");
    } else {
      formattedDate = moment(date).format("YYYY-MM-DD");
    }

    console.log("formattedDate", formattedDate);

    let data = {
      date: formattedDate,
    };
    return data;
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const getPressData = () => {
    const subscription = DataStore.observeQuery(PressRelease).subscribe(
      async (snapshot) => {
        const PressReleases = snapshot.items;
        console.log("Press Releases", PressReleases);
        setPressData(PressReleases);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (Editdataflag === false) {
      if (!formValues.Title && !formValues.Description) {
        alert("Please fill in all required fields.");
        return;
      }
      try {
        console.log("formValues", formValues);
        let CreateObj = { ...formValues };
        console.log("CreateObj", CreateObj);
        let BannerPhoto = "";
        if (BannerPhotofile && Commonfileurl) {
          BannerPhoto = await uploadLogoFileToStorage(
            "BannerPhoto",
            BannerPhotofile
          );
        }
        let formattedDate = await handleGetDate(formValues.Date);
        CreateObj.Title = formValues.Title;
        CreateObj.Date = formattedDate.date;
        CreateObj.Description = formValues.Description;
        CreateObj.Banner = BannerPhoto;

        console.log("Press Release Data  on submit", CreateObj, BannerPhoto);

        const response = await createPress(CreateObj);
        console.log("press release data saved successfully:", response);
        setMessage("Press Release added");
        setShow(true);
        inputFile.current.value = "";
        setFormValues({
          Title: "",
          Date: "",
          Description: "",
          Banner: "",
        });
        getPressData();
      } catch (error) {
        console.error("Error saving testimonial data:", error);
      }
    }
  };

  const handleEdit = async (data) => {
    console.log("Edit", data);
    setEditObj(data);
    setEditdataflag(true);
    setFormValues({
      Title: data.Title,
      Date: data.Date,
      Description: data.Description,
      Banner: data.Banner,
    });
  };

  const handleEditsubmit = async () => {
    console.log("formValues", formValues);
    let BannerPhoto = "";
    if (BannerPhotofile && Commonfileurl) {
      BannerPhoto = await uploadLogoFileToStorage(
        "BannerPhotofile",
        BannerPhotofile,
        Commonfileurl
      );
    }

    const PressReleaseObj = await DataStore.query(PressRelease, EditObj.id);
    console.log("Press Release Obj", PressReleaseObj);
    let formattedDate = await handleGetDate(formValues.Date);
    if (PressReleaseObj) {
      // eslint-disable-next-line no-use-before-define
      const TestimonialUpdateObj = PressRelease.copyOf(
        PressReleaseObj,
        (updated) => {
          updated.Title = formValues.Title;
          updated.Date = formattedDate.date;
          updated.Description = formValues.Description;
          updated.Banner = BannerPhoto !== "" ? BannerPhoto : updated.Banner;
          return updated;
        }
      );
      console.log("TestimonialObj", PressReleaseObj);
      const ResultTestimonialItem = await DataStore.save(TestimonialUpdateObj);
      setMessage("Records updated successfully!");
      setShow(true);
      if (ResultTestimonialItem) {
        inputFile.current.value = "";
        setFormValues({
          Title: "",
          Date: "",
          Description: "",
          Banner: "",
        });
      }
    }
  };

  const handleRemove = async (data) => {
    console.log("data", data);
    try {
      const response = await DataStore.delete(PressRelease, data.id);
      getPressData();
    } catch (error) {}
  };

  const handleDelete = (data) => {
    console.log("handleDelete", data);
    setSelectedDataForRemove(data);
    setMessage("Do you really want to delete these records?");
    setShow(true);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setFormValues({
      Title: "",
      Date: "",
      Description: "",
      Banner: "",
    });
    inputFile.current.value = "";
    setBannerPhotofile(null);
    setCommonfileurl(null);
    setEditdataflag(false);
  };

  // confirm box started
  const handleConfirm = async () => {
    setMessage("");
    console.log("confirm", SelectedDataForRemove);
    setShow(false);
    handleRemove(SelectedDataForRemove);
  };

  const handleDecline = async () => {
    setMessage("");
    setShow(false);
  };

  const handleOk = async () => {
    setMessage("");
    setShow(false);
  };
  // confirm box ended

  useEffect(() => {
    getPressData();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-content-container">
        <div className="admin-left-side">
          <h1 className="common-heading">Add Press Release</h1>
          <div className="white-box">
            <form action="submit" onSubmit={handleSubmitForm}>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Press Title :
                </label>
                <input
                  class="form-control"
                  placeholder="Enter Title"
                  type="text"
                  id="example"
                  name="Title"
                  value={formValues.Title}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Date :
                </label>
                <input
                  class="form-control"
                  type="date"
                  id=""
                  name="Date"
                  value={formValues.Date}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Descripiton :
                </label>
                <textarea
                  class="form-control"
                  id=""
                  rows="3"
                  name="Description"
                  value={formValues.Description}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Upload Banner :
                  <small className="text-danger">
                    (Size should be 800 X 300 )
                  </small>
                </label>
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  name="Banner"
                  ref={inputFile}
                  onChange={(e) => {
                    validateFileType(e, "Banner");
                  }}
                />
              </div>
              <div class="form-group admin-btn-container">
                {Editdataflag == false ? (
                  <button
                    className="bg-success"
                    onClick={() => {
                      handleSubmitForm();
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    className="bg-success"
                    onClick={() => {
                      handleEditsubmit();
                    }}
                  >
                    Update
                  </button>
                )}
                <button
                  className="bg-danger"
                  onClick={(e) => {
                    handleCancel(e);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="admin-right-side">
          <h1 className="common-heading">All Press Releases</h1>
          <div className="white-box">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th width="5%" className="text-center">
                    SN
                  </th>
                  <th width="10%" className="text-center">
                    Banner
                  </th>
                  <th width="25%" className="text-center">
                    Title
                  </th>
                  <th width="50%">Description</th>
                  <th width="10%">Action</th>
                </tr>
              </thead>
              <tbody>
                {pressData &&
                  pressData.length > 0 &&
                  pressData.map((data, index) => (
                    <tr>
                      <td className="text-center">{index + 1}</td>
                      <td>
                        <img
                          src={data.Banner}
                          style={{
                            width: "80px",
                            height: "40px",
                            objectFit: "cover",
                          }}
                          onError={(e) => (e.target.src = DefaultBanner)}
                          alt="user"
                        />
                      </td>
                      <td>
                        <div className="table-multiple-heading-container">
                          <h3>{data.Title}</h3>
                          <h4>{data.Date}</h4>
                        </div>
                      </td>
                      <td>{data.Description}</td>
                      <td>
                        <ul className="table-action-btn">
                          <li>
                            <BsPencilFill
                              className="text-success"
                              onClick={() => {
                                handleEdit(data);
                              }}
                            />
                          </li>

                          <li>
                            <FaTrash
                              className="text-danger"
                              onClick={() => {
                                handleDelete(data);
                              }}
                            />
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {Show ? (
        <ConfirmationBox
          show={Show}
          message={message}
          handleConfirm={
            message == "Press Release added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleConfirm
          }
          handleDecline={
            message == "Press Release added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleDecline
          }
          handleOK={
            message == "Press Release added" ||
            message == "Records updated successfully!"
              ? handleOk
              : undefined
          }
        />
      ) : null}
    </AdminLayout>
  );
}

export default AddPress;
