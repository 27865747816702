import React from "react";
import Layout from "../../components/Layout";
import Banner from "../../components/Banner";
import BannerImage from "../../assets/images/banner/banner-1.jpg";
import CareersCard from "./Careers/CareersCard";

function Careers() {
  return (
    <Layout>
      <Banner BannerImage={BannerImage} heading="Careers" url="/careers" />
      <CareersCard />
    </Layout>
  );
}

export default Careers;
