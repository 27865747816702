import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "../../../components/AdminLayout";
import { FaTrash } from "react-icons/fa";
import { BsPencilFill } from "react-icons/bs";
import { DataStore } from "@aws-amplify/datastore";
import { uploadData } from "@aws-amplify/storage";
import { Testimonial } from "../../../models";
import { createTestimonial } from "../../../api/TestimonialsAPI";
import ConfirmationBox from "../../../components/ConfirmationBox";
import { awsmobile } from "../../../aws-exports";

function AddTestimonials() {
  const [Show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [SelectedDataForRemove, setSelectedDataForRemove] = useState({});

  const [testimonialData, setTestimonialData] = useState();
  const [UserPhotofile, setUserPhotofile] = useState();
  const [Commonfileurl, setCommonfileurl] = useState();
  const [Editdataflag, setEditdataflag] = useState(false);
  const [EditObj, setEditObj] = useState({});
  const [formValues, setFormValues] = useState({
    Name: "",
    Role: "",
    Description: "",
    PhotoURL: "",
  });
  const awsBucket = awsmobile.aws_user_files_s3_bucket;

  const inputFile = useRef(null);
  // for validate image file type (started here)
  const validateFileType = async (e, Type) => {
    console.log("eventvalue", e);
    var file = e.target.files[0];
    const fileurl = URL.createObjectURL(file);
    setCommonfileurl(fileurl);
    console.log("fileurl", file, fileurl);
    var fileType = file.type;
    if (
      fileType === "image/jpg" ||
      fileType === "image/jpeg" ||
      fileType === "image/png"
    ) {
      if (Type === "PhotoURL") {
        setUserPhotofile(file);
      }
    } else {
      alert("Only jpg/jpeg and png files are allowed!");
    }
  };
  // for validate image file type (ended here)
  const uploadLogoFileToStorage = async (folderName, file) => {
    console.log("filetype", file.type, file.size);
    const contentType = file.type;
    let fileName = file.name.replaceAll(" ", "_");
    console.log("hellocontent", file.name);
    console.log("contentType", contentType);
    try {
      const uploadTask = await uploadData({
        key: `${folderName}/${fileName}`,
        data: file,
        options: {
          contentType: contentType,
        },
      }).result;

      console.log("Succeeded: ", uploadTask);
      let Response = `https://${awsBucket}.s3.ap-south-1.amazonaws.com/public/${folderName}/${fileName}`;

      return Response;
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  //
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (Editdataflag == false) {
      if (!formValues.Name || !formValues.Role || !formValues.Description) {
        alert("Please fill in all required fields.");
        return;
      }
      try {
        console.log("formValues", formValues);
        let CreateObj = { ...formValues };
        console.log("CreateObj", CreateObj);
        let UserPhoto = "";
        if (UserPhotofile && Commonfileurl) {
          UserPhoto = await uploadLogoFileToStorage(
            "Testimonials",
            UserPhotofile,
            Commonfileurl
          );
        }

        CreateObj.Name = formValues.Name;
        CreateObj.Role = formValues.Role;
        CreateObj.Description = formValues.Description;
        CreateObj.PhotoURL = UserPhoto;

        console.log("Testimonials Data  on submit", CreateObj, UserPhoto);

        const response = await createTestimonial(CreateObj);
        setMessage("Testimonial added");
        setShow(true);
        console.log("testimonial data saved successfully:", response);
        setFormValues({
          Name: "",
          Role: "",
          Description: "",
          PhotoURL: "",
        });
        inputFile.current.value = "";
      } catch (error) {
        console.error("Error saving testimonial data:", error);
      }
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const getTestimonialData = () => {
    const subscription = DataStore.observeQuery(Testimonial).subscribe(
      async (snapshot) => {
        const testimonials = snapshot.items;
        console.log("testimonials", testimonials);
        const compareDates = (a, b) =>
          new Date(b.createdAt) - new Date(a.createdAt);
        testimonials.sort(compareDates);
        console.log("SortedArray", testimonials);
        setTestimonialData(testimonials);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const handleRemove = async (data) => {
    console.log("data", data);
    try {
      const response = await DataStore.delete(Testimonial, data.id);
    } catch (error) {}
  };

  const handleDelete = (data) => {
    console.log("handleDelete", data);
    setSelectedDataForRemove(data);
    setMessage("Do you really want to delete these records?");
    setShow(true);
  };

  const handleEditsubmit = async () => {
    console.log("formValues", formValues);
    let UserPhoto = "";
    if (UserPhotofile && Commonfileurl) {
      UserPhoto = await uploadLogoFileToStorage(
        "UserPhotofile",
        UserPhotofile,
        Commonfileurl
      );
    }

    const TestimonialObj = await DataStore.query(Testimonial, EditObj.id);
    console.log("TestimonialObj", TestimonialObj);
    if (TestimonialObj) {
      // eslint-disable-next-line no-use-before-define
      const TestimonialUpdateObj = Testimonial.copyOf(
        TestimonialObj,
        (updated) => {
          updated.Name = formValues.Name;
          updated.Role = formValues.Role;
          updated.Description = formValues.Description;
          updated.PhotoURL = UserPhoto !== "" ? UserPhoto : updated.PhotoURL;
          return updated;
        }
      );
      console.log("TestimonialObj", TestimonialObj);
      const ResultTestimonialItem = await DataStore.save(TestimonialUpdateObj);

      await setMessage("Records updated successfully!");
      setShow(true);
      setEditdataflag(false);
      if (ResultTestimonialItem) {
        setFormValues({
          Name: "",
          Role: "",
          Description: "",
          PhotoURL: "",
        });
        inputFile.current.value = "";
      }
    }
  };

  const handleEdit = async (data) => {
    console.log("Edit", data);
    setEditObj(data);
    setEditdataflag(true);
    setFormValues({
      Name: data.Name,
      Role: data.Role,
      Description: data.Description,
      PhotoURL: data.PhotoURL,
    });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setFormValues({
      Name: "",
      Role: "",
      Description: "",
      PhotoURL: "",
    });
    inputFile.current.value = "";
    setUserPhotofile(null);
    setCommonfileurl(null);
    setEditdataflag(false);
  };
  // confirm box started
  const handleConfirm = async () => {
    setMessage("");
    console.log("confirm", SelectedDataForRemove);
    setShow(false);
    handleRemove(SelectedDataForRemove);
  };

  const handleDecline = async () => {
    setMessage("");
    setShow(false);
  };

  const handleOk = async () => {
    setMessage("");
    setShow(false);
  };
  // confirm box ended

  useEffect(() => {
    getTestimonialData();
  }, []);

  return (
    <>
      <AdminLayout>
        <div className="admin-content-container">
          <div className="admin-left-side">
            <h1 className="common-heading">Add Testimonials</h1>
            <div className="white-box">
              <form action="submit" onSubmit={handleSubmitForm}>
                <div class="form-group mb-3">
                  <label class="form-label" for="example">
                    Name :
                  </label>
                  <input
                    class="form-control"
                    placeholder="Enter Name"
                    type="text"
                    id="example"
                    name="Name"
                    value={formValues.Name}
                    onChange={handleOnChange}
                  />
                </div>
                <div class="form-group mb-3">
                  <label class="form-label" for="">
                    Position :
                  </label>
                  <input
                    class="form-control"
                    type="text"
                    id=""
                    name="Role"
                    value={formValues.Role}
                    onChange={handleOnChange}
                  />
                </div>
                <div class="form-group mb-3">
                  <label class="form-label" for="">
                    Descripiton :
                  </label>
                  <textarea
                    class="form-control"
                    id=""
                    rows="3"
                    name="Description"
                    value={formValues.Description}
                    onChange={handleOnChange}
                  />
                </div>
                <div class="form-group mb-3">
                  <label class="form-label" for="">
                    Upload Image :
                    <small className="text-danger">
                      (Size should be 100 X 100 )
                    </small>
                  </label>
                  <input
                    class="form-control"
                    type="file"
                    id="formFile"
                    name="PhotoURL"
                    ref={inputFile}
                    onChange={(e) => {
                      validateFileType(e, "PhotoURL");
                    }}
                  />
                </div>
                <div class="form-group admin-btn-container">
                  {Editdataflag == false ? (
                    <button
                      className="bg-success"
                      onClick={() => {
                        handleSubmitForm();
                      }}
                    >
                      Add
                    </button>
                  ) : (
                    <button
                      className="bg-success"
                      onClick={() => {
                        handleEditsubmit();
                      }}
                    >
                      Update
                    </button>
                  )}
                  <button
                    className="bg-danger"
                    onClick={(e) => {
                      handleCancel(e);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="admin-right-side">
            <h1 className="common-heading">All Testimonials</h1>
            <div className="white-box">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th width="5%" className="text-center">
                      SN
                    </th>
                    <th width="25%" className="text-center">
                      Reviewer
                    </th>
                    <th width="60%">Description</th>
                    <th width="10%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {testimonialData &&
                    testimonialData.length > 0 &&
                    testimonialData.map((data, index) => (
                      <tr>
                        <td className="text-center">{index + 1}</td>
                        <td>
                          <div className="table-reviewer-container">
                            <img src={data.PhotoURL} alt="user" />
                            <h3>{data.Name}</h3>
                            <h4>{data.Role}</h4>
                          </div>
                        </td>
                        <td>{data.Description}</td>
                        <td>
                          <ul className="table-action-btn">
                            <li>
                              <BsPencilFill
                                className="text-success"
                                onClick={() => {
                                  handleEdit(data);
                                }}
                              />
                            </li>

                            <li>
                              <FaTrash
                                className="text-danger"
                                onClick={() => {
                                  handleDelete(data);
                                }}
                              />
                            </li>
                          </ul>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </AdminLayout>

      {Show ? (
        <ConfirmationBox
          show={Show}
          message={message}
          handleConfirm={
            message == "Testimonial added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleConfirm
          }
          handleDecline={
            message == "Testimonial added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleDecline
          }
          handleOK={
            message == "Testimonial added" ||
            message == "Records updated successfully!"
              ? handleOk
              : undefined
          }
        />
      ) : null}
    </>
  );
}

export default AddTestimonials;
