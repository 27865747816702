import React, { useEffect } from "react";
import Thumbnail from "../../../assets/images/gallery/images/football-thumbnail.jpg";
import { FaPlay } from "react-icons/fa";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Videos } from "../../../models";
import { DataStore } from "@aws-amplify/datastore";

function VideoGrid() {
  const [VideosData, setVideosData] = useState();
  const [videoData, setVideoData] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (values) => {
    setShow(true);
    setVideoData(values);
  };
  useEffect(() => {
    const subscription = DataStore.observeQuery(Videos).subscribe(
      async (snapshot) => {
        const videos = snapshot.items;
        console.log("videos", videos);
        setVideosData(videos);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      <section className="image-grid-section">
        <div className="row">
          {VideosData &&
            VideosData.length > 0 &&
            VideosData.map((val, key) => {
              return (
                <div className="col-md-4">
                  <div className="image-grid-item">
                    <img
                      src={val.CoverPhoto}
                      alt="thumbnail"
                      onError={(e) => (e.target.src = Thumbnail)}
                    />
                    <div class="gallery_card_hover">
                      <h3>{val.Title}</h3>
                      <button
                        className="playbutton"
                        onClick={() => handleShow(val.VideoURL)}
                      >
                        <FaPlay />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </section>
      {/* modal started here */}
      {show && (
        <Modal
          className="amenities-modal-container"
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <video width="100%" controls>
              <source src={videoData} type="video/mp4" />
            </video>
          </Modal.Body>
        </Modal>
      )}
      {/* modal ended here */}
    </>
  );
}

export default VideoGrid;
