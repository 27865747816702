import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { DataStore } from "@aws-amplify/datastore";
import { AboutUs } from "../../../models";
function About(props) {
  const location = useLocation();
  const About1 =
    "https://snigmaypunefc-storage-63a42a0a100429-dev.s3.ap-south-1.amazonaws.com/public/HomeTeam/IMG_5829.jpg";
  const About2 =
    "https://snigmaypunefc-storage-63a42a0a100429-dev.s3.ap-south-1.amazonaws.com/public/HomeTeam/IMG_2602.jpg";
  const About3 =
    "https://snigmaypunefc-storage-63a42a0a100429-dev.s3.ap-south-1.amazonaws.com/public/HomePage/home.png";
  const hideButton = true;
  const [aboutData, setAboutData] = useState();

  useEffect(() => {
    const subscription = DataStore.observeQuery(AboutUs).subscribe(
      async (snapshot) => {
        const about = snapshot.items;
        console.log("about", about);
        setAboutData(about[0]);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const secondaryImage = location.pathname === "/about" ? About3 : About2;

  return (
    <>
      <section className="home-about-section sec-pad">
        <div className="container">
          <div className="about-img-wrapper">
            <div
              className="large-img-wrapper"
              data-aos="slide-right"
              data-aos-once="true"
            >
              <img src={About1} alt="about" />
            </div>
            <div
              className="small-img-wrapper"
              data-aos="slide-up"
              data-aos-once="true"
            >
              <img src={secondaryImage} alt="about" />
            </div>
          </div>
          {aboutData && (
            <div className="about-content-wrapper">
              <h2
                className="common-heading"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                About Us
              </h2>
              <h4
                className="common-subheading"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                {aboutData.AboutUsHeading}
              </h4>
              <p data-aos="fade-up" data-aos-once="true">
                {location.pathname === "/about"
                  ? aboutData.AboutUsDescription
                  : aboutData.AboutUsDescription.split("\n")
                      .slice(0, 3)
                      .join("\n")}
              </p>

              {!props.hideButton && (
                <Link to="/about">
                  <div className="home-btn-animation">about</div>
                </Link>
              )}
            </div>
          )}
        </div>
      </section>
    </>
  );
}
export default About;
