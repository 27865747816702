import React from 'react';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import FootballVideo from "../../../assets/Videos/football.mp4";
import { FaPlay } from "react-icons/fa";

function CSRVideo() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <section className='amenities-parallex-1 csr-parallex'>
                <div className="amenities-parallex-1-overlay">
                    <button onClick={handleShow}><FaPlay /></button>
                </div>
            </section>
            {/* modal started here */}
            <Modal className='amenities-modal-container' show={show} onHide={handleClose} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <video width="100%" controls>
                        <source src={FootballVideo} type="video/mp4" />
                    </video>
                </Modal.Body>
            </Modal>
            {/* modal ended here */}
        </>
    )
}

export default CSRVideo
