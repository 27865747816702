import React, { useEffect, useState } from "react";
import AdminLayout from "../../../components/AdminLayout";
import { DataStore } from "@aws-amplify/datastore";
import { AboutUs } from "../../../models";
import ConfirmationBox from "../../../components/ConfirmationBox";

function AddAboutUs() {
  const [Show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [aboutData, setAboutData] = useState();
  const [Editdataflag, setEditdataflag] = useState(false);
  const [editAboutObj, setEditAboutObj] = useState({});
  const [formValues, setFormValues] = useState({
    AboutUsHeading: "",
    AboutUsDescription: "",
    Vision: "",
    Mission: "",
  });

  const getAboutData = async () => {
    const subscription = await DataStore.observeQuery(AboutUs).subscribe(
      async (snapshot) => {
        const aboutUs = snapshot.items;
        console.log("About Data", aboutUs);
        setAboutData(aboutUs);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setFormValues({
      AboutUsHeading: "",
      AboutUsDescription: "",
      Vision: "",
      Mission: "",
    });
    setEditdataflag(false);
  };

  const handleEdit = async (data) => {
    console.log("Edit", data);
    setEditAboutObj(data);
    setEditdataflag(true);
    setFormValues({
      AboutUsHeading: data.AboutUsHeading,
      AboutUsDescription: data.AboutUsDescription,
      Vision: data.Vision,
      Mission: data.Mission,
    });
  };

  const handleEditsubmit = async () => {
    console.log("formValues", formValues);

    const aboutObj = await DataStore.query(AboutUs, editAboutObj.id);
    console.log("about Obj", aboutObj);
    if (aboutObj) {
      // eslint-disable-next-line no-use-before-define
      const aboutUpdateObj = AboutUs.copyOf(aboutObj, (updated) => {
        updated.AboutUsHeading = formValues.AboutUsHeading;
        updated.AboutUsDescription = formValues.AboutUsDescription;
        updated.Vision = formValues.Vision;
        updated.Mission = formValues.Mission;
      });
      console.log("About Obj", aboutObj);
      const ResultAboutItem = await DataStore.save(aboutUpdateObj);
      setMessage("Records updated successfully!");
      setEditdataflag(false);
      setShow(true);
      if (ResultAboutItem) {
        setFormValues({
          AboutUsHeading: "",
          AboutUsDescription: "",
          Vision: "",
          Mission: "",
        });
      }
    }
  };

  // confirm box started

  const handleDecline = async () => {
    setMessage("");
    setShow(false);
  };

  const handleOk = async () => {
    setMessage("");
    setShow(false);
  };
  // confirm box ended

  useEffect(() => {
    getAboutData();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-content-container">
        {Editdataflag == true ? (
          <div className="admin-left-side">
            <h1 className="common-heading">Add About US</h1>
            <div className="white-box">
              <form>
                <div class="form-group mb-3">
                  <label class="form-label" for="example">
                    About Us Heading :
                  </label>
                  <input
                    class="form-control"
                    placeholder="Enter Title"
                    type="text"
                    id="example"
                    name="AboutUsHeading"
                    value={formValues.AboutUsHeading}
                    onChange={handleOnChange}
                  />
                </div>
                <div class="form-group mb-3">
                  <label class="form-label" for="">
                    About Us Descripiton :
                  </label>
                  <textarea
                    class="form-control"
                    id=""
                    rows="3"
                    name="AboutUsDescription"
                    value={formValues.AboutUsDescription}
                    onChange={handleOnChange}
                  />
                </div>
                <div class="form-group mb-3">
                  <label class="form-label" for="">
                    Vision Descripiton :
                  </label>
                  <textarea
                    class="form-control"
                    id=""
                    rows="3"
                    name="Vision"
                    value={formValues.Vision}
                    onChange={handleOnChange}
                  />
                </div>
                <div class="form-group mb-3">
                  <label class="form-label" for="">
                    Mission Descripiton :
                  </label>
                  <textarea
                    class="form-control"
                    id=""
                    rows="3"
                    name="Mission"
                    value={formValues.Mission}
                    onChange={handleOnChange}
                  />
                </div>
                <div class="form-group admin-btn-container">
                  <button
                    className="bg-success"
                    onClick={() => {
                      handleEditsubmit();
                    }}
                  >
                    Update
                  </button>

                  <button
                    className="bg-danger"
                    onClick={(e) => {
                      handleCancel(e);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : null}
        <div className="admin-right-side">
          <h1 className="common-heading">About Us</h1>
          <div className="white-box">
            <table class="table table-bordered">
              {aboutData &&
                aboutData.length > 0 &&
                aboutData.map((data, index) => (
                  <tbody>
                    <tr>
                      <td>
                        <div className="table-multiple-heading-container">
                          <h3>About Us Heading</h3>
                        </div>
                      </td>
                      <td>{data.AboutUsHeading}</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="table-multiple-heading-container">
                          <h3>About Us Descripiton</h3>
                        </div>
                      </td>
                      <td>{data.AboutUsDescription}</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="table-multiple-heading-container">
                          <h3>Vision Descripiton</h3>
                        </div>
                      </td>
                      <td>{data.Vision}</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="table-multiple-heading-container">
                          <h3>Mission Descripiton</h3>
                        </div>
                      </td>
                      <td>{data.Mission}</td>
                    </tr>
                    {Editdataflag == false ? (
                      <tr>
                        <td colSpan={2}>
                          <div className="table-button-container admin-btn-container">
                            <button
                              className="bg-success"
                              onClick={() => {
                                handleEdit(data);
                              }}
                            >
                              Edit
                            </button>
                          </div>
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                ))}
            </table>
          </div>
        </div>
      </div>

      {Show ? (
        <ConfirmationBox
          show={Show}
          message={message}
          handleDecline={
            message == "Press Release added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleDecline
          }
          handleOK={
            message == "Press Release added" ||
            message == "Records updated successfully!"
              ? handleOk
              : undefined
          }
        />
      ) : null}
    </AdminLayout>
  );
}

export default AddAboutUs;
