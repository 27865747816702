import React, { useEffect, useState, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import AdminLayout from "../../../components/AdminLayout";
import defaultUser from "../../../assets/images/default/default-user.png";
import { FaTrash } from "react-icons/fa";
import { BsPencilFill } from "react-icons/bs";
import { TeamBehindTeam } from "../../../models";
import { createTBT } from "../../../api/TeamsBehindTeamAPI";
import { DataStore } from "@aws-amplify/datastore";
import { uploadData } from "@aws-amplify/storage";
import ConfirmationBox from "../../../components/ConfirmationBox";
import Avtar from "../../../assets/images/avtar/snigmay-avtar.png";
import { awsmobile } from "../../../aws-exports";

function AddTeamBehindTeam() {
  const [Show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [SelectedDataForRemove, setSelectedDataForRemove] = useState();
  // const [tbtData, setTbtData] = useState();
  const [UserPhotofile, setUserPhotofile] = useState();
  const [Commonfileurl, setCommonfileurl] = useState();
  const [Editdataflag, setEditdataflag] = useState(false);
  const [EditObj, setEditObj] = useState({});
  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [dataToShow, setDataToShow] = useState([]);
  const awsBucket = awsmobile.aws_user_files_s3_bucket;
  const [formValues, setFormValues] = useState({
    Name: "",
    Designation: "",
    Facebook: "",
    Instagram: "",
    Twitter: "",
    Linkedin: "",
    Team: "",
    PhotoURL: "",
    Id: "",
    Priority: "",
    Description: "",
  });
  const inputFile = useRef(null);
  // creating new array based on team Name

  const validateFileType = async (e, Type) => {
    console.log("eventvalue", e);
    var file = e.target.files[0];
    const fileurl = URL.createObjectURL(file);
    setCommonfileurl(fileurl);
    console.log("fileurl", file, fileurl);
    var fileType = file.type;
    if (
      fileType === "image/jpg" ||
      fileType === "image/jpeg" ||
      fileType === "image/png"
    ) {
      if (Type === "PhotoURL") {
        setUserPhotofile(file);
      }
    } else {
      alert("Only jpg/jpeg and png files are allowed!");
    }
  };

  const uploadLogoFileToStorage = async (folderName, file) => {
    console.log("filetype", file.type, file.size);
    const contentType = file.type;
    let fileName = file.name.replaceAll(" ", "_");
    console.log("hellocontent", file.name);
    console.log("contentType", contentType);
    try {
      const uploadTask = await uploadData({
        key: `${folderName}/${fileName}`,
        data: file,
        options: {
          contentType: contentType,
        },
      }).result;

      console.log("Succeeded: ", uploadTask);
      let Response = `https://${awsBucket}.s3.ap-south-1.amazonaws.com/public/${folderName}/${fileName}`;

      return Response;
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const getTeamName = (value) => {
    switch (value) {
      case "1":
        return "Management Team";
      case "2":
        return "Advisory Board";
      case "3":
        return "Technical Team";
      default:
        return "";
    }
  };

  const getTeamValue = (value) => {
    switch (value) {
      case "Management Team":
        return "1";
      case "Advisory Board":
        return "2";
      case "Technical Team":
        return "3";
      default:
        return "";
    }
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const teamName = getTeamName(selectedValue);
    setSelectedTeam(teamName);
    setSelectedValue(selectedValue);

    // Display selected team name
    console.log(`Selected Team: ${teamName}`);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setFormValues({
      Name: "",
      Designation: "",
      Facebook: "",
      Instagram: "",
      Twitter: "",
      Linkedin: "",
      Team: "",
      PhotoURL: "",
      Id: "",
      Priority: "",
      Description: "",
    });
    inputFile.current.value = "";
    setUserPhotofile(null);
    setCommonfileurl(null);
    setEditdataflag(false);
    setSelectedTeam("");
    setSelectedValue("");
  };

  const handleSubmitForm = async (e) => {
    if (Editdataflag === false) {
      e.preventDefault();
      console.log("formValues", formValues);
      if (
        !formValues.Name == "" &&
        !formValues.Designation == "" &&
        selectedTeam !== "" &&
        selectedTeam !== null
      ) {
        try {
          console.log("formValues", formValues);
          let CreateObj = { ...formValues };
          CreateObj.Team = selectedTeam;
          console.log("CreateObj", CreateObj);
          let UserPhoto = "";
          if (UserPhotofile && Commonfileurl) {
            UserPhoto = await uploadLogoFileToStorage(
              "Testimonials",
              UserPhotofile,
              Commonfileurl
            );
          }

          CreateObj.Facebook =
            formValues.Facebook === "" ? null : formValues.Facebook;
          CreateObj.Instagram =
            formValues.Instagram === "" ? null : formValues.Instagram;
          CreateObj.Twitter =
            formValues.Twitter === "" ? null : formValues.Twitter;
          CreateObj.Linkedin =
            formValues.Linkedin === "" ? null : formValues.Linkedin;
          CreateObj.PhotoURL = UserPhoto;
          CreateObj.Priority = Number(formValues.Priority);
          CreateObj.Description = formValues.Description;

          console.log("Teams Data  on submit", CreateObj, UserPhoto);

          const response = await createTBT(CreateObj);
          console.log("Teams data saved successfully:", response);
          setMessage("New Team Member Added");
          setShow(true);
          inputFile.current.value = "";
          setFormValues({
            Name: "",
            Designation: "",
            Facebook: "",
            Instagram: "",
            Twitter: "",
            Linkedin: "",
            Team: "",
            PhotoURL: "",
            Id: "",
            Priority: "",
            Description: "",
          });
          // getTBTData();
        } catch (error) {
          console.error("Error saving Teams data:", error);
        }
      } else {
        alert("Please fill in all required fields.");
      }
    }
  };

  const handleRemove = async (data) => {
    console.log("data", data);
    try {
      const getdata = await DataStore.query(TeamBehindTeam, data);
      console.log("getdata", getdata);
      const Response = await DataStore.delete(getdata);
      // getTBTData();
    } catch (error) {
      console.log("Error occurred", error);
    }
  };

  const handleDelete = (data) => {
    console.log("handleDelete", data.Id);
    setSelectedDataForRemove(data.Id);
    setMessage("Do you really want to delete these records?");
    setShow(true);
  };

  const handleEdit = async (data) => {
    console.log("Edit", data);
    const selectedTeamValue = getTeamValue(data.Team);
    setEditObj(data);
    setEditdataflag(true);
    setSelectedTeam(data.Team);
    setSelectedValue(selectedTeamValue);
    console.log("team name on edit", data.Team);
    console.log("team Photo on edit", data.PhotoURL);
    setFormValues({
      Name: data.Name,
      Designation: data.Position,
      Facebook: data.fbURL,
      Instagram: data.instaURL,
      Twitter: data.twitterURL,
      Linkedin: data.LinkedInURL,
      Team: data.Team,
      PhotoURL: data.PhotoURL,
      Id: data.Id,
      Priority: data.Priority,
      Description: data.Description,
    });
  };

  const handleEditsubmit = async (e) => {
    e.preventDefault();
    console.log("formValues", formValues);
    let UserPhoto = "";
    if (UserPhotofile && Commonfileurl) {
      UserPhoto = await uploadLogoFileToStorage(
        "UserPhotofile",
        UserPhotofile,
        Commonfileurl
      );
    }

    const TBTObj = await DataStore.query(TeamBehindTeam, formValues.Id);
    console.log("TBTObj", TBTObj);
    if (TBTObj) {
      // eslint-disable-next-line no-use-before-define
      const TBTUpdateObj = TeamBehindTeam.copyOf(TBTObj, (updated) => {
        updated.Name = formValues.Name;
        updated.Designation = formValues.Designation;
        updated.Facebook =
          formValues.Facebook === "" ? null : formValues.Facebook;
        updated.Instagram =
          formValues.Instagram === "" ? null : formValues.Instagram;
        updated.Twitter = formValues.Twitter === "" ? null : formValues.Twitter;
        updated.Linkedin =
          formValues.Linkedin === "" ? null : formValues.Linkedin;
        updated.Team = formValues.Team;
        updated.Priority = Number(formValues.Priority);
        updated.Description = formValues.Description;
        updated.PhotoURL = UserPhoto !== "" ? UserPhoto : updated.PhotoURL;
        return updated;
      });
      console.log("Team Behind Team Obj", TBTUpdateObj);
      const ResultTBTItem = await DataStore.save(TBTUpdateObj);
      setMessage("Records updated successfully!");
      setShow(true);
      if (ResultTBTItem) {
        setFormValues({
          Name: "",
          Designation: "",
          Facebook: "",
          Instagram: "",
          Twitter: "",
          Linkedin: "",
          Team: "",
          PhotoURL: "",
          Priority: "",
          Description: "",
        });
        // getTBTData();
      }
    }
  };

  // confirm box started
  const handleConfirm = async () => {
    setMessage("");
    console.log("confirm", SelectedDataForRemove);
    setShow(false);
    handleRemove(SelectedDataForRemove);
  };

  const handleDecline = async () => {
    setMessage("");
    setShow(false);
  };

  const handleOk = async () => {
    setMessage("");
    setShow(false);
  };
  // confirm box ended

  useEffect(() => {
    let teamsBehindTeams = [];
    let isDataSynced = false;

    const subscription = DataStore.observeQuery(TeamBehindTeam).subscribe(
      async (snapshot) => {
        const TeamData = [];
        teamsBehindTeams = snapshot.items;
        isDataSynced = snapshot.isSynced;
        console.log("Teams Behind Teams", teamsBehindTeams);
        // setTbtData(teamsBehindTeams);
        if (isDataSynced) {
          const groupedData = teamsBehindTeams.reduce((acc, member) => {
            const teamName = member.Team;
            if (!acc[teamName]) {
              acc[teamName] = [];
            }
            acc[teamName].push(member);
            return acc;
          }, {});
          console.log("groupedData", groupedData);
          // converting grouped data into Formatted array
          for (const [teamName, members] of Object.entries(groupedData)) {
            const team = {
              teamName: teamName, // Assuming teamName needs to be in uppercase
              members: members.map((member, index) => ({
                PhotoURL: member.PhotoURL, // Assuming you have Member1, Member2, etc. in your project
                Name: member.Name,
                Position: member.Designation,
                fbURL: member.Facebook,
                instaURL: member.Instagram,
                twitterURL: member.Twitter,
                LinkedInURL: member.Linkedin,
                Id: member.id,
                Priority: member.Priority,
                Description: member.Description,
              })),
            };
            TeamData.push(team);
          }
          const sortedTeams = TeamData.map((team) => {
            const sortedMembers = [...team.members].sort(
              (a, b) => a.Priority - b.Priority
            );
            return { ...team, members: sortedMembers };
          });
          console.log("Team Data", TeamData);
          console.log("sortedTeams", sortedTeams);
          setDataToShow(sortedTeams);
        }
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <AdminLayout>
      <div className="admin-content-container">
        <div className="admin-left-side">
          <h1 className="common-heading">Add Team Members</h1>
          <div className="white-box">
            <form onSubmit={handleSubmitForm}>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Member Name :
                </label>
                <input
                  class="form-control"
                  type="text"
                  id="example"
                  name="Name"
                  value={formValues.Name}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Member Position :
                </label>
                <input
                  class="form-control"
                  type="text"
                  id=""
                  name="Designation"
                  value={formValues.Designation}
                  onChange={handleOnChange}
                />
              </div>

              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Priority Position :
                </label>
                <input
                  class="form-control"
                  type="text"
                  id=""
                  name="Priority"
                  value={formValues.Priority}
                  onChange={handleOnChange}
                  placeholder="eg. 1, 2, 3"
                />
              </div>

              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Select Team :
                </label>
                <select
                  className="form-select"
                  aria-label=""
                  value={selectedValue}
                  onChange={handleSelectChange}
                >
                  <option disabled value="">
                    Select Team
                  </option>
                  <option value="1">Management Team</option>
                  <option value="2">Advisory Board</option>
                  <option value="3">Technical Team</option>
                </select>
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Description :
                </label>
                <textarea
                  class="form-control"
                  type="text"
                  id=""
                  name="Description"
                  value={formValues.Description}
                  onChange={handleOnChange}
                  // rows="4"
                  style={{ minHeight: "150px" }}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Facebook URL :
                </label>
                <input
                  class="form-control"
                  type="text"
                  id="example"
                  name="Facebook"
                  // value={formValues.Facebook}
                  value={formValues.Facebook}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Instagram URL :
                </label>
                <input
                  class="form-control"
                  type="text"
                  id="example"
                  name="Instagram"
                  // value={formValues.Instagram}
                  value={formValues.Instagram}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Twitter URL :
                </label>
                <input
                  class="form-control"
                  type="text"
                  id="example"
                  name="Twitter"
                  // value={formValues.Twitter}
                  value={formValues.Twitter}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  LinkedIn URL :
                </label>
                <input
                  class="form-control"
                  type="text"
                  id="example"
                  name="Linkedin"
                  // value={formValues.Linkedin}
                  value={formValues.Linkedin}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Upload Profile Image :{" "}
                  <small className="text-danger">
                    (Size should be 200 X 270 )
                  </small>
                </label>
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  name="PhotoURL"
                  ref={inputFile}
                  // value={formValues.PhotoURL}
                  onChange={(e) => {
                    validateFileType(e, "PhotoURL");
                  }}
                />
              </div>
              <div class="form-group admin-btn-container">
                {Editdataflag === false ? (
                  <button
                    className="bg-success"
                    onClick={(e) => {
                      handleSubmitForm(e);
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    className="bg-success"
                    onClick={(e) => {
                      handleEditsubmit(e);
                    }}
                  >
                    Update
                  </button>
                )}
                <button
                  className="bg-danger"
                  onClick={(e) => {
                    handleCancel(e);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="admin-right-side">
          <h1 className="common-heading">All Teams</h1>
          <div className="white-box">
            <Accordion defaultActiveKey="0">
              {dataToShow &&
                dataToShow.length > 0 &&
                dataToShow.map((data, teamIndex) => (
                  <Accordion.Item eventKey={teamIndex}>
                    <Accordion.Header>{data.teamName}</Accordion.Header>
                    <Accordion.Body>
                      <div className="tech-team-container">
                        {data.members &&
                          data.members.length > 0 &&
                          data.members.map((member, key) => {
                            return (
                              <div className="tech-team-item" key={key}>
                                <span>{member.Priority}</span>
                                <div
                                  className="team-item-wrapper"
                                  data-aos="fade-up"
                                  data-aos-once="true"
                                  data-aos-easing="ease-in-out"
                                >
                                  <div className="team-img-wrapper">
                                    <img
                                      src={member.PhotoURL}
                                      // onError={(e) =>
                                      //   (e.target.src = defaultUser)
                                      // }
                                      // src={val.PhotoURL ? val.PhotoURL : Avtar}
                                      onError={(e) => (e.target.src = Avtar)}
                                      alt="member"
                                    />
                                  </div>
                                  <div className="team-content-wrapper">
                                    <h2 className="text-center text-white">
                                      {member.Name}
                                    </h2>
                                    <h3 className="text-center text-white">
                                      {member.Position}
                                    </h3>
                                  </div>
                                  <div className="admin-match-card">
                                    <ul className="table-action-btn">
                                      <li>
                                        <BsPencilFill
                                          className="text-success"
                                          onClick={() => {
                                            handleEdit({
                                              ...member,
                                              Team: data.teamName,
                                            });
                                          }}
                                        />
                                      </li>

                                      <li>
                                        <FaTrash
                                          className="text-danger"
                                          onClick={() => {
                                            handleDelete(member);
                                          }}
                                        />
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
        </div>
      </div>

      {Show ? (
        <ConfirmationBox
          show={Show}
          message={message}
          handleConfirm={
            message == "New Team Member Added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleConfirm
          }
          handleDecline={
            message == "New Team Member Added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleDecline
          }
          handleOK={
            message == "New Team Member Added" ||
            message == "Records updated successfully!"
              ? handleOk
              : undefined
          }
        />
      ) : null}
    </AdminLayout>
  );
}

export default AddTeamBehindTeam;
