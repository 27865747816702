import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "../../../assets/css/swiper.css";
import { Pagination, Navigation } from "swiper";
import { Testimonial } from "../../../models";
import { DataStore } from "@aws-amplify/datastore";

function Testimonials() {
  const [testimonialsData, setTestimonialsData] = useState();

  useEffect(() => {
    const subscription = DataStore.observeQuery(Testimonial).subscribe(
      async (snapshot) => {
        const testimonials = snapshot.items;
        console.log("Testimonial", testimonials);
        setTestimonialsData(testimonials);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      {testimonialsData && testimonialsData.length > 0 && (
        <section className="home-testimonials-section sec-pad">
          <div className="headling-wrapper">
            <h2
              className="common-heading"
              data-aos="zoom-in"
              data-aos-once="true"
            >
              TESTIMONIALS
            </h2>
            <h4
              className="common-subheading"
              data-aos="zoom-in"
              data-aos-once="true"
            >
              WHAT THEY SAY ABOUT SNIGMAY.
            </h4>
          </div>
          <div className="container">
            <Swiper
              slidesPerView={2}
              spaceBetween={30}
              loop={true}
              pagination={{
                clickable: true,
              }}
              navigation={false}
              modules={[Pagination, Navigation]}
              breakpoints={{
                300: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
              }}
              className="mySwiper"
            >
              {testimonialsData &&
                testimonialsData.length > 0 &&
                testimonialsData.map((data) => (
                  <SwiperSlide>
                    <div
                      className="testimonials-item-wrapper"
                      data-aos="fade-up"
                      data-aos-once="true"
                    >
                      <div className="testimonials-content-wrapper">
                        <p className="mb-0">{data.Description}</p>
                      </div>
                      <div className="testimonials-writer-wrapper">
                        <div className="testimonials-writer-text-wrapper">
                          <h2>{data.Name}</h2>
                          <h4>{data.Role}</h4>
                        </div>
                        <div className="testimonials-user-img">
                          <img src={data.PhotoURL} alt="user" />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </section>
      )}
    </>
  );
}
export default Testimonials;
