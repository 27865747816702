import { DataStore } from "@aws-amplify/datastore";
import { POTM } from "../models";

export const createPOTM = async (obj) => {
  const Response = await DataStore.save(new POTM(obj));
  if (Response) {
    console.log("Response", Response);
  } else {
    console.log("No saved data found");
  }
  return Response;
};

export const updatePOTM = async (currentItem) => {
  let updatedItem;
  try {
    if (currentItem) {
      updatedItem = POTM.copyOf(currentItem, (ele) => ele);
      await DataStore.save(updatedItem);

      console.log("Updated Item:", updatedItem);
    } else {
      console.error("Item not found");
    }
    return updatedItem;
  } catch (error) {
    console.error("Error updating item:", error);
  }
};

export const deletePOTM = async (key) => {
  const modelToDelete = await DataStore.query(POTM, key);
  const Response = await DataStore.delete(modelToDelete);
  if (Response) {
    console.log("Response", Response);
  } else {
    console.log("No saved data found");
  }
  return Response;
};
