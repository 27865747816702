import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../../../assets/css/swiper.css";
import { Autoplay, Pagination, Navigation } from "swiper";

import { DataStore } from "@aws-amplify/datastore";
import { Achievements } from "../../../models";
function Achievement() {
  const [trophyData, setTrophyData] = useState();
  useEffect(() => {
    const subscription = DataStore.observeQuery(Achievements).subscribe(
      async (snapshot) => {
        const testimonials = snapshot.items;
        console.log("Testimonial", Achievements);
        setTrophyData(testimonials);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  return (
    <>
      <section className="academy-achievement-section sec-pad">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="achievement-text-wrapper">
                <h2
                  className="common-heading"
                  data-aos="zoom-in-up"
                  data-aos-once="true"
                  data-aos-easing="ease-in-out"
                >
                  Achievements
                </h2>
                <h4
                  className="common-subheading"
                  data-aos="zoom-in-up"
                  data-aos-once="true"
                  data-aos-easing="ease-in-out"
                >
                  Celebrating Our Success: Honoring Achievements and Milestones.
                </h4>
              </div>
            </div>
            <div className="col-md-8">
              <div className="academy-achievements-wrapper">
                <Swiper
                  slidesPerView={3}
                  spaceBetween={30}
                  loop={true}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    300: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                  }}
                  className="mySwiper"
                >
                  {trophyData &&
                    trophyData.length > 0 &&
                    trophyData.map((val, key) => {
                      return (
                        <SwiperSlide>
                          <div
                            className="academy-achievement-item"
                            data-aos="fade-up"
                            data-aos-once="true"
                            data-aos-easing="ease-in-out"
                          >
                            <img src={val.TrophyURL} alt="player" />
                            <div className="academy-text-wrapper">
                              <h2>{val.Title}</h2>
                              <span>({val.Position})</span>
                              <h3 className="mb-0">{val.Period}</h3>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Achievement;
