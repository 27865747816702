import React from "react";
// import introImage from "../../../assets/images/amenities/amenities.jpg"

function AmenitiesIntro() {
  const introImage =
    "https://snigmaypunefc-storage-63a42a0a100429-dev.s3.ap-south-1.amazonaws.com/public/HomeTeam/IMG_5829.jpg";
  return (
    <>
      <section className="amenities-section sec-pad">
        <div className="container">
          <div className="headling-wrapper white-headline">
            <h2 className="common-heading">Our Amenities</h2>
            <h4 className="common-subheading">STATE OF THE ART FACILITIES.</h4>
          </div>
          <div className="amenities-intro-container">
            <div className="amenities-intro-img-wrapper">
              <img src={introImage} alt="amenities" />
            </div>
            <div className="amenities-intro-content-wrapper">
              <p>
                Our club offers expert coaching, advanced training formats, and
                state-of-the-art amenities to ensure holistic development for
                young athletes.
              </p>
              <ul>
                <li>Professional curriculum for holistic player development</li>
                <li>
                  Drills for agility, movement, endurance, speed & technical
                  skills
                </li>
                <li>
                  Focus on young athletes’ physiological, psychological &
                  performance development
                </li>
                <li>Experienced national coaches</li>
                <li>
                  Interactive sessions with coaches, trainers, nutritionists and
                  senior players
                </li>
                <li>
                  Specialized training formats and sport plans for every student
                </li>
                <li>
                  Technical, tactical & mental development at all stages of the
                  game
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AmenitiesIntro;
