import React from "react";
import Sidebar from "./Sidebar";
import { withAuthenticator, useTheme } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
// import { components } from "@aws-amplify/ui/dist/types/theme/tokens/components";
import { Image } from "react-bootstrap";

function AdminLayout(props) {
  return (
    <>
      <div className="admin-main-container">
        <div className="sidebar-container">
          <Sidebar />
        </div>
        <div className="admin-right-side-wrapper">
          {/* navbar  strarted here */}
          <div className="admin-navbar-wrapper"></div>
          {/* navbar ended here */}
          <div className="admin-content-wrapper">{props.children}</div>
        </div>
      </div>
    </>
  );
}
const components = {
  // Header() {
  //   const { tokens } = useTheme();

  //   return (
  //     <div
  //       textAlign="center"
  //       padding={tokens.space.large}
  //       style={{ background: "red" }}
  //     >
  //       <img
  //         alt="Amplify logo"
  //         src="https://docs.amplify.aws/assets/logo-dark.svg"
  //       />
  //     </div>
  //   );
  // },
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <h2
          className="login-text-heading"
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Welcome To SNIGMAY
        </h2>
      );
    },
  },
};

export default withAuthenticator(AdminLayout, {
  hideSignUp: true,
  components: components,
});
