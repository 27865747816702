import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../../../assets/css/swiper.css";
import { Autoplay, Pagination, Navigation } from "swiper";

import VintageThreads from "../../../assets/images/partners/vintage-threads.png";
import Techzy from "../../../assets/images/partners/techzy.jpg";
import Snigmay from "../../../assets/images/partners/snigmay.png";
import SnigmayFoundateion from "../../../assets/images/partners/snigmay-foundation.png";
import Radhika from "../../../assets/images/partners/radhika-construction.jpg";
import Veggaline from "../../../assets/images/partners/vegaaline.jpg";

function Partners() {
  return (
    <section className="home-partners-section sec-pad">
      <div className="headling-wrapper">
        <h2 className="common-heading" data-aos="zoom-in" data-aos-once="true">
          Sponsors & Partners
        </h2>
      </div>
      <div className="container">
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          loop={true}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            300: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="partner-item">
              <a href="javascript:void(0)" target="_blank">
                <img src={Veggaline} alt="partner" />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="partner-item">
              <a href="javascript:void(0)" target="_blank">
                <img src={VintageThreads} alt="partner" />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="partner-item">
              <a href="javascript:void(0)" target="_blank">
                <img src={Snigmay} alt="partner" />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="partner-item">
              <a href="javascript:void(0)" target="_blank">
                <img src={Techzy} alt="partner" />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="partner-item">
              <a href="javascript:void(0)" target="_blank">
                <img src={Radhika} alt="partner" />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="partner-item">
              <a href="javascript:void(0)" target="_blank">
                <img src={SnigmayFoundateion} alt="partner" />
              </a>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
}

export default Partners;
