import React from 'react';
import Layout from '../../../components/Layout'
import Banner from "../../../components/Banner";
import BannerImage from "../../../assets/images/banner/banner-1.jpg";

function Merchandise() {
    return (
        <Layout>
            <Banner BannerImage={BannerImage} heading="Merchandise" url="/fan-zone/merchandise" />
            <section className='merch-section sec-pad'>
                <div className="container">
                    <div className="headling-wrapper white-headline">
                        <h2 className="common-heading" data-aos="zoom-in" data-aos-once="true" data-aos-easing="ease-in-out">Merchandise</h2>
                    </div>
                    <div className="row"></div>
                </div>
            </section>
        </Layout>
    )
}

export default Merchandise;
