import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "../../../components/AdminLayout";
import DefaultBanner from "../../../assets/images/default/default-banner.png";
import { FaTrash } from "react-icons/fa";
import { BsPencilFill } from "react-icons/bs";
import { uploadData } from "@aws-amplify/storage";
import { DataStore } from "@aws-amplify/datastore";
import { Videos } from "../../../models";
import { createVideos } from "../../../api/VideoAPI";
import ConfirmationBox from "../../../components/ConfirmationBox";
import { awsmobile } from "../../../aws-exports";

function AddVideo() {
  const [Show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [SelectedDataForRemove, setSelectedDataForRemove] = useState({});
  const [videoData, setVideoData] = useState();
  const [photoFile, setPhotoFile] = useState();
  const [videoFile, setVideoFile] = useState();
  const [Commonfileurl, setCommonfileurl] = useState();
  const [Editdataflag, setEditdataflag] = useState(false);
  const [EditObj, setEditObj] = useState({});
  const awsBucket = awsmobile.aws_user_files_s3_bucket;
  const [formValues, setFormValues] = useState({
    Title: "",
    CoverPhoto: "",
    VideoURL: "",
  });
  const inputFile = useRef(null);
  const inputFile2 = useRef(null);

  const validateFileType = async (e, Type) => {
    console.log("eventvalue", e);
    var file = e.target.files[0];
    const fileurl = URL.createObjectURL(file);
    setCommonfileurl(fileurl);
    console.log("fileurl", file, fileurl);
    var fileType = file.type;
    if (Type === "CoverPhoto") {
      if (
        fileType === "image/jpg" ||
        fileType === "image/jpeg" ||
        fileType === "image/png"
      ) {
        setPhotoFile(file);
      } else {
        alert("Only jpg/jpeg and png files are allowed!");
      }
    } else if (Type === "VideoURL") {
      if (fileType === "video/mp4") {
        setVideoFile(file);
      } else {
        alert("Only mp4 files are allowed!");
      }
    } else {
      alert("Invalid Type specified!");
    }
  };

  const uploadLogoFileToStorage = async (folderName, file) => {
    console.log("filetype", file.type, file.size);
    const contentType = file.type;
    let fileName = file.name.replaceAll(" ", "_");
    console.log("hellocontent", file.name);
    console.log("contentType", contentType);
    try {
      const uploadTask = await uploadData({
        key: `${folderName}/${fileName}`,
        data: file,
        options: {
          contentType: contentType,
        },
      }).result;

      console.log("Succeeded: ", uploadTask);
      let Response = `https://${awsBucket}.s3.ap-south-1.amazonaws.com/public/${folderName}/${fileName}`;

      return Response;
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const getVideos = () => {
    const subscription = DataStore.observeQuery(Videos).subscribe(
      async (snapshot) => {
        const videos = snapshot.items;
        console.log("Videos", videos);
        setVideoData(videos);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (Editdataflag === false) {
      if (!formValues.Title && !formValues.CoverPhoto && !formValues.VideoURL) {
        alert("Please fill in all required fields.");
        return;
      }
      try {
        console.log("formValues", formValues);
        let CreateObj = { ...formValues };
        console.log("CreateObj", CreateObj);
        let coverPhoto = "";
        let videoURL = "";
        if (photoFile && videoFile && Commonfileurl) {
          coverPhoto = await uploadLogoFileToStorage(
            "CoverPhoto",
            photoFile,
            Commonfileurl
          );
          videoURL = await uploadLogoFileToStorage(
            "VideoURL",
            videoFile,
            Commonfileurl
          );
        }

        CreateObj.Title = formValues.Title;
        CreateObj.CoverPhoto = coverPhoto;
        CreateObj.VideoURL = videoURL;

        console.log(
          "cover photo and video file",
          CreateObj,
          coverPhoto,
          videoURL
        );

        const response = await createVideos(CreateObj);
        console.log("Match data saved successfully:", response);
        setMessage("Video added");
        setShow(true);
        inputFile.current.value = "";
        inputFile2.current.value = "";
        setFormValues({
          Title: "",
          CoverPhoto: "",
          VideoURL: 0,
        });
        getVideos();
      } catch (error) {
        console.error("Error saving match data:", error);
      }
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleRemove = async (data) => {
    console.log("data", data);
    try {
      const response = await DataStore.delete(Videos, data.id);
      getVideos();
    } catch (error) {}
  };

  const handleDelete = (data) => {
    console.log("handleDelete", data);
    setSelectedDataForRemove(data);
    setMessage("Do you really want to delete these records?");
    setShow(true);
  };

  const handleEdit = async (data) => {
    console.log("Edit", data);
    setEditObj(data);
    setEditdataflag(true);
    setFormValues({
      Title: data.Title,
      CoverPhoto: data.CoverPhoto,
      VideoURL: data.VideoURL,
    });
  };

  const handleEditsubmit = async () => {
    console.log("formValues", formValues);
    let coverPhoto = "";
    let videoURL = "";
    if (photoFile && videoFile && Commonfileurl) {
      coverPhoto = await uploadLogoFileToStorage(
        "CoverPhoto",
        photoFile,
        Commonfileurl
      );
      videoURL = await uploadLogoFileToStorage(
        "VideoURL",
        videoFile,
        Commonfileurl
      );
    }

    const MatchObj = await DataStore.query(Videos, EditObj.id);
    console.log("MatchObj", MatchObj);
    if (MatchObj) {
      const MatchUpdatedObj = Videos.copyOf(MatchObj, (updated) => {
        updated.Title = formValues.Title;
        updated.CoverPhoto =
          coverPhoto !== "" ? coverPhoto : updated.CoverPhoto;
        updated.VideoURL = videoURL !== "" ? videoURL : updated.VideoURL;
        return updated;
      });
      console.log("MatchUpdatedObj", MatchUpdatedObj);
      const ResultofMatchTeam = await DataStore.save(MatchUpdatedObj);
      await setMessage("Records updated successfully!");
      setShow(true);
      if (ResultofMatchTeam) {
        inputFile.current.value = "";
        inputFile2.current.value = "";
        setFormValues({
          Title: "",
          CoverPhoto: "",
          VideoURL: "",
        });
        getVideos();
      }
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setFormValues({
      Title: "",
      CoverPhoto: "",
      VideoURL: "",
    });
    inputFile.current.value = "";
    inputFile2.current.value = "";
    setPhotoFile(null);
    setVideoFile(null);
    setCommonfileurl(null);
    setEditdataflag(false);
  };

  // confirm box started
  const handleConfirm = async () => {
    setMessage("");
    console.log("confirm", SelectedDataForRemove);
    setShow(false);
    handleRemove(SelectedDataForRemove);
  };

  const handleDecline = async () => {
    setMessage("");
    setShow(false);
  };

  const handleOk = async () => {
    setMessage("");
    setShow(false);
  };
  // confirm box ended

  useEffect(() => {
    getVideos();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-content-container">
        <div className="admin-left-side">
          <h1 className="common-heading">Add Videos</h1>
          <div className="white-box">
            <form action="submit" onSubmit={handleSubmitForm}>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Video Title :
                </label>
                <input
                  class="form-control"
                  placeholder="Enter Title"
                  type="text"
                  id="example"
                  name="Title"
                  value={formValues.Title}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Upload Cover Photo :
                  <small className="text-danger">
                    (Size should be 360 X 220 )
                  </small>
                </label>
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  name="CoverPhoto"
                  ref={inputFile}
                  onChange={(e) => {
                    validateFileType(e, "CoverPhoto");
                  }}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Upload Video :
                </label>
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  name="VideoURL"
                  ref={inputFile2}
                  onChange={(e) => {
                    validateFileType(e, "VideoURL");
                  }}
                />
              </div>
              <div class="form-group admin-btn-container">
                {Editdataflag == false ? (
                  <button
                    className="bg-success"
                    onClick={() => {
                      handleSubmitForm();
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    className="bg-success"
                    onClick={() => {
                      handleEditsubmit();
                    }}
                  >
                    Update
                  </button>
                )}
                <button
                  className="bg-danger"
                  onClick={(e) => {
                    handleCancel(e);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="admin-right-side">
          <h1 className="common-heading">All Videos</h1>
          <div className="white-box">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th width="10%" className="text-center">
                    SN
                  </th>
                  <th width="40%" className="text-center">
                    Cover Photo
                  </th>
                  <th width="40%" className="text-center">
                    Video
                  </th>
                  <th width="10%">Action</th>
                </tr>
              </thead>
              <tbody>
                {videoData &&
                  videoData.length > 0 &&
                  videoData.map((data, index) => (
                    <tr>
                      <td className="text-center">{index + 1}</td>
                      <td>
                        <img
                          src={data.CoverPhoto}
                          style={{
                            width: "200px",
                            maxHeight: "122px",
                            objectFit: "contain",
                          }}
                          onError={(e) => (e.target.src = DefaultBanner)}
                          alt="user"
                        />
                      </td>
                      <td>
                        <video width="100%" controls>
                          <source src={data.VideoURL} type="video/mp4" />
                        </video>
                      </td>
                      <td>
                        <ul className="table-action-btn">
                          <li>
                            <BsPencilFill
                              className="text-success"
                              onClick={() => {
                                handleEdit(data);
                              }}
                            />
                          </li>

                          <li>
                            <FaTrash
                              className="text-danger"
                              onClick={() => {
                                handleDelete(data);
                              }}
                            />
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {Show ? (
        <ConfirmationBox
          show={Show}
          message={message}
          handleConfirm={
            message == "Video added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleConfirm
          }
          handleDecline={
            message == "Video added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleDecline
          }
          handleOK={
            message == "Video added" ||
            message == "Records updated successfully!"
              ? handleOk
              : undefined
          }
        />
      ) : null}
    </AdminLayout>
  );
}

export default AddVideo;
