import React, { useEffect, useState } from "react";
//
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../../../assets/css/swiper.css";
import { Autoplay, Pagination, Navigation } from "swiper";
import { DataStore } from "@aws-amplify/datastore";
import { POTM } from "../../../models";
import DefaultUser from "../../../assets/images/avtar/snigmay-avtar-bg.png";
//
function POTMPage() {
  const [potmData, setPotmData] = useState();
  const [allPlayer, setAllPlayer] = useState();
  useEffect(() => {
    const subscription = DataStore.observeQuery(POTM).subscribe(
      async (snapshot) => {
        const potms = snapshot.items;
        console.log("potms", potms);
        setPotmData(potms);
        const allPlayersData = potms.flatMap((item) => item.Players);
        console.log("all players", allPlayersData);
        setAllPlayer(allPlayersData);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      {allPlayer && allPlayer.length > 0 && (
        <section className="potm-section sec-pad">
          <div className="headling-wrapper">
            <h2
              className="common-heading"
              data-aos="zoom-in-up"
              data-aos-once="true"
              data-aos-easing="ease-in-out"
            >
              Player of the month
            </h2>
            <h4
              className="common-subheading"
              data-aos="zoom-in-up"
              data-aos-once="true"
              data-aos-easing="ease-in-out"
            >
              Spotlight on Excellence
            </h4>
          </div>
          <div class="banner-shape shape-1"></div>
          <div class="banner-shape shape-2"></div>
          <div class="banner-shape shape-3"></div>
          <div class="banner-shape shape-4"></div>
          <div class="banner-shape shape-5"></div>
          <div class="banner-shape shape-6"></div>

          <div className="container">
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              loop={true}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                300: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              className="mySwiper"
            >
              {potmData &&
                potmData.length > 0 &&
                potmData.map((data, teamIndex) =>
                  data.Players && data.Players.length > 0
                    ? data.Players.map((player, key) => (
                        <SwiperSlide key={key}>
                          <div
                            className="potm-item"
                            data-aos="fade-up"
                            data-aos-once="true"
                            data-aos-easing="ease-in-out"
                          >
                            <img
                              src={player.PhotoURL}
                              onError={(e) => (e.target.src = DefaultUser)}
                              alt={player.Name}
                            />
                            <div className="potm-text-wrapper">
                              <h2>{player.Name}</h2>
                              <span>{data.Month}</span>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))
                    : null
                )}
            </Swiper>
          </div>
        </section>
      )}
    </>
  );
}
export default POTMPage;
