import React, { useEffect, useState } from "react";
import { DataStore } from "@aws-amplify/datastore";
import { ScrollingText } from "../models";

function ScrollingTextComp() {
  const [scrollingTextData, setScrollingTextData] = useState();
  useEffect(() => {
    const subscription = DataStore.observeQuery(ScrollingText).subscribe(
      async (snapshot) => {
        const scrollingTexts = snapshot.items;
        setScrollingTextData(scrollingTexts);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <div className="header-marq-container">
      <marquee behavior="" direction="">
        {scrollingTextData &&
          scrollingTextData.length > 0 &&
          scrollingTextData.map((val, key) => {
            return <span>{val.Text}</span>;
          })}
      </marquee>
    </div>
  );
}

export default ScrollingTextComp;
