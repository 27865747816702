import React from "react";
import Layout from "../../components/Layout";
import Banner from "../../components/Banner";
import BannerImage from "../../assets/images/banner/banner-1.jpg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ImageGrid from "./Gallery/ImageGrid";
import VideoGrid from "./Gallery/VideoGrid";
import { Helmet } from "react-helmet";

function Gallery() {
  return (
    <>
      <Layout>
        <Helmet>
          <title>Our Amazing Gallery Walk </title>
          <meta
            name="description"
            content="Experience the journey of Snigmay Pune Football Club through our captivating gallery walk."
          />
          <meta name="keywords" content="Gallery walk " />
        </Helmet>
        <Banner BannerImage={BannerImage} heading="Gallery" url="/gallery" />
        <section className="gallery-section sec-pad">
          <div className="container">
            <Tabs defaultActiveKey="photos" id="">
              <Tab eventKey="photos" title="Photos">
                <ImageGrid />
              </Tab>
              <Tab eventKey="videos" title="Videos">
                <VideoGrid />
              </Tab>
            </Tabs>
          </div>
        </section>
      </Layout>
    </>
  );
}

export default Gallery;
