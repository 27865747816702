import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../../../assets/css/swiper.css";
import { Autoplay, Pagination, Navigation } from "swiper";
import DefaultUser from "../../../assets/images/avtar/snigmay-avtar-bg.png";

import { DataStore } from "@aws-amplify/datastore";
import { COTM } from "../../../models";
function COTMPage() {
  const [cotmData, setCotmData] = useState();
  const [allCoach, setAllCoach] = useState();

  useEffect(() => {
    const subscription = DataStore.observeQuery(COTM).subscribe(
      async (snapshot) => {
        const cotms = snapshot.items;
        console.log("coach of the month", cotms);
        setCotmData(cotms);
        const allCoachesData = cotms.flatMap((item) => item.Coaches);
        console.log("all coaches", allCoachesData);
        setAllCoach(allCoachesData);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      {allCoach && allCoach.length > 0 && (
        <section className="potm-section cotm-section sec-pad">
          <div className="headling-wrapper">
            <h2
              className="common-heading"
              data-aos="zoom-in"
              data-aos-once="true"
              data-aos-easing="ease-in-out"
            >
              Coach of the month
            </h2>
            <h4
              className="common-subheading"
              data-aos="zoom-in"
              data-aos-once="true"
              data-aos-easing="ease-in-out"
            >
              Celebrating Excellence
            </h4>
          </div>
          <div class="banner-shape shape-1"></div>
          <div class="banner-shape shape-2"></div>
          <div class="banner-shape shape-3"></div>
          <div class="banner-shape shape-4"></div>
          <div class="banner-shape shape-5"></div>
          <div class="banner-shape shape-6"></div>

          <div className="container">
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              loop={true}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                300: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              className="mySwiper"
            >
              {cotmData &&
                cotmData.length > 0 &&
                cotmData.map((data, teamIndex) =>
                  data.Coaches && data.Coaches.length > 0
                    ? data.Coaches.map((coach, key) => (
                        <SwiperSlide key={key}>
                          <div
                            className="potm-item"
                            data-aos="fade-up"
                            data-aos-once="true"
                            data-aos-easing="ease-in-out"
                          >
                            <img
                              src={coach.PhotoURL}
                              onError={(e) => (e.target.src = DefaultUser)}
                              alt={DefaultUser}
                            />
                            <div className="potm-text-wrapper">
                              <h2>{coach.Name}</h2>
                              <span>{data.Month}</span>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))
                    : null
                )}
            </Swiper>
          </div>
        </section>
      )}
    </>
  );
}
export default COTMPage;
