import React from "react";

function WhySnigmay() {
  return (
    <section className="home-why-snigmay-section">
      <div className="why-snigmay-overlay sec-pad">
        <div className="container">
          <div className="headling-wrapper">
            <h2 className="common-heading">Why Snigmay</h2>
            <h4 className="common-subheading">Learn to play the snigmay way</h4>
          </div>
          <div className="row">
            <div className="animated-ellipses col-md-6">
              <div className="ellipse"></div>
              <div className="ellipse"></div>
              <div className="ellipse"></div>
              <div className="ellipse"></div>
              <div className="count-wrapper appear-animate blurIn appear-animation-visible">
                <label>8</label>
              </div>
              <div className="text-reasons">
                <h3 className="text-white text-uppercase font-weight-bolder mb-2 appear-animate fadeInLeftShorter appear-animation-visible">
                  Reasons
                </h3>
                <h3 className="text-white text-uppercase font-weight-bolder mb-2 appear-animate fadeInLeftShorter appear-animation-visible">
                  Why you should choose
                </h3>
                <h3 className="text-white text-uppercase font-weight-bolder mb-0 appear-animate fadeInLeftShorter appear-animation-visible">
                  SNIGMAY
                </h3>
              </div>
            </div>
            <div className="col-md-6">
              <div className="why-snigmay-container">
                {/* <h3>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit labore
                  et dolore magna aliqua.
                </h3> */}
                <ul>
                  <li>Best in class player development program </li>
                  <li>Best in class facilities </li>
                  <li>Superlative coaching team </li>
                  <li>
                    Opportunity to play at National, District & State level
                    tournaments{" "}
                  </li>
                  <li>Technology driven assessment system </li>
                  <li>
                    Proven track record of producing players at National,
                    University & District level{" "}
                  </li>
                  <li>
                    Best career pathway to become a professional player from
                    academy team to Senior teams{" "}
                  </li>
                  <li>AIFF, WIFA & PDFA recognised club & youth academy </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhySnigmay;
