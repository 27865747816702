import React from "react";
import { IoMdFootball } from "react-icons/io";
import { BiPlus } from "react-icons/bi";
import { BiTrophy } from "react-icons/bi";
import { AiOutlineTeam } from "react-icons/ai";
import { TbTargetArrow } from "react-icons/tb";
import { useEffect, useState } from "react";
function Counter() {
  const [count, setCount] = useState(0);
  const [startCounter, setStartCounter] = useState(false);

  useEffect(() => {
    const counter = (id, start, end, duration) => {
      let obj = document.getElementById(id),
        current = start,
        range = end - start,
        increment = end > start ? 1 : -1,
        step = Math.abs(Math.floor(duration / range)),
        timer = setInterval(() => {
          current += increment;
          obj.textContent = current;
          if (current === end) {
            clearInterval(timer);
          }
        }, step);
    };

    const scrollHandler = () => {
      const counterSection = document.getElementById("counter-section");
      const rect = counterSection.getBoundingClientRect();
      const isInViewport = rect.top <= window.innerHeight;

      if (isInViewport) {
        counter("count1", 0, 400, 3000);
        counter("count2", 0, 250, 3000);
        counter("count3", 0, 70, 3000);
        counter("count4", 0, 38, 3000);
        window.removeEventListener("scroll", scrollHandler);
      }
    };

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <>
      <section id="counter-section" className="home-counter-sec sec-pad">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-6">
              <div
                className="home-counter-item"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-easing="ease-in-out"
                data-aos-delay="50"
              >
                <div className="home-counter-icon-wrapper">
                  <IoMdFootball />
                </div>
                <div className="home-counter-content-wrapper">
                  <h2>
                    <span id="count1"></span> <BiPlus />
                  </h2>
                  <h3>Matches Played</h3>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div
                className="home-counter-item"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-easing="ease-in-out"
                data-aos-delay="300"
              >
                <div className="home-counter-icon-wrapper">
                  <BiTrophy />
                </div>
                <div className="home-counter-content-wrapper">
                  <h2>
                    <span id="count2"></span> <BiPlus />
                  </h2>
                  <h3>Matches Won</h3>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div
                className="home-counter-item"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-easing="ease-in-out"
                data-aos-delay="550"
              >
                <div className="home-counter-icon-wrapper">
                  <AiOutlineTeam />
                </div>
                <div className="home-counter-content-wrapper">
                  <h2>
                    <span id="count3"></span> <BiPlus />
                  </h2>
                  <h3>Team Members</h3>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div
                className="home-counter-item"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-easing="ease-in-out"
                data-aos-delay="800"
              >
                <div className="home-counter-icon-wrapper">
                  <TbTargetArrow />
                </div>
                <div className="home-counter-content-wrapper">
                  <h2>
                    <span id="count4"></span> <BiPlus />
                  </h2>
                  <h3>Awards Won</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Counter;
