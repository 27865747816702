import React from "react";
import { Link, useLocation } from "react-router-dom";
// import AcademyGround from "../../../assets/images/home/academy.jpg";
function Academy(props) {
  const location = useLocation();
  const AcademyGround =
    "https://snigmaypunefc-storage-63a42a0a100429-dev.s3.ap-south-1.amazonaws.com/public/HomeTeam/IMG_26899.jpg";
  const AcademyRight =
    "https://snigmaypunefc-storage-63a42a0a100429-dev.s3.ap-south-1.amazonaws.com/public/HomePage/IMG_2721.JPG";

  const isAcademy = location.pathname === "/academy";

  const secondaryImage =
    location.pathname === "/academy" ? AcademyRight : AcademyGround;
  return (
    <>
      <section className="home-academy-section sec-pad">
        <div className="container">
          <div className="home-academy-content-wrapper">
            <h2
              className="common-heading"
              data-aos="zoom-in"
              data-aos-once="true"
            >
              Our Academy
            </h2>
            <h4
              className="common-subheading"
              data-aos="zoom-in"
              data-aos-once="true"
            >
              Empowering Dreams, where talents soar and champions are made—our
              academy shapes futures."
            </h4>
            <p>
              Snigmay Pune FC takes pride in being a club where we focus towards
              youth development and creating an ecosystem where talented players
              can shape up their skills to be a professional player.
            </p>
            <p>
              We started our Academy in 2019 and since then, have grown on to
              have multiple centers of excellence in Pune. We focus on the
              development of all Youth Team players, aiming to prepare them for
              playing in the Senior team.
            </p>

            {isAcademy && (
              <>
                <p>
                  Our players get all round development covering Mental,
                  Physical, Technical and Tactical guidance, which is at par
                  with professional academies in the country.
                </p>
                <p>
                  Our sole purpose is to train players from the grassroot level
                  to help them hone their skills from a young age, along with
                  making the sport enjoyable for them to help them develop
                  skills as well as retain interest in it. Our sessions are
                  challenging and engaging while promoting a positive and
                  supportive environment. The academy delivers both introductory
                  and competitive football programs to our academy players.
                </p>
                <p>
                  We firmly believe that just a few hours of on ground training
                  is not enough in todays’ competitive & evolved footballing
                  landscape, therefore, we’ve invested heavily in to technology
                  too, with GPS tracking, app based performance updates, video
                  analysis etc as a part of their regular curriculum that helps
                  a holistic development of a player.{" "}
                </p>
              </>
            )}
            <h6 className="common-heading mt-4">Facilities</h6>
            <div className="facilities-container">
              <ul>
                <li
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="300"
                  data-aos-easing="ease-in-out"
                >
                  Natural & Artificial grass grounds
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="600"
                  data-aos-easing="ease-in-out"
                >
                  Gym for S&C sessions
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="900"
                  data-aos-easing="ease-in-out"
                >
                  GPS & App for performance tracking
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="1200"
                  data-aos-easing="ease-in-out"
                >
                  Audio Visual conference rooms for video analysis
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="1500"
                  data-aos-easing="ease-in-out"
                >
                  Hydro sessions
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="1800"
                  data-aos-easing="ease-in-out"
                >
                  Diet consultation
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="1800"
                  data-aos-easing="ease-in-out"
                >
                  Advanced locker rooms
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="1800"
                  data-aos-easing="ease-in-out"
                >
                  Physios & medical assistance
                </li>
              </ul>
            </div>
            {!props.hideButton && (
              <Link to="/academy">
                <div className="home-btn-animation">Know More..</div>
              </Link>
            )}
          </div>
          <div className="home-academy-img-wrapper">
            <img
              src={secondaryImage}
              alt="academy"
              data-aos="slide-left"
              data-aos-once="true"
            />
            <div className="home-academy-img-contact">
              <h3>Contact Us to Join</h3>
              <h2>Call : (+91)9920614314</h2>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Academy;
