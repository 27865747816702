import React, { useState, useEffect } from "react";
import "./Loader.css";
import LaunchVideo from "../../../assets/Videos/launch-video.mp4";

function VideoLoader() {
  const [isVideoFinished, setIsVideoFinished] = useState(false);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    const videoElement = document.getElementById("splash-video");
    videoElement.onended = () => {
      setIsVideoFinished(true);
    };
  }, []);
  //   useEffect(() => {
  //     setTimeout(() => {
  //       setIsMuted(false);
  //     }, 1000);
  //   }, []);
  if (isVideoFinished) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "#000",
        zIndex: 9999,
      }}
    >
      <video
        id="splash-video"
        style={{ width: "100%", height: "100%" }}
        autoPlay
        muted={isMuted}
        loop
      >
        <source src={LaunchVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {isMuted && (
        <button
          onClick={() => setIsMuted(false)}
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "#fff",
            padding: "10px",
            border: "none",
            cursor: "pointer",
          }}
        >
          Unmute
        </button>
      )}
    </div>
  );
}

export default VideoLoader;
