import React from 'react';
// import Member1 from "../../../assets/images/team/member-1.jpg";
// import Member2 from "../../../assets/images/team/member-2.jpg";
import Trophy1 from "../../../assets/images/trophy/Trophy-1.png";
import Trophy2 from "../../../assets/images/trophy/Trophy-2.png";
import Trophy3 from "../../../assets/images/trophy/Trophy-3.png";
import Trophy4 from "../../../assets/images/trophy/Trophy-4.png";

function TeamAchievement() {
    const TeamData = [
        {
            PhotoURL: Trophy1,
            Name: "Santosh Trophy",
            Position: "2023",
        },
        {
            PhotoURL: Trophy2,
            Name: "Santosh Trophy",
            Position: "2023",
        },
        {
            PhotoURL: Trophy3,
            Name: "Santosh Trophy",
            Position: "2023",
        },
        {
            PhotoURL: Trophy4,
            Name: "Santosh Trophy",
            Position: "2023",
        },
        {
            PhotoURL: Trophy1,
            Name: "Santosh Trophy",
            Position: "2023",
        },
        {
            PhotoURL: Trophy2,
            Name: "Santosh Trophy",
            Position: "2023",
        },
        {
            PhotoURL: Trophy3,
            Name: "Santosh Trophy",
            Position: "2023",
        },
        {
            PhotoURL: Trophy4,
            Name: "Santosh Trophy",
            Position: "2023",
        },
    ];
    return (
        <section className='teams-player-container'>
            <div className="tech-team-container">
                {TeamData &&
                    TeamData.length > 0 &&
                    TeamData.map((val, key) => {
                        return (
                            <div className="tech-team-item">
                                <div className="team-item-wrapper" data-aos="fade-up" data-aos-once="true" data-aos-easing="ease-in-out">
                                    <div className="team-img-wrapper">
                                        <img src={val.PhotoURL} alt="member" />
                                    </div>
                                    <div className="team-content-wrapper">
                                        <h2 className='text-center'>{val.Name}</h2>
                                        <h3 className='text-center'>{val.Position}</h3>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </section>
    )
}

export default TeamAchievement
