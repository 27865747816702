import React from "react";
import Layout from "../../../components/Layout";
import Banner from "../../../components/Banner";
import BannerImage from "../../../assets/images/banner/banner-1.jpg";
import CSRIntro from "./CSRIntro";
import CSRProgramme from "./CSRProgramme";
import CSRVideo from "./CSRVideo";
import { Helmet } from "react-helmet";

function CSR() {
  return (
    <Layout>
      <Helmet>
        <title>Our CSR Activities </title>
        <meta
          name="description"
          content="Snigmay Pune FC supports local development through social impact programs, philanthropic efforts, and community outreach, enhancing engagement and contributing to positive change."
        />
        <meta
          name="keywords"
          content="CSR Activities, Community Initiatives "
        />
      </Helmet>
      <Banner BannerImage={BannerImage} heading="CSR" url="/csr" />
      <CSRIntro />
      <CSRVideo />
      <CSRProgramme />
    </Layout>
  );
}

export default CSR;
