import { DataStore } from "@aws-amplify/datastore";
import { PressRelease } from "../models";

export const createPress = async (obj) => {
  const Response = await DataStore.save(new PressRelease(obj));
  if (Response) {
    console.log("Response", Response);
  } else {
    console.log("No saved data found");
  }
  return Response;
};

export const updatePress = async (currentItem) => {
  let updatedItem;
  try {
    if (currentItem) {
      updatedItem = PressRelease.copyOf(currentItem, (ele) => ele);
      await DataStore.save(updatedItem);

      console.log("Updated Item:", updatedItem);
    } else {
      console.error("Item not found");
    }
    return updatedItem;
  } catch (error) {
    console.error("Error updating item:", error);
  }
};

export const deletePress = async (key) => {
  const modelToDelete = await DataStore.query(PressRelease, key);
  const Response = await DataStore.delete(modelToDelete);
  if (Response) {
    console.log("Response", Response);
  } else {
    console.log("No saved data found");
  }
  return Response;
};
