import React from "react";
// import AC from "../../../assets/images/amenities/icon/bedroom.png";
// import Medical from "../../../assets/images/amenities/icon/cardiogram.png";
// import PhysioTherepy from "../../../assets/images/amenities/icon/rehabilitation.png";
// import Canteen from "../../../assets/images/amenities/icon/restaurant.png";
// import HotWater from "../../../assets/images/amenities/icon/shower.png";
// import GYM from "../../../assets/images/amenities/icon/treadmill.png";
// import PurifiedWater from "../../../assets/images/amenities/icon/water.png";
import PollImage from "../../../assets/images/amenities/pool.jpg";
import GPSdevice from "../../../assets/images/amenities/gps.png";
import grassGround from "../../../assets/images/amenities/grass-ground.png";

function Facilities() {
  //   const FacilitiesData = [
  //     {
  //       PhotoUrl: AC,
  //       Heading: "AIR CONDITIONED ROOM",
  //       content:
  //         "We have our own hostel for players with ambient environment where the players spend most of their off-field time relaxing, studying & enjoying with fellow players. Our hostel is well guarded and is second home for the players.",
  //     },
  //     {
  //       PhotoUrl: Medical,
  //       Heading: "Medical Facilities",
  //       content:
  //         "Besides providing medical insurance, the academy has arranged with Doctor for priority check up and treatment to academy players. The academy also has a ‘sick room’ for specific care and treatment.",
  //     },
  //     {
  //       PhotoUrl: PhysioTherepy,
  //       Heading: "In-house Physiotherapist",
  //       content:
  //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.",
  //     },
  //     {
  //       PhotoUrl: Canteen,
  //       Heading: "Healthy Food",
  //       content:
  //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.",
  //     },
  //     {
  //       PhotoUrl: HotWater,
  //       Heading: "Hot & Cold Water",
  //       content:
  //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.",
  //     },
  //     {
  //       PhotoUrl: GYM,
  //       Heading: "Gym",
  //       content:
  //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.",
  //     },
  //     {
  //       PhotoUrl: PurifiedWater,
  //       Heading: "Purified Drinking Water",
  //       content:
  //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.",
  //     },
  //   ];

  const FacilitiesData = [
    {
      PhotoUrl:
        "https://snigmaypunefc-storage-63a42a0a7ed69-prod.s3.ap-south-1.amazonaws.com/public/Amenities/image+6.png",
      Heading: "In-house Gym",
      content: "",
    },
    {
      PhotoUrl:
        "https://snigmaypunefc-storage-63a42a0a7ed69-prod.s3.ap-south-1.amazonaws.com/public/Amenities/image+13.png",
      Heading: "World Class Turf",
      content: "",
    },
    {
      PhotoUrl:
        "https://snigmaypunefc-storage-63a42a0a7ed69-prod.s3.ap-south-1.amazonaws.com/public/Amenities/image+8.png",
      Heading: "Video Analysis Room",
      content: "",
    },
    {
      PhotoUrl:
        "https://snigmaypunefc-storage-63a42a0a7ed69-prod.s3.ap-south-1.amazonaws.com/public/Amenities/image+10.png",
      Heading: "On Ground Drill",
      content: "",
    },
    {
      PhotoUrl: PollImage,
      Heading: "Swimming/Hydro Sessions",
      content: "",
    },
    {
      PhotoUrl:
        "https://snigmaypunefc-storage-63a42a0a7ed69-prod.s3.ap-south-1.amazonaws.com/public/Amenities/image+11.png",
      Heading: "Digital Session",
      content: "",
    },
    {
      PhotoUrl: GPSdevice,
      Heading: "Use of advance technology",
      content: "",
    },
    {
      PhotoUrl: grassGround,
      Heading: "AIFF Approved natural grass ground",
      content: "",
    },
  ];

  return (
    <>
      <section className="sec-pad">
        <div className="container">
          <div className="headling-wrapper white-headline">
            <h2 className="common-heading">Our Facilities</h2>
            {/* <h4 className="common-subheading">Lorem ipsum dolor.</h4> */}
          </div>
          <div className="amenities-facilities-container">
            <div className="row">
              {FacilitiesData &&
                FacilitiesData.length > 0 &&
                FacilitiesData.map((val, key) => {
                  return (
                    <div className="col-md-4">
                      <div className="amenities-facilities-item">
                        <div className="amenities-facilities-icon-wrapper">
                          <img src={val.PhotoUrl} alt={val.PhotoUrl} />
                        </div>
                        <div className="amenities-facilities-content-wrapper">
                          <h4>{val.Heading}</h4>
                          {/* <p>{val.content}</p> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Facilities;
