import React, { useEffect, useState } from "react";
import { PressRelease } from "../../../models";
import { DataStore } from "@aws-amplify/datastore";
import Modal from "react-bootstrap/Modal";

function PressCard() {
  const [pressData, setPressData] = useState();
  const [pressDetails, setPressDetails] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (values) => {
    setShow(true);
    setPressDetails(values);
    console.log("values", values);
  };

  useEffect(() => {
    const subscription = DataStore.observeQuery(PressRelease).subscribe(
      async (snapshot) => {
        const pressReleases = snapshot.items;
        console.log("press Releases", pressReleases);
        setPressData(pressReleases);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      <section className="press-section sec-pad">
        <div className="container">
          <div className="headling-wrapper white-headline">
            <h2 className="common-heading">Press Releases</h2>
            <h4 className="common-subheading">
              Keeping You Informed with Our Winning Stories
            </h4>
          </div>
          <div className="row">
            {pressData &&
              pressData.length > 0 &&
              pressData.map((val, key) => {
                return (
                  <div className="col-md-4">
                    <div
                      className="press-card-item"
                      onClick={() => handleShow(val)}
                    >
                      <div className="press-card-img-wrapper">
                        <img src={val.Banner} alt="press" />
                      </div>
                      <div className="press-card-content-wrapper">
                        <p>{val.Date}</p>
                        <h3>{val.Title}</h3>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      {/* modal started here */}
      {show && (
        <Modal
          className="amenities-modal-container press-modal-container"
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img src={pressDetails.Banner} alt="banner" />
            <h2>{pressDetails.Title}</h2>
            <p>{pressDetails.Description}</p>
            {/* <h1>AKASH</h1> */}
          </Modal.Body>
        </Modal>
      )}
      {/* modal ended here */}
    </>
  );
}

export default PressCard;
