import React, { useEffect, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import AdminLayout from "../../../components/AdminLayout";
import defaultUser from "../../../assets/images/default/default-user.png";
import { FaTrash } from "react-icons/fa";
import { DataStore } from "@aws-amplify/datastore";
import { uploadData } from "@aws-amplify/storage";
import { Event } from "../../../models";
import ConfirmationBox from "../../../components/ConfirmationBox";
import { awsmobile } from "../../../aws-exports";

function AddImage() {
  const [Show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [SelectedDataForRemove, setSelectedDataForRemove] = useState({});
  const [Photofile, setPhotofile] = useState();
  const [Commonfileurl, setCommonfileurl] = useState();
  const [Editdataflag, setEditdataflag] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const awsBucket = awsmobile.aws_user_files_s3_bucket;
  const [formValues, setFormValues] = useState({
    Photos: "",
    id: "",
  });
  const inputFile = useRef(null);

  const [photoData, setPhotoData] = useState();
  // Add new folder started
  const [folderName, setFolderName] = useState();
  const handleOnChangeFolder = (e) => {
    setFolderName(e.target.value);
  };

  const handleSubmitFormFolder = async (e) => {
    console.log("Inside Add");
    e.preventDefault();
    let createobj = {
      Name: folderName,
    };
    await DataStore.save(new Event(createobj));
    setFolderName("");
  };
  // Add new folder ended

  // Add Images started here
  const validateFileType = async (e, Type) => {
    console.log("eventvalue", e);
    var file = e.target.files[0];
    const fileurl = URL.createObjectURL(file);
    setCommonfileurl(fileurl);
    console.log("fileurl", file, fileurl);
    var fileType = file.type;
    if (
      fileType === "image/jpg" ||
      fileType === "image/jpeg" ||
      fileType === "image/png"
    ) {
      if (Type === "PhotoURL") {
        setPhotofile(file);
      }
    } else {
      alert("Only jpg/jpeg and png files are allowed!");
    }
  };

  const uploadLogoFileToStorage = async (folderName, file) => {
    console.log("filetype", file.type, file.size);
    const contentType = file.type;
    let fileName = file.name.replaceAll(" ", "_");
    console.log("hellocontent", file.name);
    console.log("contentType", contentType);
    try {
      const uploadTask = await uploadData({
        key: `${folderName}/${fileName}`,
        data: file,
        options: {
          contentType: contentType,
        },
      }).result;

      console.log("Succeeded: ", uploadTask);
      let Response = `https://${awsBucket}.s3.ap-south-1.amazonaws.com/public/${folderName}/${fileName}`;

      return Response;
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const handleSelectChange = (event) => {
    console.log("event", event);
    const selectedValue = event.target.value;
    console.log("selectedValue", selectedValue);
    setSelectedValue(selectedValue);
  };

  const handleSubmitForm = async (e) => {
    if (Editdataflag === false) {
      e.preventDefault();
      if (!selectedValue) {
        alert("Please fill in all required fields.");
        return;
      }
      try {
        console.log("formValues", formValues);
        let UserPhoto = "";
        if (Photofile && Commonfileurl) {
          UserPhoto = await uploadLogoFileToStorage(
            "images",
            Photofile,
            Commonfileurl
          );
        }

        console.log("Images Data  on submit", UserPhoto);

        const Imagesobj = await DataStore.query(Event, selectedValue);
        console.log("careers Obj", Imagesobj);
        if (Imagesobj) {
          let arrvalue = [];
          arrvalue = Imagesobj.Photos !== null ? [...Imagesobj.Photos] : [];

          arrvalue.push(UserPhoto);
          console.log("arrvalue", UserPhoto, arrvalue);

          const CareerUpdateObj = Event.copyOf(Imagesobj, (updated) => {
            updated.Photos = arrvalue;
            return updated;
          });
          console.log("Updated Obj", CareerUpdateObj);
          const ResultImages = await DataStore.save(CareerUpdateObj);
        }
        inputFile.current.value = "";
        setMessage("New Image added in Gallery");
        setShow(true);
        setFormValues({
          Photos: "",
          id: "",
        });
      } catch (error) {
        console.error("Error saving Images data:", error);
      }
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setFormValues({
      Photos: "",
      id: "",
    });
    inputFile.current.value = "";
    setPhotofile(null);
    setCommonfileurl(null);
    setEditdataflag(false);
    setSelectedValue("");
  };
  // Add Images ended here

  // confirm box started
  const handleConfirm = async () => {
    setMessage("");
    console.log("confirm", SelectedDataForRemove);
    setShow(false);
    // handleRemove(SelectedDataForRemove, selectedIndex);
  };

  const handleDecline = async () => {
    setMessage("");
    setShow(false);
  };

  const handleOk = async () => {
    setMessage("");
    setShow(false);
  };
  // confirm box ended

  useEffect(() => {
    const ImagesSubscription = DataStore.observeQuery(Event).subscribe(
      async (snapshots) => {
        const Images = snapshots.items;
        setPhotoData(Images);
        console.log("Images", Images);
      }
    );
  }, []);

  const handleRemove = async (data, index) => {
    console.log("handleRemove", data, index);
    let Data = await DataStore.query(Event, data.id);
    let imgdata = [...data.Photos];
    imgdata.splice(index, 1);

    const CareerUpdateObj = Event.copyOf(Data, (updated) => {
      updated.Photos = imgdata;
      return updated;
    });
    console.log("Updated Obj", CareerUpdateObj);
    const ResultImages = await DataStore.save(CareerUpdateObj);
  };

  return (
    <AdminLayout>
      <div className="admin-content-container">
        <div className="admin-left-side">
          <h1 className="common-heading">Add Gallery</h1>
          <div className="white-box small-white-box">
            <form onSubmit={handleSubmitFormFolder}>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Folder Name :
                </label>
                <input
                  class="form-control"
                  type="text"
                  id="example"
                  value={folderName}
                  onChange={handleOnChangeFolder}
                />
              </div>
              <div class="form-group admin-btn-container">
                <button className="bg-success">Add</button>
                <button className="bg-danger">Cancel</button>
              </div>
            </form>
          </div>
          <h1 className="common-heading">
            Add Images{" "}
            <small className="text-danger">
              (first image will be cover photo )
            </small>
          </h1>
          <div className="white-box">
            <form onSubmit={handleSubmitForm}>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Select Folder :
                </label>
                <select
                  class="form-select"
                  aria-label=""
                  value={selectedValue}
                  onChange={(e) => handleSelectChange(e)}
                >
                  <option selected>Choose Folder</option>
                  {photoData &&
                    photoData.length > 0 &&
                    photoData.map((data, index) => (
                      <option value={data.id}>{data.Name}</option>
                    ))}
                </select>
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Upload Image :{" "}
                </label>
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  name="PhotoURL"
                  ref={inputFile}
                  onChange={(e) => {
                    validateFileType(e, "PhotoURL");
                  }}
                />
              </div>
              <div class="form-group admin-btn-container">
                <button
                  className="bg-success"
                  onClick={(e) => {
                    handleSubmitForm(e);
                  }}
                >
                  Add
                </button>

                <button
                  className="bg-danger"
                  onClick={(e) => {
                    handleCancel(e);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="admin-right-side">
          <h1 className="common-heading">All Photos</h1>
          <div className="white-box">
            <Accordion defaultActiveKey="0">
              {photoData &&
                photoData.length > 0 &&
                photoData.map((data, teamIndex) => (
                  <Accordion.Item eventKey={teamIndex} key={teamIndex}>
                    <Accordion.Header>{data.Name}</Accordion.Header>
                    <Accordion.Body>
                      <div className="tech-team-container">
                        {data.Photos &&
                          data.Photos.length > 0 &&
                          data.Photos.map((photo, key) => (
                            <div className="tech-team-item" key={key}>
                              <div
                                className="team-item-wrapper"
                                data-aos="fade-up"
                                data-aos-once="true"
                                data-aos-easing="ease-in-out"
                                style={{ background: "#6766662b" }}
                              >
                                <div className="team-img-wrapper">
                                  <img
                                    src={photo}
                                    onError={(e) =>
                                      (e.target.src = defaultUser)
                                    }
                                    alt="member"
                                    style={{ objectFit: "contain" }}
                                  />
                                </div>

                                <div className="admin-match-card">
                                  <ul className="table-action-btn">
                                    <li>
                                      <FaTrash
                                        className="text-danger"
                                        onClick={() => {
                                          handleRemove(data, key);
                                        }}
                                      />
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
        </div>
      </div>

      {Show ? (
        <ConfirmationBox
          show={Show}
          message={message}
          handleConfirm={
            message == "New Image added in Gallery" ||
            message == "Records updated successfully!"
              ? undefined
              : handleConfirm
          }
          handleDecline={
            message == "New Image added in Gallery" ||
            message == "Records updated successfully!"
              ? undefined
              : handleDecline
          }
          handleOK={
            message == "New Image added in Gallery" ||
            message == "Records updated successfully!"
              ? handleOk
              : undefined
          }
        />
      ) : null}
    </AdminLayout>
  );
}

export default AddImage;
