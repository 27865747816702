import React, { useEffect, useState } from "react";
import AdminLayout from "../../../components/AdminLayout";
import { FaTrash } from "react-icons/fa";
import { BsPencilFill } from "react-icons/bs";
import { DataStore } from "@aws-amplify/datastore";
import { ScrollingText } from "../../../models";
import ConfirmationBox from "../../../components/ConfirmationBox";

function TopSlider() {
  const [Show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [SelectedDataForRemove, setSelectedDataForRemove] = useState({});
  const [Editdataflag, setEditdataflag] = useState(false);
  const [Textmessage, setTextmessage] = useState("");
  const [Editdata, setEditdata] = useState({});
  const [AllMessages, setAllMessages] = useState([]);

  const handleOnChange = (e) => {
    setTextmessage(e.target.value);
    const newObject = {
      ...Editdata,
      Text: e.target.value,
    };

    setEditdata(newObject);
  };

  const handleSubmitForm = async (e) => {
    console.log("Inside Add");
    e.preventDefault();
    let createobj = {
      Text: Textmessage,
    };
    await DataStore.save(new ScrollingText(createobj));
    setMessage("New Scrolling Text added");
    setShow(true);
    handleGetData();
    setTextmessage("");
  };

  const handleGetData = async () => {
    let data = await DataStore.query(ScrollingText);
    setAllMessages(data);
    console.log("get scrolling text", data);
  };

  const handleRemove = async (data) => {
    try {
      await DataStore.delete(ScrollingText, data.id);
      handleGetData();
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };

  const handleDelete = (data) => {
    console.log("handleDelete", data);
    setSelectedDataForRemove(data);
    setMessage("Do you really want to delete these records?");
    setShow(true);
  };

  const handleEdit = async (data) => {
    setEditdataflag(true);
    setTextmessage(data.Text);
    setEditdata(data);
  };

  const handleEditsubmit = async (e) => {
    console.log("Edited data1", Editdata);
    e.preventDefault();
    try {
      const existingRecord = await DataStore.query(ScrollingText, Editdata.id);
      console.log("existingRecord", existingRecord);
      if (existingRecord) {
        const updatedRecord = await DataStore.save(
          ScrollingText.copyOf(existingRecord, (updated) => {
            updated.Text = Textmessage;
          })
        );
        setMessage("Records updated successfully!");
        setShow(true);
        if (updatedRecord) {
          setEditdataflag(false);
          setTextmessage("");
          setEditdata({});
          handleGetData();
        }
      }
    } catch (error) {
      console.error("Error updating record:", error);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setTextmessage("");
    setEditdataflag(false);
  };

  // confirm box started
  const handleConfirm = async () => {
    setMessage("");
    console.log("confirm", SelectedDataForRemove);
    setShow(false);
    handleRemove(SelectedDataForRemove);
  };

  const handleDecline = async () => {
    setMessage("");
    setShow(false);
  };

  const handleOk = async () => {
    setMessage("");
    setShow(false);
  };
  // confirm box ended

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-content-container">
        <div className="admin-left-side">
          <h1 className="common-heading">Add Top Slider </h1>
          <div className="white-box">
            <form action="" onSubmit={handleSubmitForm}>
              <div className="form-group mb-3">
                <label className="form-label" htmlFor="example">
                  Message:
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Message"
                  type="text"
                  id="example"
                  value={Textmessage}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group admin-btn-container">
                {Editdataflag == false ? (
                  <button
                    className="bg-success"
                    onClick={() => {
                      handleSubmitForm();
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    className="bg-success"
                    onClick={() => {
                      handleEditsubmit();
                    }}
                  >
                    Update
                  </button>
                )}
                <button
                  className="bg-danger"
                  onClick={(e) => {
                    handleCancel(e);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="admin-right-side">
          <h1 className="common-heading">All Top Sliders</h1>
          <div className="white-box">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th width="10%" className="text-center">
                    SN
                  </th>
                  <th width="80%">Message</th>
                  <th width="10%">Action</th>
                </tr>
              </thead>
              <tbody>
                {AllMessages &&
                  AllMessages.length > 0 &&
                  AllMessages.map((data, index) => (
                    <tr key={data.id}>
                      <td className="text-center">{index + 1}</td>
                      <td>
                        <h3 className="dataH4">{data.Text}</h3>
                      </td>
                      <td>
                        <ul className="table-action-btn">
                          <li>
                            <BsPencilFill
                              className="text-success"
                              onClick={() => {
                                handleEdit(data);
                              }}
                            />
                          </li>
                          <li>
                            <FaTrash
                              className="text-danger"
                              onClick={() => handleDelete(data)}
                            />
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {Show ? (
        <ConfirmationBox
          show={Show}
          message={message}
          handleConfirm={
            message == "New Scrolling Text added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleConfirm
          }
          handleDecline={
            message == "New Scrolling Text added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleDecline
          }
          handleOK={
            message == "New Scrolling Text added" ||
            message == "Records updated successfully!"
              ? handleOk
              : undefined
          }
        />
      ) : null}
    </AdminLayout>
  );
}

export default TopSlider;
