import React from "react";
import "./Loader.css";
import gifBG from "../../../assets/images/loader/gif-one.gif";
import gifBG2 from "../../../assets/images/loader/gif-two.gif";
import gifBG3 from "../../../assets/images/loader/gif-four.gif";
import gifOne from "../../../assets/images/loader/gif-three.gif";
import gifSix from "../../../assets/images/loader/gif-six.gif";

function Loader() {
  return (
    <div className="loader-main-wrapper">
      <img src={gifBG3} alt="" className="gifBG2" />
      <img
        src={gifOne}
        alt=""
        className="gifOne"
        style={{ position: "absolute", left: 100 }}
      />
      <img
        src={gifOne}
        alt=""
        className="gifOne"
        style={{ position: "absolute", right: 100 }}
      />

      {/* <img src={gifSix} alt="" className="gifOne" /> */}
      {/* <div className="loader"></div> */}
    </div>
  );
}

export default Loader;
