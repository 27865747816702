import React, { useEffect, useState } from "react";
import Thumbnail from "../../../assets/images/gallery/images/football-thumbnail.jpg";
import { useNavigate, Link } from "react-router-dom";
import { DataStore } from "@aws-amplify/datastore";
import { Event } from "../../../models";
// import { useHistory } from "react-router-dom";

function ImageGrid() {
  const [imageData, setImageData] = useState();
  const [getID, setGetID] = useState("");
  const navigate = useNavigate();

  const handleRedirect = (id) => {
    console.log(`Clicked on object with ID: ${id}`);
    setGetID(id);
  };

  const handleRedirectgallery = (val) => {
    console.log("redirect", val);
    navigate("/gallery/photos", { state: { yourParameter: val } });
  };

  useEffect(() => {
    const ImagesSubscription = DataStore.observeQuery(Event).subscribe(
      // (Event,(c)=>c.id.eq()).subscribe(
      async (snapshot) => {
        const Images = snapshot.items;
        console.log("Images", Images);
        setImageData(Images);
      }
    );
    return () => {
      ImagesSubscription.unsubscribe();
    };
  }, []);

  return (
    <>
      <section className="image-grid-section">
        <div className="row">
          {imageData &&
            imageData.length > 0 &&
            imageData.map((val, key) =>
              val.Photos && val.Photos.length > 0 ? (
                <div className="col-md-4" key={key}>
                  <div
                    // to="/gallery/photos"
                    onClick={() => handleRedirectgallery(val)}
                  >
                    <div className="image-grid-item">
                      <img src={val.Photos[0]} alt="thumbnail" />
                      <div className="gallery_card_hover">
                        <h3>{val.Name}</h3>
                        <button>View Gallery</button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null
            )}
        </div>
      </section>
    </>
  );
}

export default ImageGrid;
