import React, { useEffect, useState } from "react";
// import VisionLeft from "../../../assets/images/home/home-vision.jpg";
import { TbTarget } from "react-icons/tb";
import { TbEye } from "react-icons/tb";
import { DataStore } from "@aws-amplify/datastore";
import { AboutUs } from ".././../../models";

function VisionMission() {
  const VisionLeft =
    "https://snigmaypunefc-storage-63a42a0a100429-dev.s3.ap-south-1.amazonaws.com/public/HomeTeam/IMG_2811-min.JPG";
  const [aboutData, setAboutData] = useState();
  useEffect(() => {
    const subscription = DataStore.observeQuery(AboutUs).subscribe(
      async (snapshot) => {
        const about = snapshot.items;
        console.log("about", about);
        setAboutData(about[0]);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  return (
    <>
      <section className="home-vision-section sec-pad">
        <div className="container">
          <div
            className="vision-img-wrapper"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <img src={VisionLeft} alt="vision mission" />
          </div>
          <div className="vision-content-wrapper">
            <h2
              className="common-heading"
              data-aos="zoom-in"
              data-aos-once="true"
            >
              WHO WE ARE
            </h2>
            <h4
              className="common-subheading"
              data-aos="zoom-in"
              data-aos-once="true"
            >
              {/* Lorem ipsum dolor sit amet, adipiscing elit consectetuer. */}
              High on Pune's pride, Snigmay Pune FC strides! Uniting hearts,
              conquering tides! <br />{" "}
              <span style={{ color: "#660000" }}>"ONE TEAM ONE DREAM"</span>
            </h4>
            {aboutData && (
              <div className="home-vision-mission-container">
                <div
                  className="home-vision-wrapper"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-easing="ease-in-out"
                  data-aos-delay="50"
                >
                  <span>
                    <TbEye />
                  </span>
                  <h3>Our Vision</h3>
                  <p>{aboutData.Vision}</p>
                </div>
                <div
                  className="home-vision-wrapper"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-easing="ease-in-out"
                  data-aos-delay="300"
                >
                  <span>
                    <TbTarget />
                  </span>
                  <h3>Our Mission</h3>
                  <p>{aboutData.Mission}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
export default VisionMission;
