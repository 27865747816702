import React from "react";
import Layout from "../../components/Layout";
import Banner from "../../components/Banner";
import BannerImage from "../../assets/images/banner/banner-1.jpg";
import Academy from "./Home/Academy";
import AcademyTeam from "./Academy/AcademyTeam";
import Achievement from "./Academy/Achievement";
import Testimonials from "./Home/Testimonials";
import BirthdayCorner from "./Academy/BirthdayCorner";
import POTMPage from "./Academy/POTMPage";
import COTMPage from "./Academy/COTMPage";
import Score from "./Academy/Score";
import Fixture from "./Academy/Fixture";
import { Helmet } from "react-helmet";

function AcademyMain() {
  const hideButton = true;
  return (
    <Layout>
      <Helmet>
        <title>Snigmay Pune FC is the Best Football Club Academy in Pune</title>
        <meta
          name="description"
          content="Snigmay Best Football Club Academy in Pune offers full-sized pitches natural & artificial, for elite training and expert coaching."
        />
        <meta name="keywords" content="Best Football Club Academy in Pune" />
      </Helmet>
      <Banner BannerImage={BannerImage} heading="Academy" url="/academy" />
      <Academy hideButton={hideButton} />
      {/* <AcademyTeam /> */}
      <Achievement />
      {/* <POTMPage /> */}
      {/* <COTMPage /> */}
      {/* <Testimonials /> */}
      {/* <BirthdayCorner /> */}
      {/* <Score /> */}
      {/* <Fixture /> */}
    </Layout>
  );
}
export default AcademyMain;
