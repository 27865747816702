import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo/logo.png";
import { useLocation } from "react-router-dom";
import PlayerIcon from "../assets/images/icons/football-player.png";
import { RxHamburgerMenu } from "react-icons/rx";
import ScrollingTextComp from "./ScrollingTextComp";

function Navbar() {
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const handleClickActive = () => {
    setIsActive(!isActive);
  };
  const handleClickActive2 = () => {
    setIsActive2(!isActive2);
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsSticky(scrollTop > 400);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={
          isSticky ? "navbar-container sticky-navbar" : "navbar-container"
        }
      >
        <ScrollingTextComp />
        <div className="container">
          <div className="navbar-wrapper">
            <div className="logo-container">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="social-nav-container">
              <button className="hamburger-btn" onClick={handleClickActive}>
                <RxHamburgerMenu />
              </button>
              <ul className={isActive ? "active" : ""}>
                <li>
                  <Link
                    to="/"
                    className={location.pathname === "/" ? "active" : ""}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  {" "}
                  <span
                    className={
                      location.pathname === "/about" ||
                      location.pathname === "/academy" ||
                      location.pathname === "/club" ||
                      location.pathname === "/about/team"
                        ? "active"
                        : ""
                    }
                    onClick={handleClickActive2}
                  >
                    About
                  </span>
                  <ul className={isActive2 ? "activeUL" : ""}>
                    <li>
                      <Link
                        to="/about"
                        className={
                          location.pathname === "/about" ? "active" : ""
                        }
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/academy"
                        className={
                          location.pathname === "/academy" ? "active" : ""
                        }
                      >
                        Academy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/club"
                        className={
                          location.pathname === "/club" ? "active" : ""
                        }
                      >
                        Club
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/teams/under-13"
                        className={
                          location.pathname === "/club" ? "active" : ""
                        }
                      >
                        Under-13 Team
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="/about/team"
                        className={
                          location.pathname === "/about/team" ? "active" : ""
                        }
                      >
                        Team Behind The Team
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    to="/teams"
                    className={location.pathname === "/teams" ? "active" : ""}
                  >
                    Teams
                  </Link>
                </li>
                <li>
                  <Link
                    to="/our-amenities"
                    className={
                      location.pathname === "/our-amenities" ? "active" : ""
                    }
                  >
                    Amenities
                  </Link>
                </li>
                <li>
                  <Link
                    to="/gallery"
                    className={location.pathname === "/gallery" ? "active" : ""}
                  >
                    Gallery
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/csr"
                    className={location.pathname === "/csr" ? "active" : ""}
                  >
                    CSR
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="/press"
                    className={location.pathname === "/press" ? "active" : ""}
                  >
                    Press
                  </Link>
                </li>
                <li>
                  <Link
                    to="/careers"
                    className={location.pathname === "/careers" ? "active" : ""}
                  >
                    Careers
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className={location.pathname === "/contact" ? "active" : ""}
                  >
                    Contact
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/home/banner"
                    className={
                      location.pathname === "/admin/home/banner"
                        ? " active"
                        : ""
                    }
                  >
                    Admin
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/wall-of-fame"
                    className={
                      location.pathname === "/wall-of-fame" ? " active" : ""
                    }
                  >
                    Wall of Fame
                  </Link>
                </li> */}
                <li className="apply-for-trial-li">
                  <Link to="/trials" className="">
                    <button>
                      <img src={PlayerIcon} alt="" /> Apply for Trials
                    </button>
                  </Link>
                </li>
                <li className="apply-for-trial-li-mob">
                  <Link to="/trials" className="">
                    {" "}
                    Apply for Trials
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
