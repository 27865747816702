import React from 'react';
import { TfiWorld } from "react-icons/tfi";
import { TbTrees } from "react-icons/tb";

function CSRIntro() {
    return (
        <section className='csr-intro-section sec-pad'>
            <div className="container">
                <div className="headling-wrapper white-headline">
                    <h2 className="common-heading">Overview</h2>
                    <h4 className="common-subheading">
                        LOREM IPSUM DOLOR SIT
                    </h4>
                </div>
                <div className="row">
                    <div className="col-md-12 mb-2">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                    </div>
                    <div className="col-md-3">
                        <div className="csr-intro-item">
                            <div className="icon-wrapper">
                                <TbTrees />
                            </div>
                            <div className="content-wrapper">
                                <h2>Clean Surrounding</h2>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque architecto beatae vitae dicta sunt explicabo.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="csr-intro-item">
                            <div className="icon-wrapper">
                                <TfiWorld />
                            </div>
                            <div className="content-wrapper">
                                <h2>Clean Surrounding</h2>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque architecto beatae vitae dicta sunt explicabo.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="csr-intro-item">
                            <div className="icon-wrapper">
                                <TfiWorld />
                            </div>
                            <div className="content-wrapper">
                                <h2>Clean Surrounding</h2>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque architecto beatae vitae dicta sunt explicabo.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="csr-intro-item">
                            <div className="icon-wrapper">
                                <TfiWorld />
                            </div>
                            <div className="content-wrapper">
                                <h2>Clean Surrounding</h2>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque architecto beatae vitae dicta sunt explicabo.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CSRIntro;
