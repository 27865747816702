import React from 'react';
import Form from "react-bootstrap/Form";

function CareersForm() {
    return (
        <>
            <Form className="" >
                <div className="row">
                    <div className="col-md-12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control type="text" placeholder="Name" />
                        </Form.Group>
                    </div>
                    <div className="col-md-12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control type="text" placeholder="Email" />
                        </Form.Group>
                    </div>
                    <div className="col-md-12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control type="text" placeholder="Phone" />
                        </Form.Group>
                    </div>
                    <div className="col-md-12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control type="file" />
                        </Form.Group>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                        <button className="home-btn-animation" type="submit"> Submit </button>
                    </div>
                </div>
            </Form>
        </>
    )
}

export default CareersForm;
