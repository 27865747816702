import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// import "swiper/css/pagination"; **
import "swiper/css/navigation";
import "../../../assets/css/swiper.css";
// import { Navigation } from "swiper";
import { Autoplay, Pagination, Navigation } from "swiper";
import slider1 from "../../../assets/images/banner/slider-1.jpg";
import slider2 from "../../../assets/images/banner/slider-2.jpg";
import slider3 from "../../../assets/images/banner/slider-3.jpg";

import { DataStore } from "@aws-amplify/datastore";
import { AboutUs } from "../../../models";

function HeaderSwiper() {
  const [swiperData, setSwiperData] = useState();

  useEffect(() => {
    const subscription = DataStore.observeQuery(AboutUs).subscribe(
      async (snapshot) => {
        const swiper = snapshot.items;
        console.log("swiper", swiper[0].SwiperImage);
        setSwiperData(swiper[0].SwiperImage);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // const headerSwiperData = [
  //   {
  //     title: "WE ARE SNIGMAY FC",
  //     subtitle: "TRADITION  PRIDE & FUTURE",
  //     banner: slider1,
  //   },
  //   {
  //     title: "WE ARE SNIGMAY FC",
  //     subtitle: "Unity in Motion, Victory in Passion.",
  //     banner: slider2,
  //   },
  //   {
  //     title: "WE ARE SNIGMAY FC",
  //     subtitle: "Beyond Boundaries, We Play as One.",
  //     banner: slider3,
  //   },
  // ];
  return (
    <>
      <div className="cus-swiper-wrapper">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          navigation={true}
          modules={[Autoplay, Navigation]}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          className="mySwiper"
        >
          {swiperData &&
            swiperData.length > 0 &&
            swiperData.map((data) => (
              <SwiperSlide>
                <div className="banner-wrapper">
                  <img src={data} alt="banner" />
                  <div className="banner-content-overlay">
                    <div className="container">
                      <div className="banner-content-wrapper">
                        <h4>We are Snigmay FC</h4>
                        <h1>TRADITION PRIDE & FUTURE</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </>
  );
}
export default HeaderSwiper;
