import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../../../assets/css/swiper.css";
import { Autoplay, Pagination, Navigation } from "swiper";
import BirthdayDecoration from "../../../assets/images/birthdayCorner/birthday-decoration.png";
import { DataStore } from "@aws-amplify/datastore";
import { Birthday } from "../../../models";
function BirthdayCorner() {
  const [birthdayData, setBirthdayData] = useState([]);

  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    });
    return formattedDate;
  };

  useEffect(() => {
    //   const subscription = DataStore.observeQuery(Birthday).subscribe(
    //     async (snapshot) => {
    //       const birthdays = snapshot.items;
    //       console.log("Testimonial", Birthday);
    //       const today = new Date();
    //       const offset = today.getTimezoneOffset();
    //       const tempDate = new Date(today.getTime() - offset * 60 * 1000);
    //       const formattedDate = tempDate.toISOString().split("T")[0];
    //       console.log("today", formattedDate);
    //       const todayBirthDays = birthdays.filter(
    //         (birthday) => birthday.Date === formattedDate
    //       );
    //       console.log("birthdays", todayBirthDays[0]);
    //       console.log(
    //         "data show",
    //         todayBirthDays[0].BirthdayList ? todayBirthDays[0].BirthdayList : null
    //       );
    //       setBirthdayData(
    //         todayBirthDays[0].BirthdayList ? todayBirthDays[0].BirthdayList : null
    //       );
    //       console.log(
    //         "length of array",
    //         todayBirthDays[0].BirthdayList &&
    //           todayBirthDays[0].BirthdayList.length > 2
    //           ? 3
    //           : todayBirthDays[0].BirthdayList.length
    //       );
    //       const slidesToShow =
    //         todayBirthDays[0].BirthdayList &&
    //         todayBirthDays[0].BirthdayList.length > 2
    //           ? 3
    //           : todayBirthDays[0].BirthdayList.length;
    //       setSlideLength(slidesToShow);
    //     }
    //   );

    const subscription = DataStore.observeQuery(Birthday).subscribe(
      async (snapshot) => {
        const birthdays = snapshot.items;
        console.log("birthdays", birthdays);
        // setBirthdayData(birthdays);

        const modifiedBirthdays = birthdays.flatMap((item) => {
          return item.BirthdayList.map((birthday) => ({
            Name: birthday.Name,
            Date: item.Date,
            Description: birthday.Description,
            PhotoURL: birthday.PhotoURL,
          }));
        });
        console.log("modifiedBirthdays", modifiedBirthdays);

        const currentMonth = new Date().getMonth() + 1; // Adding 1 because months are zero-based
        const currentDate = new Date().getDate();

        const birthdayData = modifiedBirthdays.filter((entry) => {
          const entryDate = new Date(entry.Date);
          return (
            entryDate.getMonth() + 1 === currentMonth &&
            entryDate.getDate() === currentDate
          );
        });
        setBirthdayData(birthdayData);
        console.log("birthdayData 2", birthdayData);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      {birthdayData && birthdayData.length > 0 && (
        <section className="birthday-section sec-pad">
          <div className="container">
            <div className="headling-wrapper white-headline">
              <h2
                className="common-heading"
                data-aos="zoom-in-up"
                data-aos-once="true"
                data-aos-easing="ease-in-out"
              >
                Birthday Corner
              </h2>
            </div>
            {/* slider started */}
            <Swiper
              slidesPerView={2}
              spaceBetween={30}
              loop={true}
              navigation={false}
              modules={[Autoplay, Pagination, Navigation]}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                300: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                // 1024: {
                //   slidesPerView: slidesLength,
                //   spaceBetween: 30,
                // },
              }}
              className="mySwiper"
            >
              {birthdayData &&
                birthdayData.length > 0 &&
                birthdayData.map((val, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div
                        className="aca-birthday-container"
                        data-aos="flip-right"
                        data-aos-once="true"
                        data-aos-easing="ease-in-out"
                      >
                        <div className="birthday-decoration-wrapper">
                          <img src={BirthdayDecoration} alt="decoration-img" />
                        </div>
                        <div className="birthday-img-wrapper">
                          <div className="img-image-container">
                            <img src={val.PhotoURL} alt="birthday" />
                          </div>
                        </div>
                        <div className="birthday-text-wrapper">
                          <h2>{val.Name}</h2>
                          <h4
                            style={{
                              fontSize: "18px",
                              fontFamily: "var(--theme-font)",
                            }}
                          >
                            {formatDateString(val.Date)}
                          </h4>
                          <h1>Wishing You a Happiest Birthday</h1>
                          <h3>~Snigmay Pune FC Family</h3>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
            {/* slider ended */}
          </div>
        </section>
      )}
    </>
  );
}
export default BirthdayCorner;
