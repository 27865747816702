import React from "react";
import Layout from "../../../components/Layout";
import Banner from "../../../components/Banner";
import BannerImage from "../../../assets/images/banner/banner-1.jpg";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";
import { useLocation } from "react-router-dom";

function ImageGallery() {
  const location = useLocation();
  const { yourParameter } = location.state;
  console.log("yourParameter", yourParameter);

  return (
    <>
      <Layout>
        <Banner BannerImage={BannerImage} heading="Gallery" url="/gallery" />
        <section className="image-gallery-section sec-pad">
          <div className="container">
            <div className="headling-wrapper white-headline">
              <h2 className="common-heading">{yourParameter.Name}</h2>
            </div>
            <SlideshowLightbox className="image-gallery-container">
              {yourParameter.Photos &&
                yourParameter.Photos.length > 0 &&
                yourParameter.Photos.map((data, index) => (
                  <img
                    //   key={index}
                    className="w-full rounded"
                    alt="gallery"
                    src={data}
                  />
                ))}
            </SlideshowLightbox>
          </div>
        </section>
      </Layout>
    </>
  );
}

export default ImageGallery;
