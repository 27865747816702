import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import Banner from "../../components/Banner";
import BannerImage from "../../assets/images/banner/banner-1.jpg";
import TrialImage from "../../assets/images/team/trial.png";
import TrialForm from "./Trial/TrialForm";
import { DataStore } from "@aws-amplify/datastore";
import { Trials } from "../../models";

function Trial() {
  const [trialsData, setTrialsData] = useState();
  useEffect(() => {
    const subscription = DataStore.observeQuery(Trials).subscribe(
      async (snapshot) => {
        const trials = snapshot.items;
        console.log("trials", trials);
        setTrialsData(trials);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  return (
    <>
      <Layout>
        <Banner BannerImage={BannerImage} heading="Trial" url="/trials" />
        <section className="academy-trial-section sec-pad">
          <div className="headling-wrapper white-headline">
            <h2
              className="common-heading"
              data-aos="zoom-in"
              data-aos-once="true"
              data-aos-easing="ease-in-out"
            >
              Trial
            </h2>
            <h4
              className="common-subheading"
              data-aos="zoom-in"
              data-aos-once="true"
              data-aos-easing="ease-in-out"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </h4>
          </div>
          <div className="container">
            {trialsData &&
              trialsData.length > 0 &&
              trialsData.map((val, key) => {
                return (
                  <div className="academy-trial-container mb-3">
                    <div className="trial-content-wrapper">
                      <h3 className="trials-design">Trials</h3>
                      <div className="trial-content-card">
                        <h3
                          data-aos="fade-up-right"
                          data-aos-delay="300"
                          data-aos-once="true"
                          data-aos-easing="ease-in-out"
                        >
                          <span>Date :</span> {val.FromDate},{val.FromTime} -{" "}
                          {val.ToDate},{val.ToTime}
                        </h3>
                        <h3
                          data-aos="fade-up-right"
                          data-aos-delay="600"
                          data-aos-once="true"
                          data-aos-easing="ease-in-out"
                        >
                          <span>Age Group:</span> {val.AgeGroup}
                        </h3>
                        <h3
                          data-aos="fade-up-right"
                          data-aos-delay="900"
                          data-aos-once="true"
                          data-aos-easing="ease-in-out"
                        >
                          <span>Venue :</span> {val.Venue}
                        </h3>
                      </div>
                      <div
                        className="trial-form-container"
                        data-aos="fade-up-right"
                        data-aos-delay="1200"
                        data-aos-once="true"
                        data-aos-easing="ease-in-out"
                      >
                        <h3>Register Here</h3>
                        <TrialForm />
                      </div>
                    </div>
                    <div className="trial-img-wrapper">
                      <img
                        src={TrialImage}
                        alt="trial"
                        data-aos="fade-up-left"
                        data-aos-delay="1500"
                        data-aos-once="true"
                        data-aos-easing="ease-in-out"
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </section>
      </Layout>
    </>
  );
}
export default Trial;
