import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "../../../components/AdminLayout";
import DefaultBanner from "../../../assets/images/default/default-banner.png";
import { FaTrash } from "react-icons/fa";
import { BsPencilFill } from "react-icons/bs";
import TrophyImage from "../../../assets/images/trophy/trophy5.png";
import { DataStore } from "@aws-amplify/datastore";
import { Achievements } from "../../../models";
import { uploadData } from "@aws-amplify/storage";
import { createAchievement } from "../../../api/AchievementsAPI";
import ConfirmationBox from "../../../components/ConfirmationBox";
import { awsmobile } from "../../../aws-exports";

function AddAchievement() {
  const [Show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [SelectedDataForRemove, setSelectedDataForRemove] = useState({});
  const [trophyData, setTrophyData] = useState();
  const [TrophyPhotofile, setTrophyPhotofile] = useState();
  const [Commonfileurl, setCommonfileurl] = useState();
  const [Editdataflag, setEditdataflag] = useState(false);
  const [EditObj, setEditObj] = useState({});
  const awsBucket = awsmobile.aws_user_files_s3_bucket;
  const [formValues, setFormValues] = useState({
    Title: "",
    Position: "",
    Period: "",
    TrophyURL: "",
  });
  const inputFile = useRef(null);
  // for validate image file type (started here)
  const validateFileType = async (e, Type) => {
    console.log("eventvalue", e);
    var file = e.target.files[0];
    const fileurl = URL.createObjectURL(file);
    setCommonfileurl(fileurl);
    console.log("fileurl", file, fileurl);
    var fileType = file.type;
    if (
      fileType === "image/jpg" ||
      fileType === "image/jpeg" ||
      fileType === "image/png"
    ) {
      if (Type === "TrophyURL") {
        setTrophyPhotofile(file);
      }
    } else {
      alert("Only jpg/jpeg and png files are allowed!");
    }
  };
  // for validate image file type (ended here)
  const uploadPhotoFileToStorage = async (folderName, file) => {
    console.log("filetype", file.type, file.size);
    const contentType = file.type;
    let fileName = file.name.replaceAll(" ", "_");
    console.log("hellocontent", file.name);
    console.log("contentType", contentType);
    try {
      const uploadTask = await uploadData({
        key: `${folderName}/${fileName}`,
        data: file,
        options: {
          contentType: contentType,
        },
      }).result;

      console.log("Succeeded: ", uploadTask);
      let Response = `https://${awsBucket}.s3.ap-south-1.amazonaws.com/public/${folderName}/${fileName}`;

      return Response;
    } catch (error) {
      console.log("Error : ", error);
    }
  };
  //
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (Editdataflag === false) {
      if (!formValues.Title || !formValues.Position || !formValues.Period) {
        alert("Please fill in all required fields.");
        return;
      }
      try {
        console.log("formValues", formValues);
        let CreateObj = { ...formValues };
        console.log("CreateObj", CreateObj);
        let TrophyPhoto = "";
        if (TrophyPhotofile && Commonfileurl) {
          TrophyPhoto = await uploadPhotoFileToStorage(
            "Achievements",
            TrophyPhotofile,
            Commonfileurl
          );
        }

        CreateObj.Title = formValues.Title;
        CreateObj.Position = formValues.Position;
        CreateObj.Period = formValues.Period;
        CreateObj.TrophyURL = TrophyPhoto;

        console.log("Achievements Data  on submit", CreateObj, TrophyPhoto);

        const response = await createAchievement(CreateObj);
        console.log("Achievements data saved successfully:", response);
        setMessage("New Achievement added");
        setShow(true);
        inputFile.current.value = "";
        setFormValues({
          Title: "",
          Position: "",
          Period: "",
          TrophyURL: "",
        });
        getAchievementData();
      } catch (error) {
        console.error("Error saving Achievements data:", error);
      }
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const getAchievementData = () => {
    const subscription = DataStore.observeQuery(Achievements).subscribe(
      async (snapshot) => {
        const achievements = snapshot.items;
        console.log("achievements", achievements);
        setTrophyData(achievements);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const handleRemove = async (data) => {
    console.log("data", data);
    try {
      const response = await DataStore.delete(Achievements, data.id);
      getAchievementData();
    } catch (error) {}
  };

  const handleDelete = (data) => {
    console.log("handleDelete", data);
    setSelectedDataForRemove(data);
    setMessage("Do you really want to delete these records?");
    setShow(true);
  };

  const handleEditsubmit = async () => {
    console.log("formValues", formValues);
    let TrophyPhoto = "";
    if (TrophyPhotofile && Commonfileurl) {
      TrophyPhoto = await uploadPhotoFileToStorage(
        "TrophyPhotofile",
        TrophyPhotofile,
        Commonfileurl
      );
    }

    const AchievementObj = await DataStore.query(Achievements, EditObj.id);
    console.log("AchievementObj", AchievementObj);
    if (AchievementObj) {
      // eslint-disable-next-line no-use-before-define
      const AchievementUpdateObj = Achievements.copyOf(
        AchievementObj,
        (updated) => {
          updated.Title = formValues.Title;
          updated.Position = formValues.Position;
          updated.Period = formValues.Period;
          updated.TrophyURL =
            TrophyPhoto !== "" ? TrophyPhoto : updated.TrophyURL;
          return updated;
        }
      );
      console.log("AchievementObj", AchievementObj);
      const ResultAchievementItem = await DataStore.save(AchievementUpdateObj);
      inputFile.current.value = "";
      setMessage("Records updated successfully!");
      setShow(true);
      if (ResultAchievementItem) {
        setFormValues({
          Title: "",
          Position: "",
          Period: "",
          TrophyURL: "",
        });
        getAchievementData();
      }
    }
  };

  const handleEdit = async (data) => {
    console.log("Edit", data);
    setEditObj(data);
    setEditdataflag(true);
    setFormValues({
      Title: data.Title,
      Position: data.Position,
      Period: data.Period,
      TrophyURL: data.TrophyURL,
    });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setFormValues({
      Title: "",
      Position: "",
      Period: "",
      TrophyURL: "",
    });
    inputFile.current.value = "";
    TrophyPhotofile(null);
    setCommonfileurl(null);
    setEditdataflag(false);
  };

  // confirm box started
  const handleConfirm = async () => {
    setMessage("");
    console.log("confirm", SelectedDataForRemove);
    setShow(false);
    handleRemove(SelectedDataForRemove);
  };

  const handleDecline = async () => {
    setMessage("");
    setShow(false);
  };

  const handleOk = async () => {
    setMessage("");
    setShow(false);
  };
  // confirm box ended

  useEffect(() => {
    getAchievementData();
  }, []);
  return (
    <AdminLayout>
      <div className="admin-content-container">
        <div className="admin-left-side">
          <h1 className="common-heading">Add Achievements</h1>
          <div className="white-box">
            <form action="submit" onSubmit={handleSubmitForm}>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Achievement Title :
                </label>
                <input
                  class="form-control"
                  placeholder="Enter Title"
                  type="text"
                  id="example"
                  name="Title"
                  value={formValues.Title}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Achievement Status :
                </label>
                <input
                  class="form-control"
                  placeholder="Enter Title"
                  type="text"
                  id="example"
                  name="Position"
                  value={formValues.Position}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Achievement Year :
                </label>
                <input
                  class="form-control"
                  placeholder="Enter Title"
                  type="text"
                  id="example"
                  name="Period"
                  value={formValues.Period}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Upload Achievments Trophy :
                  <small className="text-danger">
                    (Size should be 220 X 360 )
                  </small>
                </label>
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  name="TrophyURL"
                  ref={inputFile}
                  onChange={(e) => {
                    validateFileType(e, "TrophyURL");
                  }}
                />
              </div>
              <div class="form-group admin-btn-container">
                {Editdataflag == false ? (
                  <button
                    className="bg-success"
                    onClick={() => {
                      handleSubmitForm();
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    className="bg-success"
                    onClick={() => {
                      handleEditsubmit();
                    }}
                  >
                    Update
                  </button>
                )}
                <button
                  className="bg-danger"
                  onClick={(e) => {
                    handleCancel(e);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="admin-right-side">
          <h1 className="common-heading">All Achievements</h1>
          <div className="white-box">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th width="10%" className="text-center">
                    SN
                  </th>
                  <th width="25%" className="text-center">
                    Trophy
                  </th>
                  <th width="55%">Details</th>
                  <th width="10%">Action</th>
                </tr>
              </thead>
              <tbody>
                {trophyData &&
                  trophyData.length > 0 &&
                  trophyData.map((data, index) => (
                    <tr>
                      <td className="text-center">{index + 1}</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          <img
                            src={data.TrophyURL}
                            style={{
                              width: "122px",
                              maxHeight: "200px",
                              objectFit: "contain",
                            }}
                            onError={(e) => (e.target.src = TrophyImage)}
                            alt="user"
                          />
                        </div>
                      </td>
                      <td>
                        <h3 className="dataH3">{data.Title}</h3>
                        <h4 className="dataH4">{data.Position}</h4>
                        <h4 className="dataH4">{data.Period}</h4>
                      </td>
                      <td>
                        <ul className="table-action-btn">
                          <li>
                            <BsPencilFill
                              className="text-success"
                              onClick={() => {
                                handleEdit(data);
                              }}
                            />
                          </li>

                          <li>
                            <FaTrash
                              className="text-danger"
                              onClick={() => {
                                handleDelete(data);
                              }}
                            />
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {Show ? (
        <ConfirmationBox
          show={Show}
          message={message}
          handleConfirm={
            message == "New Achievement added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleConfirm
          }
          handleDecline={
            message == "New Achievement added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleDecline
          }
          handleOK={
            message == "New Achievement added" ||
            message == "Records updated successfully!"
              ? handleOk
              : undefined
          }
        />
      ) : null}
    </AdminLayout>
  );
}

export default AddAchievement;
