import React from 'react';
import Player1 from "../../../assets/images/WallofFame/player1.jpg";
import Player2 from "../../../assets/images/WallofFame/player2.jpg";
import Player3 from "../../../assets/images/WallofFame/player3.jpg";
import Player4 from "../../../assets/images/WallofFame/player4.jpg";

function FameCard() {
    const FameData = [
        { Name: "Sunil Chhetri", PhotoURL: Player1, },
        { Name: "Sunil Chhetri", PhotoURL: Player2, },
        { Name: "Sunil Chhetri", PhotoURL: Player3, },
        { Name: "Sunil Chhetri", PhotoURL: Player4, },
        { Name: "Sunil Chhetri", PhotoURL: Player1, },
        { Name: "Sunil Chhetri", PhotoURL: Player2, },
        { Name: "Sunil Chhetri", PhotoURL: Player3, },
        { Name: "Sunil Chhetri", PhotoURL: Player4, },];
    return (
        <>
            <section className='fame-parallex-1'>
                <div className="teams-flex-container">
                    {FameData && FameData.length > 0 && FameData.map((val, key) => {
                        return (
                            <div className="teams-item">
                                <div className="team-bg"></div>
                                <div className="team-bg1"></div>
                                <div className="team-img-wrapper">
                                    <img src={val.PhotoURL} alt="player" />
                                </div>
                                {/* <h3>2022</h3> */}
                                <h4>{val.Name}</h4>
                            </div>
                        );
                    })}
                </div>
            </section>
            {/* <section></section> */}
        </>
    )
}

export default FameCard;
