import React from "react";
import Layout from "../../components/Layout";
import Banner from "../../components/Banner";
import BannerImage from "../../assets/images/banner/banner-1.jpg";
import Club from "./Home/Club";
import AcademyTeam from "./Academy/AcademyTeam";
import Achievement from "./Academy/Achievement";
import Testimonials from "./Home/Testimonials";
import BirthdayCorner from "./Academy/BirthdayCorner";
import Score from "./Academy/Score";
import Fixture from "./Academy/Fixture";
function ClubMain() {
  const hideButton = true;
  return (
    <>
      <Layout>
        <Banner BannerImage={BannerImage} heading="Club" url="/club" />
        <Club hideButton={hideButton} />
        {/* <AcademyTeam /> */}
        <Achievement />
        {/* <Testimonials /> */}
        {/* <BirthdayCorner /> */}
        {/* <Score /> */}
        {/* <Fixture /> */}
      </Layout>
    </>
  );
}
export default ClubMain;
