import React from "react";
import Layout from "../../../components/Layout";
import { Helmet } from "react-helmet";

function Login() {
  return (
    <Layout>
      <Helmet>
        <title>Snigmay Pune FC – Pune’s Best Football Club</title>
        <meta
          name="description"
          content="Snigmay Pune FC – Pune’s Best Football Club, Premier facilities, brilliant coaches, expert guidance, and comprehensive training for all levels. "
        />
        <meta
          name="keywords"
          content="Best Football Club in Pune, Top Football Club in Pune, "
        />
      </Helmet>
      <section className="login-page-section">
        <div className="login-page-section-overlay">
          <div className="login-form-wrapper">
            <h1>Welcome To SNIGMAY</h1>
            <form action="">
              <input type="text" placeholder="Enter Email" />
              <input type="password" placeholder="Enter Password" />
              <button>Login</button>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Login;
