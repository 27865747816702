import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../../../assets/css/swiper.css";
import { Autoplay, Pagination, Navigation } from "swiper";
import Sudeva from "../../../assets/images/partners/sudeva.png";
import Sportszy from "../../../assets/images/partners/sportszy.png";
function TechnicalPartner() {
  const partner1 =
    "https://snigmaypunefc-storage-63a42a0a100429-dev.s3.ap-south-1.amazonaws.com/public/HomeTeam/tmt-logo.png";
  const partner2 =
    "https://snigmaypunefc-storage-63a42a0a100429-dev.s3.ap-south-1.amazonaws.com/public/HomeTeam/tma-logo.png";

  return (
    <>
      <section className="home-partners-section sec-pad">
        <div className="headling-wrapper">
          <h2
            className="common-heading"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            Technical & Venue Partners
          </h2>
        </div>
        <div className="container">
          <Swiper
            slidesPerView={4}
            spaceBetween={30}
            loop={true}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              300: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            className="mySwiper"
          >
            <SwiperSlide>
              <div
                className="partner-item"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-easing="ease-in-out"
                data-aos-delay="0"
              >
                <a href="https://www.trackmytournament.com/" target="_blank">
                  <img src={partner1} alt="partner" />
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="partner-item"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-easing="ease-in-out"
                data-aos-delay="300"
              >
                <a href="https://trackmyacademy.co/" target="_blank">
                  <img src={partner2} alt="partner" />
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="partner-item"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-easing="ease-in-out"
                data-aos-delay="300"
              >
                <a href="javascript:void(0)" target="_blank">
                  <img src={Sudeva} alt="partner" />
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="partner-item"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-easing="ease-in-out"
                data-aos-delay="300"
              >
                <a href="javascript:void(0)" target="_blank">
                  <img src={Sportszy} alt="partner" />
                </a>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </>
  );
}
export default TechnicalPartner;
