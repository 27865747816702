import React, { useEffect, useState } from "react";
import AdminLayout from "../../../components/AdminLayout";
import { FaTrash } from "react-icons/fa";
import { BsPencilFill } from "react-icons/bs";
import { DataStore } from "@aws-amplify/datastore";
import { Trials } from "../../../models";
import { createTrials } from "../../../api/TrialsAPI";
import moment from "moment";
import ConfirmationBox from "../../../components/ConfirmationBox";

function AddTrials() {
  const [Show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [SelectedDataForRemove, setSelectedDataForRemove] = useState({});
  const [trialData, setTrialData] = useState();
  const [Editdataflag, setEditdataflag] = useState(false);
  const [editTrialsObj, setEditTrialsObj] = useState({});
  const [formValues, setFormValues] = useState({
    AgeGroup: "",
    FromDate: "",
    FromTime: "",
    HubspotFormId: "",
    ToDate: "",
    ToTime: "",
    Venue: "",
  });

  const getTrialsData = () => {
    const subscription = DataStore.observeQuery(Trials).subscribe(
      async (snapshot) => {
        const trials = snapshot.items;
        console.log("trials", trials);
        setTrialData(trials);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const handleGetTimeDate = (time, date) => {
    let TimeSend = "00:00";
    console.log("SelectedTImeRecord", time, date);
    if (time !== undefined) {
      TimeSend = `${time}`;
    }
    const formattedTime = moment(TimeSend, "H:mm").format("HH:mm");
    const formattedDate = moment(date).format("ddd MMM DD YYYY");
    console.log("formattedDate", formattedDate);
    const dateformat = moment(formattedDate, "ddd MMM DD YYYY").format(
      "YYYY-MM-DD"
    );
    console.log("dateformat", formattedTime, dateformat);
    let data = {
      date: dateformat,
      time: formattedTime,
    };
    return data;
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    console.log("formValues", formValues);
    if (Editdataflag === false) {
      if (
        !formValues.AgeGroup ||
        !formValues.FromDate ||
        !formValues.FromTime ||
        !formValues.HubspotFormId ||
        !formValues.ToDate ||
        !formValues.ToTime ||
        !formValues.Venue
      ) {
        alert("Please fill in all required fields.");
        return;
      }
      try {
        console.log("formValues", formValues);
        let CreateTrialsObj = { ...formValues };
        console.log("Create Trials Obj", CreateTrialsObj);

        let datetime1 = await handleGetTimeDate(
          formValues.FromTime,
          formValues.FromDate
        );
        console.log("datetime", datetime1);
        let datetime2 = await handleGetTimeDate(
          formValues.ToTime,
          formValues.ToDate
        );
        console.log("datetime", datetime2);

        CreateTrialsObj.AgeGroup = formValues.AgeGroup;
        CreateTrialsObj.FromDate = datetime1.date;
        CreateTrialsObj.FromTime = datetime1.time;
        CreateTrialsObj.HubspotFormId = formValues.HubspotFormId;
        CreateTrialsObj.ToDate = datetime2.date;
        CreateTrialsObj.ToTime = datetime2.time;
        CreateTrialsObj.Venue = formValues.Venue;

        console.log("Trials Data  on submit", CreateTrialsObj);

        const response = await createTrials(CreateTrialsObj);
        console.log("Trials data saved successfully:", response);
        setMessage("New Trials added");
        setShow(true);
        setFormValues({
          AgeGroup: "",
          FromDate: "",
          FromTime: "",
          HubspotFormId: "",
          ToDate: "",
          ToTime: "",
          Venue: "",
        });
        getTrialsData();
      } catch (error) {
        console.error("Error saving Trials data:", error);
      }
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleRemove = async (data) => {
    console.log("data", data);
    try {
      const response = await DataStore.delete(Trials, data.id);
      getTrialsData();
    } catch (error) {}
  };

  const handleDelete = (data) => {
    console.log("handleDelete", data);
    setSelectedDataForRemove(data);
    setMessage("Do you really want to delete these records?");
    setShow(true);
  };

  const handleEdit = async (data) => {
    console.log("Edit", data);
    setEditTrialsObj(data);
    setEditdataflag(true);
    setFormValues({
      AgeGroup: data.AgeGroup,
      FromDate: data.FromDate,
      FromTime: data.FromTime,
      HubspotFormId: data.HubspotFormId,
      ToDate: data.ToDate,
      ToTime: data.ToTime,
      Venue: data.Venue,
    });
  };

  const handleEditsubmit = async () => {
    console.log("formValues", formValues);

    const trialsObj = await DataStore.query(Trials, editTrialsObj.id);
    console.log("trials Obj", trialsObj);
    let datetime1 = await handleGetTimeDate(
      formValues.FromTime,
      formValues.FromDate
    );
    console.log("datetime", datetime1);
    let datetime2 = await handleGetTimeDate(
      formValues.ToTime,
      formValues.ToDate
    );
    console.log("datetime", datetime2);
    if (trialsObj) {
      // eslint-disable-next-line no-use-before-define
      const TrialsUpdateObj = Trials.copyOf(trialsObj, (updated) => {
        updated.AgeGroup = formValues.AgeGroup;
        updated.FromDate = datetime1.date;
        updated.FromTime = datetime1.time;
        updated.HubspotFormId = formValues.HubspotFormId;
        updated.ToDate = datetime2.date;
        updated.ToTime = datetime2.time;
        updated.Venue = formValues.Venue;
      });
      console.log("trials Obj", trialsObj);
      const ResultTrialsItem = await DataStore.save(TrialsUpdateObj);
      setMessage("Records updated successfully!");
      setShow(true);
      if (ResultTrialsItem) {
        setFormValues({
          AgeGroup: "",
          FromDate: "",
          FromTime: "",
          HubspotFormId: "",
          ToDate: "",
          ToTime: "",
          Venue: "",
        });
        getTrialsData();
      }
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setFormValues({
      AgeGroup: "",
      FromDate: "",
      FromTime: "",
      HubspotFormId: "",
      ToDate: "",
      ToTime: "",
      Venue: "",
    });
    setEditdataflag(false);
  };

  // confirm box started
  const handleConfirm = async () => {
    setMessage("");
    console.log("confirm", SelectedDataForRemove);
    setShow(false);
    handleRemove(SelectedDataForRemove);
  };

  const handleDecline = async () => {
    setMessage("");
    setShow(false);
  };

  const handleOk = async () => {
    setMessage("");
    setShow(false);
  };
  // confirm box ended

  useEffect(() => {
    getTrialsData();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-content-container">
        <div className="admin-left-side">
          <h1 className="common-heading">Add Trials </h1>
          <div className="white-box">
            <form action="" onSubmit={handleSubmitForm}>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Trials Start Date :
                </label>
                <input
                  class="form-control"
                  placeholder="Enter  Trials Date "
                  type="date"
                  value={formValues.FromDate}
                  name="FromDate"
                  id=""
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Trials Start Time :
                </label>
                <input
                  class="form-control"
                  placeholder="Enter  Trials Date "
                  type="time"
                  value={formValues.FromTime}
                  name="FromTime"
                  id=""
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Trials End Date :
                </label>
                <input
                  class="form-control"
                  placeholder="Enter  Trials Date "
                  type="date"
                  value={formValues.ToDate}
                  name="ToDate"
                  id=""
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Trials End Time :
                </label>
                <input
                  class="form-control"
                  placeholder="Enter  Trials Date "
                  type="time"
                  value={formValues.ToTime}
                  name="ToTime"
                  id=""
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Age Group :
                </label>
                <input
                  class="form-control"
                  placeholder="e.g. U-13, U-07"
                  type="text"
                  value={formValues.AgeGroup}
                  name="AgeGroup"
                  id=""
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Trials Venue :
                </label>
                <input
                  class="form-control"
                  placeholder="Enter Trials Venue"
                  type="text"
                  value={formValues.Venue}
                  name="Venue"
                  id=""
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  HubSpot Form Id :
                </label>
                <input
                  class="form-control"
                  placeholder=""
                  type="text"
                  value={formValues.HubspotFormId}
                  name="HubspotFormId"
                  id=""
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group admin-btn-container">
                {Editdataflag == false ? (
                  <button
                    className="bg-success"
                    onClick={() => {
                      handleSubmitForm();
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    className="bg-success"
                    onClick={() => {
                      handleEditsubmit();
                    }}
                  >
                    Update
                  </button>
                )}
                <button
                  className="bg-danger"
                  onClick={(e) => {
                    handleCancel(e);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="admin-right-side">
          <h1 className="common-heading">All Trials</h1>
          <div className="white-box">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th width="5%" className="text-center">
                    SN
                  </th>
                  <th width="25%">Date & Time</th>
                  <th width="25%">Age Group</th>
                  <th width="35%">Venue</th>
                  <th width="10%">Action</th>
                </tr>
              </thead>
              <tbody>
                {trialData &&
                  trialData.length > 0 &&
                  trialData.map((data, index) => (
                    <tr>
                      <td className="text-center">{index + 1}</td>
                      <td>
                        <p style={{ fontSize: "15px" }}>
                          {" "}
                          Start -{data.FromDate}-{data.FromTime}
                        </p>
                        <p style={{ fontSize: "15px" }}>
                          End -{data.ToDate}-{data.ToTime}
                        </p>
                      </td>
                      <td>{data.AgeGroup}</td>
                      <td>{data.Venue}</td>
                      <td>
                        <ul className="table-action-btn">
                          <li>
                            <BsPencilFill
                              className="text-success"
                              onClick={() => {
                                handleEdit(data);
                              }}
                            />
                          </li>

                          <li>
                            <FaTrash
                              className="text-danger"
                              onClick={() => {
                                handleDelete(data);
                              }}
                            />
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {Show ? (
        <ConfirmationBox
          show={Show}
          message={message}
          handleConfirm={
            message == "New Trials added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleConfirm
          }
          handleDecline={
            message == "New Trials added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleDecline
          }
          handleOK={
            message == "New Trials added" ||
            message == "Records updated successfully!"
              ? handleOk
              : undefined
          }
        />
      ) : null}
    </AdminLayout>
  );
}

export default AddTrials;
