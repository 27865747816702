import React from "react";
import Layout from "../../components/Layout";
import Banner from "../../components/Banner";
import BannerImage from "../../assets/images/banner/banner-1.jpg";
import { IoMdMap } from "react-icons/io";
import { IoIosCall } from "react-icons/io";
import { IoIosMail } from "react-icons/io";
import ContactForm from "./../../components/ContactForm";
import { Helmet } from "react-helmet";
function Contact() {
  return (
    <Layout>
      <Helmet>
        <title>Contact Us </title>
        <meta
          name="description"
          content="Contact us through +91 9920614314 or Email "
        />
        <meta name="keywords" content="Contact Us, " />
      </Helmet>
      <div style={{ overflow: "hidden" }}>
        <Banner BannerImage={BannerImage} heading="Contact" url="/contact" />
        <section className="contact-section sec-pad">
          <div className="container">
            <div className="contact-details-container">
              <div>
                <h2
                  className="common-heading"
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  know about us
                </h2>
                <h4
                  className="common-subheading"
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  get in touch
                </h4>
              </div>
              <div className="contact-details-container-wrapper mt-4">
                <div
                  className="contact-details-item"
                  data-aos="fade-up-right"
                  data-aos-once="true"
                  data-aos-dela="300"
                >
                  <div className="contact-item-icon-wrapper">
                    <h2>
                      <IoMdMap />
                    </h2>
                  </div>
                  <div className="contact-item-content-wrapper">
                    <h2>Head Office</h2>
                    <p>
                      Sportzy Arena, Near Opposite Sharda English Med School,
                      Rahatani, Pune- 411017
                    </p>
                  </div>
                </div>

                <div
                  className="contact-details-item"
                  data-aos="fade-up-right"
                  data-aos-once="true"
                  data-aos-dela="600"
                >
                  <div className="contact-item-icon-wrapper">
                    <h2>
                      <IoIosCall />
                    </h2>
                  </div>
                  <div className="contact-item-content-wrapper">
                    <h2>Contact</h2>
                    <p>
                      <a href="tel:9111111111">+91 9920614314</a>
                    </p>
                  </div>
                </div>
                <div
                  className="contact-details-item"
                  data-aos="fade-up-right"
                  data-aos-once="true"
                  data-aos-dela="900"
                >
                  <div className="contact-item-icon-wrapper">
                    <h2>
                      <IoIosMail />
                    </h2>
                  </div>
                  <div className="contact-item-content-wrapper">
                    <h2>email address</h2>
                    <p>
                      <a href="mailto:test@example.com" target="_blank">
                        admin@snigmaypunefc.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="contact-form-container"
              data-aos="zoom-in"
              data-aos-once="true"
            >
              <h2
                className="common-heading"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                Contact Us
              </h2>
              <h4
                className="common-subheading"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                DO YOU HAVE ANY QUESTIONS?
              </h4>
              <div className="contact-from-wrapper">
                <ContactForm />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="google-map-container">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.212824435427!2d73.78979247335401!3d18.609494866455492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b98f901102e9%3A0xad9758500561934e!2sSportszy%20Arena!5e0!3m2!1sen!2sin!4v1715148908120!5m2!1sen!2sin"
          // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.192061086647!2d73.76644327410094!3d18.565377967806214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf94c44576f3%3A0x797deefe4f83d755!2sDigitrack%20Sports%20India%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1682338472365!5m2!1sen!2sin"
          height="300"
          top="1825"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        />
      </div>
    </Layout>
  );
}
export default Contact;
