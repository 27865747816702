import React from "react";
import Layout from "../../components/Layout";
import Banner from "../../components/Banner";
import BannerImage from "../../assets/images/WallofFame/banner.jpg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FameCard from "./FameWall/FameCard";
import TeamAchievement from "./FameWall/TeamAchievement";

function WallOfFame() {
  return (
    <Layout>
      <Banner
        BannerImage={BannerImage}
        heading="Wall of Fame"
        url="/wall-of-fame"
      />
      <section className="gallery-section wall-of-fame-section ">
        <div className="wall-of-fame-overlay sec-pad">
          <div className="headling-wrapper">
            <h2 className="common-heading">Wall of Fame</h2>
            <h4 className="common-subheading">Lorem ipsum dolor.</h4>
          </div>
          <div className="container">
            <Tabs defaultActiveKey="individual" id="">
              <Tab eventKey="individual" title="individual">
                <FameCard />
              </Tab>
              <Tab eventKey="team-achievement" title="team achievements">
                <TeamAchievement />
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default WallOfFame;
