import React from 'react';
import PlantIcon from "../../../assets/images/icons/plant.png"

function CSRProgramme() {
    return (
        <section className='car-programme-section sec-pad'>
            <div className="container">
                <div className="headling-wrapper white-headline">
                    <h2 className="common-heading">Programme</h2>
                    <h4 className="common-subheading">
                        LOREM IPSUM DOLOR SIT
                    </h4>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 c-overview-left">
                        <div className="csr-overview-left  wow fadeInLeft">
                            <p>Lorem ipsum labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud  commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            <p>Sed ut perspiciatis doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 csr-overview-right">
                        <div className=" overview-right  wow fadeInRight">
                            <h3>SNIGMAY CSR Programme</h3>
                            <ul>
                                <li>
                                    <aside><img src={PlantIcon} alt="" /></aside>
                                    <p>Lorem ipsum labore <br /> et dolore</p>
                                </li>
                                <li>
                                    <aside><img src={PlantIcon} alt="" /></aside>
                                    <p>Lorem ipsum labore <br /> et dolore</p>
                                </li>
                                <li>
                                    <aside><img src={PlantIcon} alt="" /></aside>
                                    <p>Lorem ipsum labore <br /> et dolore</p>
                                </li>
                                <li>
                                    <aside><img src={PlantIcon} alt="" /></aside>
                                    <p>Lorem ipsum labore <br /> et dolore</p>
                                </li>
                                <li>
                                    <aside><img src={PlantIcon} alt="" /></aside>
                                    <p>Lorem ipsum labore <br /> et dolore</p>
                                </li>
                                <li>
                                    <aside><img src={PlantIcon} alt="" /></aside>
                                    <p>Lorem ipsum labore <br /> et dolore</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CSRProgramme;
