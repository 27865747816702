import React from "react";
import Layout from "../../components/Layout";
import Banner from "../../components/Banner";
import BannerImage from "../../assets/images/banner/banner-1.jpg";
import AmenitiesIntro from "./Amenities/AmenitiesIntro";
import Facilities from "./Amenities/Facilities";
import FacilitiesVideo from "./Amenities/FacilitiesVideo";
import { Helmet } from "react-helmet";

function Amenities() {
  return (
    <Layout>
      <Helmet>
        <title>Top-notch facilities</title>
        <meta
          name="description"
          content="Snigmay Pune FC provides top-notch facilities: gym, world-class turf, video analysis room, swimming sessions, and AIFF-approved natural grass ground for an exceptional training experience."
        />
        <meta name="keywords" content="Top-notch facilities" />
      </Helmet>
      <Banner
        BannerImage={BannerImage}
        heading="Amenities"
        url="/our-amenities"
      />
      <AmenitiesIntro />
      <FacilitiesVideo />
      <Facilities />
    </Layout>
  );
}

export default Amenities;
