import React, { useEffect, useState } from "react";
import RoutesList from "./Router/Routes";
import { Amplify } from "aws-amplify";
import config from "./aws-exports.js";
import "./assets/css/style.css";
import "./assets/css/custom.css";
import "./assets/css/navbar.css";
import "./assets/css/responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import Aos from "aos";
import Loader from "./Pages/Web/Loader/Loader.jsx";
import "./Pages/Web/Loader/Loader.css";
import VideoLoader from "./Pages/Web/Loader/VideoLoader.jsx";

Amplify.configure(config);

function App() {
  const [loading, setLoading] = useState(true);
  const [videoLoading, setVideoLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 37500);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setVideoLoading(false);
    }, 30000);
  }, []);

  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
      once: true,
    });
  }, []);
  return (
    <>
      {videoLoading ? (
        <VideoLoader />
      ) : (
        <>
          {loading && (
            <div className="app-loader-container">
              <Loader />
            </div>
          )}
          {/* {loading ? <Loader /> : <RoutesList />} */}
          <RoutesList />
        </>
      )}
    </>
  );
}

export default App;
