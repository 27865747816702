import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "../../../components/AdminLayout";
import { FaTrash } from "react-icons/fa";
import { BsPencilFill } from "react-icons/bs";
import defaultClubLogo from "../../../assets/images/default/default-club-logo.png";
import { createMatches } from "../../../api/MatchesAPI";
import { DataStore } from "@aws-amplify/datastore";
import { uploadData } from "@aws-amplify/storage";
import { Matches } from "../../../models";
import moment from "moment";
import ConfirmationBox from "../../../components/ConfirmationBox";
import { awsmobile } from "../../../aws-exports";

function AddMatches() {
  const [Show, setShow] = useState(false);
  const [SelectedDataForRemove, setSelectedDataForRemove] = useState({});
  const [message, setMessage] = useState("");
  const [matchesData, setMatchesData] = useState();
  const [AwayTeamLogofile, setAwayTeamLogofile] = useState();
  const [HomeTeamLogofile, setHomeTeamLogofile] = useState();
  const [Commonfileurl, setCommonfileurl] = useState();
  const [Editdataflag, setEditdataflag] = useState(false);
  const [EditObj, setEditObj] = useState({});
  const [formValues, setFormValues] = useState({
    TournamentName: "",
    HomeTeamName: "",
    HomeTeamScore: 0,
    HomeTeamLogo: "",
    OpponentTeamName: "",
    OpponentTeamScore: 0,
    OpponentTeamLogo: "",
    Date: 0,
    Time: 0,
    Location: "",
  });
  const awsBucket = awsmobile.aws_user_files_s3_bucket;
  const inputFile = useRef(null);
  const inputFile2 = useRef(null);

  const validateFileType = async (e, Type) => {
    console.log("eventvalue", e);
    var file = e.target.files[0];
    const fileurl = URL.createObjectURL(file);
    setCommonfileurl(fileurl);
    console.log("fileurl", file, fileurl);
    var fileType = file.type;
    if (
      fileType === "image/jpg" ||
      fileType === "image/jpeg" ||
      fileType === "image/png"
    ) {
      if (Type === "AwayTeam") {
        setAwayTeamLogofile(file);
      }
      if (Type === "HomeTeam") {
        setHomeTeamLogofile(file);
      }
    } else {
      alert("Only jpg/jpeg and png files are allowed!");
    }
  };

  const uploadLogoFileToStorage = async (folderName, file) => {
    console.log("filetype", file.type, file.size);
    const contentType = file.type;
    let fileName = file.name.replaceAll(" ", "_");
    console.log("hellocontent", file.name);
    console.log("contentType", contentType);
    try {
      const uploadTask = await uploadData({
        key: `${folderName}/${fileName}`,
        data: file,
        options: {
          contentType: contentType,
        },
      }).result;

      console.log("Succeeded: ", uploadTask);
      let Response = `https://${awsBucket}.s3.ap-south-1.amazonaws.com/public/${folderName}/${fileName}`;

      return Response;
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (Editdataflag === false) {
      if (
        !formValues.TournamentName ||
        !formValues.HomeTeamName ||
        !formValues.Date ||
        !formValues.Time ||
        !formValues.Location
      ) {
        alert("Please fill in all required fields.");
        return;
      }
      try {
        console.log("formValues", formValues);
        let CreateObj = { ...formValues };
        console.log("CreateObj", CreateObj);
        let Awaylogo = "";
        let Homelogo = "";
        if (AwayTeamLogofile && HomeTeamLogofile && Commonfileurl) {
          Awaylogo = await uploadLogoFileToStorage(
            "Matches",
            AwayTeamLogofile,
            Commonfileurl
          );
          Homelogo = await uploadLogoFileToStorage(
            "Matches",
            HomeTeamLogofile,
            Commonfileurl
          );
        }

        let TimeSend = "00:00";
        console.log("SelectedTImeRecord", formValues.Time, formValues.Date);
        if (formValues.Time !== undefined) {
          TimeSend = `${formValues.Time}`;
        }
        const formattedTime = moment(TimeSend, "H:mm").format("HH:mm");
        const formattedDate = moment(formValues.Date).format("ddd MMM DD YYYY");
        console.log("formattedDate", formattedDate);
        const dateformat = moment(formattedDate, "ddd MMM DD YYYY").format(
          "YYYY-MM-DD"
        );
        console.log("dateformat", formattedTime, dateformat);

        CreateObj.HomeTeamLogo = Homelogo;
        CreateObj.OpponentTeamLogo = Awaylogo;
        CreateObj.OpponentTeamScore = Number(formValues.OpponentTeamScore);
        CreateObj.HomeTeamScore = Number(formValues.HomeTeamScore);
        CreateObj.Date = dateformat;
        CreateObj.Time = formattedTime;
        console.log("HomeawayteamLogourl", CreateObj, Awaylogo, Homelogo);

        const response = await createMatches(CreateObj);
        setMessage("Matches added");
        setShow(true);
        console.log("Match data saved successfully:", response);
        inputFile.current.value = "";
        inputFile2.current.value = "";
        setFormValues({
          TournamentName: "",
          HomeTeamName: "",
          HomeTeamScore: 0,
          HomeTeamLogo: "",
          OpponentTeamName: "",
          OpponentTeamScore: 0,
          OpponentTeamLogo: "",
          Date: 0,
          Time: 0,
          Location: "",
        });
        // getMatches();
      } catch (error) {
        console.error("Error saving match data:", error);
      }
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const getMatches = () => {
    const subscription = DataStore.observeQuery(Matches).subscribe(
      async (snapshot) => {
        const matches = snapshot.items;
        console.log("Matches", matches);
        const compareDates = (a, b) =>
          new Date(b.createdAt) - new Date(a.createdAt);
        matches.sort(compareDates);
        console.log("SortedArray", matches);
        setMatchesData(matches);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const handleRemove = async (data) => {
    console.log("data", data);
    try {
      const response = await DataStore.delete(Matches, data.id);
      // getMatches();
    } catch (error) {}
  };

  const handleDelete = (data) => {
    console.log("handleDelete", data);
    setSelectedDataForRemove(data);
    setMessage("Do you really want to delete these records?");
    setShow(true);
  };

  const handleEditsubmit = async () => {
    console.log("formValues", formValues);
    let Awaylogo = "";
    let Homelogo = "";
    if (AwayTeamLogofile && HomeTeamLogofile && Commonfileurl) {
      Awaylogo = await uploadLogoFileToStorage(
        "AwayTeam",
        AwayTeamLogofile,
        Commonfileurl
      );
      Homelogo = await uploadLogoFileToStorage(
        "HomeTeam",
        HomeTeamLogofile,
        Commonfileurl
      );
    }

    let TimeSend = "00:00";
    console.log("SelectedTImeRecord", formValues.Time, formValues.Date);
    if (formValues.Time !== undefined) {
      TimeSend = `${formValues.Time}`;
    }
    const formattedTime = moment(TimeSend, "H:mm").format("HH:mm");
    const formattedDate = moment(formValues.Date).format("ddd MMM DD YYYY");
    console.log("formattedDate", formattedDate);
    const dateformat = moment(formattedDate, "ddd MMM DD YYYY").format(
      "YYYY-MM-DD"
    );
    console.log("dateformat", formattedTime, dateformat);
    const MatchObj = await DataStore.query(Matches, EditObj.id);
    console.log("MatchObj", MatchObj);
    if (MatchObj) {
      const MatchUpdatedObj = Matches.copyOf(MatchObj, (updated) => {
        updated.HomeTeamLogo =
          Homelogo !== "" ? Homelogo : updated.HomeTeamLogo;
        updated.Date = dateformat;
        updated.HomeTeamName = formValues.HomeTeamName;
        updated.HomeTeamScore = Number(formValues.HomeTeamScore);
        updated.OpponentTeamLogo =
          Awaylogo !== "" ? Awaylogo : updated.OpponentTeamLogo;
        updated.OpponentTeamName = formValues.OpponentTeamName;
        updated.OpponentTeamScore = Number(formValues.OpponentTeamScore);
        updated.Time = formattedTime;
        updated.TournamentName = formValues.TournamentName;
        updated.Location = formValues.Location;
        return updated;
      });
      console.log("MatchUpdatedObj", MatchUpdatedObj);
      const ResultofMatchTeam = await DataStore.save(MatchUpdatedObj);
      setMessage("Records updated successfully!");
      setShow(true);

      if (ResultofMatchTeam) {
        inputFile.current.value = "";
        inputFile2.current.value = "";
        setFormValues({
          TournamentName: "",
          HomeTeamName: "",
          HomeTeamScore: 0,
          HomeTeamLogo: "",
          OpponentTeamName: "",
          OpponentTeamScore: 0,
          OpponentTeamLogo: "",
          Date: 0,
          Time: 0,
          Location: "",
        });
        // getMatches();
      }
    }
  };
  const handleEdit = async (data) => {
    console.log("Edit", data);
    setEditObj(data);
    setEditdataflag(true);
    setFormValues({
      TournamentName: data.TournamentName,
      HomeTeamName: data.HomeTeamName,
      HomeTeamScore: data.HomeTeamScore,
      HomeTeamLogo: data.HomeTeamLogo,
      OpponentTeamName: data.OpponentTeamName,
      OpponentTeamScore: data.OpponentTeamScore,
      OpponentTeamLogo: data.OpponentTeamLogo,
      Date: data.Date,
      Time: data.Time,
      Location: data.Location,
    });
  };
  const handleCancel = (e) => {
    e.preventDefault();
    setFormValues({
      TournamentName: "",
      HomeTeamName: "",
      HomeTeamScore: 0,
      HomeTeamLogo: "",
      OpponentTeamName: "",
      OpponentTeamScore: 0,
      OpponentTeamLogo: "",
      Date: 0,
      Time: 0,
      Location: "",
    });
    inputFile.current.value = "";
    inputFile2.current.value = "";
    setAwayTeamLogofile(null);
    setHomeTeamLogofile(null);
    setCommonfileurl(null);
    setEditdataflag(false);
  };
  // confirm box started
  const handleConfirm = async () => {
    console.log("confirm", SelectedDataForRemove);
    setShow(false);
    handleRemove(SelectedDataForRemove);
  };

  const handleDecline = async () => {
    setShow(false);
  };

  const handleOk = async () => {
    setShow(false);
  };
  // confirm box ended

  useEffect(() => {
    getMatches();
  }, []);
  return (
    <AdminLayout>
      <div className="admin-content-container">
        <div className="admin-left-side">
          <h1 className="common-heading">Add Matches Data</h1>
          <div className="white-box">
            <form action="submit" onSubmit={handleSubmitForm}>
              <div class="form-group mb-3">
                <label class="form-label" for="example">
                  Tournament Name :
                </label>
                <input
                  class="form-control"
                  placeholder="Enter Tournament Name"
                  type="text"
                  id="example"
                  name="TournamentName"
                  value={formValues.TournamentName}
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Home Team Name :
                </label>
                <input
                  class="form-control"
                  type="text"
                  name="HomeTeamName"
                  value={formValues.HomeTeamName}
                  id=""
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Home Team Logo :
                  <small className="text-danger">
                    (Size should be 100 X 100 )
                  </small>
                </label>
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  name="HomeTeamLogo"
                  ref={inputFile}
                  onChange={(e) => {
                    validateFileType(e, "HomeTeam");
                  }}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Home Team Score :
                </label>
                <input
                  class="form-control"
                  type="text"
                  value={formValues.HomeTeamScore}
                  name="HomeTeamScore"
                  id=""
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Opponent Team Name :
                </label>
                <input
                  class="form-control"
                  type="text"
                  value={formValues.OpponentTeamName}
                  name="OpponentTeamName"
                  id=""
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Opponent Team Logo :
                  <small className="text-danger">
                    (Size should be 100 X 100 )
                  </small>
                </label>
                <input
                  class="form-control"
                  type="file"
                  ref={inputFile2}
                  name="OpponentTeamLogo"
                  id="formFile"
                  onChange={(e) => {
                    validateFileType(e, "AwayTeam");
                  }}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Opponent Team Score :
                </label>
                <input
                  class="form-control"
                  type="text"
                  value={formValues.OpponentTeamScore}
                  name="OpponentTeamScore"
                  id=""
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Date:
                </label>
                <input
                  class="form-control"
                  // type="text"
                  type="date"
                  value={formValues.Date}
                  name="Date"
                  id=""
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Time:
                </label>
                <input
                  class="form-control"
                  // type="text"
                  type="time"
                  value={formValues.Time}
                  name="Time"
                  id=""
                  onChange={handleOnChange}
                />
              </div>
              <div class="form-group mb-3">
                <label class="form-label" for="">
                  Location :
                </label>
                <input
                  class="form-control"
                  type="text"
                  id=""
                  value={formValues.Location}
                  name="Location"
                  onChange={handleOnChange}
                />
              </div>

              <div class="form-group admin-btn-container">
                {Editdataflag == false ? (
                  <button
                    className="bg-success"
                    onClick={() => {
                      handleSubmitForm();
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <button
                    className="bg-success"
                    onClick={() => {
                      handleEditsubmit();
                    }}
                  >
                    Update
                  </button>
                )}
                <button
                  className="bg-danger"
                  onClick={(e) => {
                    handleCancel(e);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="admin-right-side">
          <h1 className="common-heading">All Matches Data</h1>
          <div className="white-box">
            <div
              className="row home-recent-match-section"
              style={{ background: "#fff" }}
            >
              {matchesData &&
                matchesData.length > 0 &&
                matchesData.map((data, index) => (
                  <div className="col-md-6" key={index}>
                    <div
                      className="recent-maches-item"
                      data-aos="fade-up"
                      data-aos-once="true"
                      style={{
                        background: "#515151ad",
                        marginBottom: 20,
                      }}
                    >
                      <h3>{data.TournamentName}</h3>
                      <div className="score-team-containerr">
                        <div className="team-logo-wrapper">
                          <img
                            src={data.HomeTeamLogo}
                            onError={(e) => (e.target.src = defaultClubLogo)}
                            alt="team logo"
                          />
                          <h4>{data.HomeTeamName}</h4>
                        </div>
                        <div className="score-wrapper">
                          {data.HomeTeamScore} &nbsp;-&nbsp;
                          {data.OpponentTeamScore}
                        </div>
                        <div className="team-logo-wrapper">
                          <img
                            src={data.OpponentTeamLogo}
                            onError={(e) => (e.target.src = defaultClubLogo)}
                            alt="team logo"
                          />
                          <h4>{data.OpponentTeamName}</h4>
                        </div>
                      </div>
                      <h4>
                        {data.Date},&nbsp;&nbsp;{data.Time}, <br />{" "}
                        {data.Location}
                      </h4>
                      <div className="admin-match-card">
                        <ul className="table-action-btn">
                          <li>
                            <BsPencilFill
                              className="text-success"
                              onClick={() => {
                                handleEdit(data);
                              }}
                            />
                          </li>

                          <li>
                            <FaTrash
                              className="text-danger"
                              onClick={() => {
                                handleDelete(data);
                              }}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      {Show ? (
        <ConfirmationBox
          show={Show}
          message={message}
          handleConfirm={
            message == "Matches added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleConfirm
          }
          handleDecline={
            message == "Matches added" ||
            message == "Records updated successfully!"
              ? undefined
              : handleDecline
          }
          handleOK={
            message == "Matches added" ||
            message == "Records updated successfully!"
              ? handleOk
              : undefined
          }
        />
      ) : null}
    </AdminLayout>
  );
}
export default AddMatches;
