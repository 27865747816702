export const awsmobile = {
  aws_project_region: "ap-south-1",
  aws_appsync_graphqlEndpoint:
    "https://b4ujffh3pneonlsofxieth7yx4.appsync-api.ap-south-1.amazonaws.com/graphql",
  aws_appsync_region: "ap-south-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-r5dclemixzfz3btt4rfz444w64",
  aws_cognito_identity_pool_id:
    "ap-south-1:b6569251-16f7-4d2e-974b-79535481c406",
  aws_cognito_region: "ap-south-1",
  aws_user_pools_id: "ap-south-1_ViDxOdAnd",
  aws_user_pools_web_client_id: "1uqu0fsbucdpe804fjufsa2p11",
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
      "REQUIRES_UPPERCASE",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_user_files_s3_bucket: "snigmaypunefc-storage-63a42a0a7ed69-prod",
  aws_user_files_s3_bucket_region: "ap-south-1",
};

export default awsmobile;
