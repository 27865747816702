import React from "react";
import Layout from "../../components/Layout";
import Banner from "../../components/Banner";
import BannerImage from "../../assets/images/banner/banner-1.jpg";
import AdvisoryBoard from "../Web/About/TeamBehindTeam/AdvisoryBoard";
import ManagementTeam from "../Web/About/TeamBehindTeam/ManagementTeam";
import TechnicalTeam from "../Web/About/TeamBehindTeam/TechnicalTeam";
import { Helmet } from "react-helmet";

function TeamBehindTeam() {
  return (
    <Layout>
      <Helmet>
        <title>Owner of Snigmay Pune FC</title>
        <meta
          name="description"
          content="Proud to be the Owner of Snigmay Pune FC and lead our team towards greatness. Our goal is to shine on the field and uplift our community. "
        />
        <meta name="keywords" content="Owner of Snigmay Pune FC" />
      </Helmet>
      <Banner
        BannerImage={BannerImage}
        heading="Team Behind The Team"
        url="/about"
      />
      <ManagementTeam />
      <AdvisoryBoard />
      <TechnicalTeam />
    </Layout>
  );
}

export default TeamBehindTeam;
