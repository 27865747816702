import React from "react";
import { Link } from "react-router-dom";
// import Player from "../../../assets/images/home/player.png";
// import Player from "https://snigmaypunefc-storage-63a42a0a100429-dev.s3.ap-south-1.amazonaws.com/public/HomeTeam/home-club-player.png";
function Club(props) {
  const Player =
    "https://snigmaypunefc-storage-63a42a0a100429-dev.s3.ap-south-1.amazonaws.com/public/HomeTeam/Untitled+design+(16).png";
  return (
    <>
      <section className="home-club-section sec-top-pad">
        <div className="home-club-inner-wrapper">
          <div className="container">
            <div className="home-club-content-wrapper">
              <h2
                className="common-heading"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                Our Club
              </h2>
              <h4
                className="common-subheading"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                Born in Pune, conquering the World.
              </h4>
              <p>
                As a club, we provide a unique opportunity to all our players to
                play at various club level age group tournaments starting from
                under 7 going right up to Super Division, which is the top most
                division in Pune District. Our performances speak for our growth
                both on & off the field, being amongst very few clubs in the
                district to have got promoted to junior to the top most tier
                with consecutive promotions.
              </p>

              {!props.hideButton && (
                <Link to="/about">
                  <div className="home-btn-animation">View More</div>
                </Link>
              )}
            </div>
            <div className="home-club-image-wrapper">
              <div className="home-club-overlay"></div>
              <div className="img-container">
                <img
                  src={Player}
                  alt="player"
                  data-aos="fade-up"
                  data-aos-once="true"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Club;
