import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  GrFacebookOption,
  GrInstagram,
  GrTwitter,
  GrLinkedinOption,
} from "react-icons/gr";
import { DataStore } from "@aws-amplify/datastore";
import { TeamBehindTeam } from "../../../../models";
import Modal from "react-bootstrap/Modal";
import Avtar from "../../../../assets/images/avtar/snigmay-avtar.png";

function AdvisoryBoard() {
  const [allData, setAllData] = useState([]);
  const [AdvBoardData, setAdvBoardData] = useState([]);
  const [modalData, setModalData] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (values) => {
    setShow(true);
    setModalData(values);
  };

  useEffect(() => {
    const subscription = DataStore.observeQuery(TeamBehindTeam).subscribe(
      async (snapshot) => {
        try {
          const TBTdata = snapshot.items;
          console.log("Team Behind Team data", TBTdata);
          setAllData(TBTdata);
          // Move the call to getManagementTeamData here
          getManagementTeamData(TBTdata);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
      (error) => {
        console.error("Subscription error:", error);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const getManagementTeamData = (data) => {
    const ManagementArray = [];
    const AdvisoryArray = [];
    const TechnicalArray = [];

    data.forEach((member) => {
      const teamName = member.Team;
      const teamObject = {
        PhotoURL: member.PhotoURL,
        Name: member.Name,
        Position: member.Designation,
        fbURL: member.Facebook,
        instaURL: member.Instagram,
        twitterURL: member.Twitter,
        LinkedInURL: member.Linkedin,
        Priority: member.Priority,
        Description: member.Description,
      };

      if (teamName === "Advisory Board") {
        AdvisoryArray.push(teamObject);
      }
    });
    console.log("Advisory Board:", AdvisoryArray);
    const sortedTeams = AdvisoryArray.sort((a, b) => a.Priority - b.Priority);

    setAdvBoardData(sortedTeams);

    console.log("Advisory Board:", AdvisoryArray);
  };

  return (
    <>
      <div className="team-container sec-pad">
        <div className="container">
          <div className="headling-wrapper white-headline">
            <h2
              className="common-heading"
              data-aos="zoom-in"
              data-aos-once="true"
              data-aos-easing="ease-in-out"
            >
              Our Advisory Board
            </h2>
            {/* <h4
              className="common-subheading"
              data-aos="zoom-in"
              data-aos-once="true"
              data-aos-easing="ease-in-out"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </h4> */}
          </div>
          <div className="row academy-teams-section advisory-board-team-container">
            {AdvBoardData &&
              AdvBoardData.length > 0 &&
              AdvBoardData.map((val, key) => {
                return (
                  <div className="col-md-3">
                    <div
                      className="academy-team-item"
                      data-aos="fade-up"
                      data-aos-once="true"
                      data-aos-easing="ease-in-out"
                      onClick={() => handleShow(val)}
                    >
                      <img
                        // src={val.PhotoURL}
                        src={val.PhotoURL ? val.PhotoURL : Avtar}
                        onError={(e) => (e.target.src = Avtar)}
                        alt="player"
                      />
                      <h2>{val.Name}</h2>
                      <h3>{val.Position}</h3>
                      <ul>
                        <li>
                          <a
                            href={val.fbURL}
                            target="_blank"
                            className="footer-fb"
                          >
                            <GrFacebookOption />
                          </a>
                        </li>
                        <li>
                          <a
                            href={val.InstaURL}
                            target="_blank"
                            className="footer-insta"
                          >
                            <GrInstagram />
                          </a>
                        </li>
                        <li>
                          <a
                            href={val.TwitterURL}
                            target="_blank"
                            className="footer-twitter"
                          >
                            <GrTwitter />
                          </a>
                        </li>
                        <li>
                          <a
                            href={val.LinkedInURL}
                            target="_blank"
                            className="footer-linkedIn"
                          >
                            <GrLinkedinOption />
                          </a>
                        </li>
                      </ul>
                      <div class="team_card_hover">
                        <Link to={val.ProfileURL}>View Profile</Link>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      {/* modal started here */}
      {show && (
        <Modal
          className="amenities-modal-container team-behind-team-modal"
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="team-behind-team-modal-wrapper">
              <img src={modalData.PhotoURL} alt="" />
              <h2>{modalData.Name}</h2>
              <h3>{modalData.Position}</h3>
            </div>
            <p className="mb-0 text-center">
              {modalData.Description !== "" && modalData.Description !== null
                ? modalData.Description
                : "No Data Available"}
            </p>
          </Modal.Body>
        </Modal>
      )}
      {/* modal ended here */}
    </>
  );
}
export default AdvisoryBoard;
