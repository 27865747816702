import React from "react";
import Form from "react-bootstrap/Form";
function ContactForm() {
  return (
    <>
      <Form className="mt-4" >
        <div className="row">
          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control type="text" placeholder="Name" />
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control type="text" placeholder="Email" />
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control type="text" placeholder="Phone" />
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Select className="mb-3" aria-label="Default select example">
              <option>Purpose of Enquiry</option>
              <option value="1">Sponsorship</option>
              <option value="2">General Enquiry</option>
              <option value="3">Academy Admission</option>
            </Form.Select>
          </div>
          {/* <div className="col-md-6">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control type="text" placeholder="Purpose of Enquiry" />
            </Form.Group>
          </div> */}
          <div className="col-12">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                as="textarea"
                type="text"
                placeholder="Message"
                style={{ height: "100px" }}
              />
            </Form.Group>
          </div>
          <div className="col-12">
            <button className="home-btn-animation" type="submit"> Submit </button>
          </div>
        </div>
      </Form>
    </>
  );
}
export default ContactForm;
